<template>
  <div>
    <item-list-loader
        :disable-no-results="true"
        :limit="1000"
        namespace="additionalFields"
        :custom-filters="{ sectorId : sectorId }"
    >
      <template v-slot:main="fieldsSlot">
        <div v-for="(field,fieldIndex) in fields" :key="field.ident"
             class="light-border rounded pa-1 mt-1 d-flex justify-space-between cursor align-center">

          <div class="flex-fill mr-2 col-6">
            <v-text-field
                :outlined="true"
                :hide-details="fieldErrors[fields[fieldIndex].ident] == null"
                @blur="validate"
                label="Field name"
                :error="fieldErrors[fields[fieldIndex].ident] != null"
                :error-messages="fieldErrors[fields[fieldIndex].ident]"
                v-model="fields[fieldIndex].additionalField"></v-text-field>
          </div>
          <div class="flex-fill mr-2 col-6">
            <v-text-field
                :outlined="true"
                :hide-details="true"
                label="Field value"
                @blur="validate"
                :error="valueErrors[fields[fieldIndex].ident] != null"
                :error-messages="valueErrors[fields[fieldIndex].ident]"
                v-model="fields[fieldIndex].additionalFieldValue">

            </v-text-field>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" v-bind="attrs" class="flex-wrap mr-3 error"
                     @click="removeField(field.ident)" icon>
                <v-icon color="white">mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete additional detail</span>
          </v-tooltip>

        </div>
      </template>
    </item-list-loader>

    <v-dialog
        persistent
        max-width="500"
        v-model="deleteDialog">
      <v-card >
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              color="green"
              text
              @click="deleteDialog = false"
          >
            Don't delete
          </v-btn>
          <v-btn
              color="error"
              text
              @click="removeFieldConfirm()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="fields.length === 0" class="rounded light pa-2 mt-1">
      <small>No additional details added for this asset</small>
    </div>
    <v-btn class="mt-2" @click="addField" color="primary" small>
      <v-icon size="15" class="mr-1">mdi-plus</v-icon>
      {{ fields.length === 0 ? "Add detail" : "Add another" }}
    </v-btn>
  </div>
</template>

<script>
import CustomFieldInput from "@/components/asset-types/CustomFieldInput";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash'
import {mapGetters} from "vuex";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {endpoints, httpRequest} from "@/store/network/amtag.client";

export default {
  name: "CustomFieldCreatorInput",
  components: {ItemListLoader, CustomFieldInput},
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    sectorId: null
  },
  data() {
    return {
      deletingId: null,
      fields: this.value,
      fieldErrors: {},
      valueErrors: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['organisation']),
    deleteDialog: {
      get() {
        return this.deletingId != null;
      },
      set(val) {
        if(val === false) {
          this.deletingId = null;
        }
      }
    }
  },
  methods: {
    validate() {
      console.log("VALIDATION");
      //Find all idents of those fields that have the same additionalField value
      var grouped = _.groupBy(this.fields, (field) => field.additionalField.toLowerCase());
      console.log("GROUPED IS ", grouped);
      var fieldErrors = {};
      for (var key in grouped) {
        if (grouped[key].length > 1) {
          grouped[key].forEach((field) => {
            fieldErrors[field.ident] = ["Field name must be unique"];
          })
        }
      }
      //check for empty field names
      this.fields.forEach((field) => {
        if (field.additionalField == null || field.additionalField === "") {
          fieldErrors[field.ident] = ["Field name cannot be empty"];
        }
      });
      this.fieldErrors = fieldErrors;
      //check for empty values
      var valueErrors = {};
      this.fields.forEach((field) => {
        if (field.additionalFieldValue == null || field.additionalFieldValue === "") {
          valueErrors[field.ident] = ["Field value cannot be empty"];
        }
      });
      this.valueErrors = valueErrors;
    },
    addField() {
      this.fields.push({
        ident: uuidv4()
      })
    },
    beforeSave(search, additionalFieldId = null) {
      if (additionalFieldId == null) {
        return {name: search, organisationId: this.organisation.id, sectorId: this.sectorId};
      } else {
        return {fieldValue: search, organisationId: this.organisation.id, additionalFieldId: additionalFieldId};
      }
    },
    removeField(fieldId) {
      console.log("REMOVING FIELD" + fieldId);
      this.deletingId = fieldId;
    },
    removeFieldConfirm() {
      console.log("REMOVING FIELD" + this.deletingId);
      this.fields = this.fields.filter(field => field.ident !== this.deletingId);
      this.deleteDialog = false;
    }
  },
  watch: {
    fields(newVal) {
      this.$emit('input', newVal);
    },
  }
}
</script>

