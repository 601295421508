<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      class="px-4"
      :on-save-listeners="['sectorsUpdated']"
      :form-type="formType"
      :title="formTitle"
      :before-save="beforeSave"
      :item-transform="itemTransform"
      :initial-item="clientAdmin ? {} : {organisationId : organisation.id}"
      :btn-text="formTitle"
      :id="sectorId"
      delete-btn-text="Delete sector"
      btn-icon="mdi-pencil"
      namespace="sectors">
    <template #default="{item,errors,onUpdate}">
      <template v-if="formType === 'delete'">
        <v-alert type="error">
          Deleting of a sector is irreversible, are you sure you want to do this?
        </v-alert>
      </template>
      <template v-else>
        <api-select-box
            limit="100"
            class="mb-4"
            v-if="clientAdmin"
            label="Select organisation"
            hint="Select organisation to add this sector to"
            item-text="name"
            item-value="id"
            v-model="item.organisationId"
            @input="onUpdate(item)"
            namespace="organisationSelect"
            :error="errors.organisationId != null"
            :error-messages="errors.organisationId"
        />
        <v-text-field
            label="Sector name"
            v-model="item.name"
            @input="onUpdate(item)"
            :error="errors.name != null"
            :error-messages="errors.name"
        />
      </template>
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import HoseConfigurationView from "../../../components/asset-types/AssetTypeView";
import AssetTypeView from "@/components/asset-types/AssetTypeView";
import TaskSelectionInput from "@/components/tasks/TaskSelectionInput";
import {v4 as uuidv4} from "uuid";

export default {
  name: "SectorAdd",
  components: {TaskSelectionInput, AssetTypeView, HoseConfigurationView, ApiSelectBox, FormLoader},
  data() {
    return {}
  },
  props: {
    sectorId: null
  },
  methods: {
    onAssetUpdate(asset) {
      console.log("HOSE UPDATED", asset);
      this.getItem(this.sectorId);
    },
    onAssetLoaded(asset) {
      console.log("HOSE EMITTED ", asset);
    },
    beforeSave(item) {

      return item;
    },
    itemTransform(item) {

      return item;
    },
    ...mapActions('sectors', ['getItem'])

  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation']),
    formType() {
      if (this.$router.currentRoute.name === "SectorEdit") return 'edit';
      if (this.$router.currentRoute.name === "SectorAdd") return 'add';
      return "delete";
    },
    formTitle() {
      if (this.$router.currentRoute.name === "SectorEdit") return 'Edit sector';
      if (this.$router.currentRoute.name === "SectorAdd") return 'Add sector';
      return "Delete sector";
    }
  }
}
</script>

<style scoped>

</style>
