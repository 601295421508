import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
import moment from 'moment';
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});
Vue.filter('formatDateOnly', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY HH:mm')
  }
});
Vue.filter('databaseDate', function(value) {
  if (value) {
    return moment(String(value),'DD/MM/YYYY').format('YYYY-MM-DD\\Z')
  }
});
Vue.filter('fileTypeToIcon', function(value) {
  switch (value) {
    case "image/jpg":
    case "image/jpeg":
    case "image/png":
      return "mdi-image";
    case "application/pdf":
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "mdi-file-document";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "mdi-table";
  }
});
Vue.filter('statusToColor', function(value) {
  if (value === 1) {
    return "success";
  } else if (value === 2) {
    return "warning";
  } else if (value === 3) {
    return "error";
  } else {
    return "secondary";
  }
});
Vue.filter('statusToString', function(value) {
  if (value === 1) {
    return "Complete";
  } else if (value === 2) {
    return "Monitor";
  } else if (value === 3) {
    return "Action";
  } else {
    return "Invalid";
  }
});
Vue.filter('roleToColor', function(value) {
  if (value === 0) {
    return "success";
  } else if (value === 1) {
    return "warning";
  } else if (value === 2) {
    return "error";
  } else {
    return "";
  }
});
Vue.filter('roleToColor', function(value) {
  if (value === 0) {
    return "success";
  } else if (value === 1) {
    return "warning";
  } else if (value === 2) {
    return "error";
  } else {
    return "";
  }
});
Vue.filter('roleToString', function(value) {
  if (value === 0) {
    return "User";
  } else if (value === 1) {
    return "Manager";
  } else if (value === 2) {
    return "Admin";
  } else {
    return "";
  }
});
Vue.filter('parseDate', function(value) {
  if (value) {
    return moment(String(value),'DD/MM/YYYY').format('YYYY-MM-DD')
  }
});

Sentry.init({
  Vue,
  dsn: "https://5e8a03102b0f4d3fb0f5e4fb805d239d@o216648.ingest.sentry.io/4504690212208640",
  environment : process.env.NODE_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.amtag.uk", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'mdi'
})

import { createHead } from '../node_modules/@unhead/vue'
import { UnheadPlugin } from '../node_modules/@unhead/vue/dist/vue2'

const head = createHead()
Vue.use(UnheadPlugin,{

})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  unhead : head
}).$mount('#app');
