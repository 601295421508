<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-chart-pie" :actions="actions" title="Sectors">
    <paginated-result-loader
        :enable-exports="false"
        :custom-filters="{organisationId: currentOrganisationId}"
        namespace="sectors">
      <template v-slot:default="props">
        <div
            v-for="(item,itemIndex) in props.items">
          <div class="pa-3 rounded align-center justify-space-between d-flex">

            <div class="flex-fill ml-2">
              <div><strong>{{ item.name }}</strong></div>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap mr-3 error"
                       :to="{name: 'SectorDelete', params : {sectorId : item.id}}" icon>
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete sector</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap primary"
                       :to="{name: 'SectorEdit', params : {sectorId : item.id}}" icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit sector</span>
            </v-tooltip>

          </div>
          <v-divider></v-divider>
        </div>

      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import AssetTypeList from "@/components/asset-types/AssetTypeList";
import TaskList from "@/components/tasks/TaskList";
import emitter from "@/utils/emitter";

export default {
  name: 'Sectors',
  components: {
    TaskList,
    AssetTypeList,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    emitter.on('sectorsUpdated', this.refreshSectors)
  },
  beforeDestroy() {
    emitter.off('sectorsUpdated', this.refreshSectors)
  },
  methods: {
    refreshSectors() {
      this.getSectors({customFilters: []});
    },
    ...mapActions('sectors', {getSectors: 'getItems'}),
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'currentOrganisationId', 'isAdmin']),
    actions() {
      var list = [];
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Create sector',
        route: {name: 'SectorAdd'},
        variant: 'success'
      });

      return list;
    }
  }
}
</script>
