import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth.module.js'
import organisationModule from "./modules/organisations.module";
import userModule from "./modules/users.module";
import alertsModule from "./modules/alerts.module";
import sitesModule from "./modules/sites.module";
import assetsModule from "./modules/assets.module";
import assetTypesModule from "@/store/modules/assetTypes.module";
import completedChecksModule from "@/store/modules/completedChecksModule";
import sectorsModule from "@/store/modules/sectors.module";
import checksModule from "@/store/modules/checksModule";
import additionalFieldsModule from "@/store/modules/additionalFields.module";
import additionalFieldValuesModule from "@/store/modules/additionalFieldValues.module";
import checksDueModule from "@/store/modules/checksDue.module";
import uploadsModule from "@/store/modules/uploads.module";
import assetMetaModule from "@/store/modules/assetMeta.module";
import batchesModule from "@/store/modules/batches.module";
import clientsModule from "@/store/modules/clients.module";
import clientUsersModule from "@/store/modules/clientUsers.module";

Vue.use(Vuex);

var store = new Vuex.Store({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    auth : authModule,
    organisations : organisationModule(),
    clients : clientsModule(),
    clientUsers : clientUsersModule(),
    clientsEdit : clientsModule(),
    batches : batchesModule(),
    organisationSelect : organisationModule(),
    organisationsDashboard : organisationModule(),
    organisationsDashboard2 : organisationModule(),
    users : userModule(),
    userSelect : userModule(),
    alerts : alertsModule,
    sites : sitesModule(),
    siteSelect : sitesModule(),
    assets : assetsModule(),
    assetMeta : assetMetaModule(),
    assetsEdit : assetsModule(),
    assetsDashboard : assetsModule(),
    assetTypes : assetTypesModule(),
    additionalFields : additionalFieldsModule(),
    additionalFieldValues : additionalFieldValuesModule(),
    completedChecks : completedChecksModule(),
    sectors : sectorsModule(),
    sectorsSelect : sectorsModule(),
    checks : checksModule(),
    checks2 : checksModule(),
    checks3 : checksModule(),
    checksDue : checksDueModule(),
    checksDueDashboard1 : checksDueModule(),
    checksDueDashboard2 : checksDueModule(),
    uploads : uploadsModule(),
  },
  plugins: []
});
export default store;
