<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-calendar-month" title="Task Planner">
    <paginated-result-loader
        :enable-exports="true"
        namespace="checksDue">
      <template v-slot:default="props">
        <task-planner-list :full="true" :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>
import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import HoseList from "../../../components/assets/AssetList";
import CompletedTaskList from "@/components/completed-tasks/CompletedTaskList";
import TaskPlannerList from "@/components/task-planner/TaskPlannerList.vue";
import CheckDetailList from "@/components/checks/CheckDetailList.vue";

export default {
  name: 'TaskPlanner',
  components: {
    CheckDetailList,
    TaskPlannerList,
    CompletedTaskList,
    HoseList,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin']),
  }
}
</script>
