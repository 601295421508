<template>
  <div v-if="id == null && assetId == null"></div>
  <div v-else-if="loading">
    Loading
  </div>
  <div v-else-if="hasErrors">
    <global-error-inline :errors="errors"></global-error-inline>
  </div>
  <div v-else>
    <label-value-horizontal v-if="asset.distributor && (clientAdmin || !distributor)" label="Distributor"
                            :value="asset.distributor.name"/>
    <label-value-horizontal v-if="asset.customer && (clientAdmin || distributor)" label="Customer"
                            :value="asset.customer.name"/>
    <label-value-horizontal v-if="assetType.name" label="Asset name" :value="assetType.name"/>
    <label-value-horizontal :key="additionalFieldKey+'addField'" v-for="(additionalField,additionalFieldKey) in additionalFields" :label="additionalField.additionalField" :value="additionalField.additionalFieldValue"/>
    <label-value-horizontal v-if="asset.name" label="Asset reference" :value="asset.name"/>
    <label-value-horizontal v-if="asset.organisationZone" label="Asset Location"
                            :value="asset.organisationZone.name"/>
    <label-value-horizontal v-if="assetType.sector" label="Sector" :value="assetType.sector.name"/>
    <label-value-horizontal v-if="asset.buildUser" label="Assigned by" :value="asset.buildUser.fullName"/>
    <label-value-horizontal v-if="asset.buildDate" label="Assigned date"
                            :value="$options.filters.formatDateOnly(asset.buildDate)"/>
    <label-value-horizontal v-if="asset.installationUser" label="Installed by"
                            :value="asset.installationUser.fullName"/>
    <label-value-horizontal v-if="asset.installationDate" label="Installed date"
                            :value="$options.filters.formatDateOnly(asset.installationDate)"/>
    <label-value-horizontal v-if="asset.id" label="Asset ID" :value="asset.id"/>

  </div>
</template>

<script>
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import GlobalErrorInline from "../GlobalErrorInline";
import LabelValueVertical from "../LabelValueVertical";
import LabelValueHorizontal from "../LabelValueHorizontal";
import {mapGetters} from "vuex";
import {v4 as uuidv4} from "uuid";

export default {
  name: "AssetTypeView",
  components: {LabelValueHorizontal, LabelValueVertical, GlobalErrorInline},
  props: {
    id: null,
    assetId: null,
    refreshToken: null,
  },
  data() {
    return {
      errors: {},
      loading: true,
      assetType: {},
      asset: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['distributor', 'clientAdmin']),
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },
    additionalFields() {
      var finalFields = [];
      _.forEach(this.assetType.additionalFields,(additionalField) => {
        let fieldValue = this.assetType.additionalFieldValues.filter(fv => fv.additionalFieldId === additionalField.id)[0];
        if(fieldValue) {
          finalFields.push({
            ident: uuidv4(),
            additionalField : additionalField.name,
            additionalFieldValue : fieldValue.fieldValue,
          })
        }
      });
      return finalFields;
    }
  },
  created() {
    if (this.id != null) {
      this.loadAssetType();
    } else if (this.assetId != null) {
      this.loadAsset();
    }
  },
  watch: {
    id(newVal, oldVal) {
      this.loadAssetType();
    },
    assetId(newVal, oldVal) {
      this.loadAsset();
    },
    refreshToken(newVal, oldVal) {
      if (this.id != null) {
        this.loadAssetType();
      } else if (this.assetId != null) {
        this.loadAsset();
      }
    }
  },
  methods: {
    async loadAssetType() {
      this.loading = true;
      try {
        let res = await httpRequest(endpoints.assetTypes.getItem(this.id));
        this.assetType = res.data;
      } catch (e) {
        console.log("error", e.response.data);
        this.errors = e.response.data
      } finally {
        this.loading = false;
      }
    },
    async loadAsset() {
      this.loading = true;
      try {
        let res = await httpRequest(endpoints.assets.getItem(this.assetId));
        this.asset = res.data;
        this.assetType = res.data.assetType;
        this.$emit('assetLoaded', res.data);
      } catch (e) {
        console.log("error", e.response.data);
        this.errors = e.response.data
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
label {
  color: var(--v-textSubtle-base);
  font-weight: 700;
}

.col {
  padding-bottom: 2px;
  padding-top: 2px;
}
</style>
