<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-img :aspect-ratio="thumbnail ? '1' : undefined" cover class="light" :width="width" :src="url">
        <template v-slot:placeholder>
            <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
            >
                <v-icon v-if="error">mdi-alert</v-icon>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
            </v-row>
        </template>
    </v-img>
</template>

<script>
    import {endpoints, httpRequest} from "../store/network/amtag.client";

    export default {
        name: "UploadImageLoader",
        data() {
            return {
                loading: false,
                error: false,
                url: null,
            }
        },
        props: {
            thumbnail : {
                type: Boolean,
                default() {
                    return true;
                }
            },
            upload: {},
            size : {
                type: Number,
                default() {
                    return 3;
                }
            },
            width : {
                type: Number,
                default() {
                    return 70;
                }
            }
        },
        watch: {
            upload(newVal) {
                this.loadThumbnail();
            }
        },
        created() {
            if(this.upload.id)
            this.loadThumbnail();
        },
        computed: {},
        methods: {
            async loadThumbnail() {
                this.url = null;
                this.loading = true;
                this.error = false;
                try {
                    var response = await httpRequest(endpoints.uploads.getItem(this.upload.id, this.size));
                    console.log("IMAGE LOAD SUCCESS",response);
                    this.url = "data:image/jpeg;base64,"+response.data.base64Data;
                } catch(e) {
                    console.log("IMAGE LOAD ERROR",e);
                    this.error = true;
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped>

</style>
