<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-clipboard-check-outline" title="Completed tasks">
    <paginated-result-loader
        :enable-exports="true"
        namespace="completedChecks">
      <template v-slot:default="props">
        <completed-task-list :full="true" :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>
import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import HoseList from "../../../components/assets/AssetList";
import CompletedTaskList from "@/components/completed-tasks/CompletedTaskList";

export default {
  name: 'CompletedTasks',
  components: {
    CompletedTaskList,
    HoseList,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin']),
  }
}
</script>
