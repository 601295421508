<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          Task
        </th>
        <th>
          Last Performed
        </th>
        <th>
          Status
        </th>
        <th>
          Next due
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          :class="item.checkId ? 'cursor' : ''"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'AssetCheckTimeline',params: { assetId : item.assetId, checkId : item.checkId }}) }"
      >
        <td class="py-3">
          <div><strong>{{ item.checkName }}</strong></div>
          <check-timeline-chip short :check-timeline="item"></check-timeline-chip>
        </td>
        <td>
          <template v-if="item.lastCheckDate">
            <div>{{ convertDate(item.lastCheckDate) }} <strong v-if="item.lastCheckUserName">by {{ item.lastCheckUserName }}</strong></div>
          </template>
        </td>
        <td>
          <check-status :status="item.lastCheckStatus"/>
        </td>
        <td>
          <v-chip :color="item.isDue ? 'accent' : ''">{{ item.period === 0 ?
              convertDateTime(item.nextDue,!item.lastCheckStatus) :
              convertDate(item.nextDue) }}</v-chip>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import AssetTypeDropdown from "../asset-types/AssetTypeDropdown";
import SiteDropdown from "../sites/SiteDropdown";
import CheckStatus from "@/components/checks/CheckStatus";
import CheckTimelineChip from "@/components/CheckTimelineChip";

export default {
  name: 'CheckDetailList',
  components: {
    CheckTimelineChip,
    CheckStatus, SiteDropdown, AssetTypeDropdown, HoseConfigurationView, ActionDropdown},
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),
  },
  methods: {
    convertDate(date) {
      return new moment(date).format("DD/MM/yyyy");
    },
    convertDateTime(date,offset = false) {
      if(offset){
        //Get current time zone offset
        let offsetMinutes = new Date().getTimezoneOffset();
        console.log("OFFSET IS",offset);
        return new moment(date).add(offsetMinutes,"minute").format("DD/MM/yyyy HH:mm");
      }
      return new moment(date).format("DD/MM/yyyy HH:mm");
    }
  }
}
</script>
