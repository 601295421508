<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      class="px-4"
      :form-type="formType"
      :on-save-listeners="['checksUpdated']"
      :title="formTitle"
      :item-transform="itemTransform"
      v-on:itemUpdated="onItemUpdated"
      :before-save="beforeSave"
      :id="taskId"
      :initial-item="clientAdmin ? {} : {organisationId : organisation.id}"
      namespace="checks">
    <template #default="{item,errors,onUpdate}">

      <v-alert type="warning" icon="mdi-alert-circle-outline" dark dense v-if="item.hoseCount">
        <div class="flex-fill">Any changes made to this task will effect all {{ item.hoseCount }} hoses associated with
          it
        </div>
      </v-alert>

      <api-select-box
          limit="100"
          class="mb-4"
          v-if="clientAdmin"
          label="Select organisation"
          hint="Select organisation to add this task to"
          item-text="name"
          item-value="id"
          v-model="item.organisationId"
          @input="onUpdate(item)"
          namespace="organisationSelect"
          :error="errors.organisationId != null"
          :error-messages="errors.organisationId"
      />
      <api-select-box
          limit="100"
          class="mb-4"
          label="Select sector"
          hint="Select sector to add this task to"
          item-text="name"
          item-value="id"
          v-model="item.sectorId"
          @input="onUpdate(item)"
          namespace="sectorsSelect"
          :error="errors.sectorId != null"
          :error-messages="errors.sectorId"
      />
      <v-text-field
          label="Task name"
          v-model="item.name"
          @input="onUpdate(item)"
          :error="errors.name != null"
          :error-messages="errors.name"
      />
      <check-timeline-input
        v-model="item.checkTimeline"
        @input="onUpdate(item)"
        :error="errors.checkTimeline != null"
        :error-messages="errors.checkTimeline">
      </check-timeline-input>

    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import CrudPage from "../../../components/CrudPage";
import CheckTimelineInput from "@/components/CheckTimelineInput";

export default {
  name: "TaskAdd",
  components: {CheckTimelineInput, CrudPage, ApiSelectBox, FormLoader},
  props: {
    taskId: null,
  },
  data() {
    return {
      currentItem: {
        type: Object,
        default: {},
      }
    }
  },
  created() {

  },
  methods: {
    beforeSave(item) {

      item.checkTimeline.organisationId = item.organisationId;
      return item;
    },
    itemTransform(item) {

      return item;
    },
    onItemUpdated(item) {
      this.currentItem = item;
    },
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'organisation', 'optionalFields']),
    formType() {
      if (this.$router.currentRoute.name === "TaskDelete") return 'delete';
      if (this.$router.currentRoute.name === "TaskView") return 'view';
      return this.taskId != null ? "edit" : "add";
    },
    formTitle() {
      if (this.formType === "delete") {
        return "Delete task";
      } else if (this.formType === "view") {
        return "View task";
      } else if (this.formType === "add") {
        return "Create task";
      } else if (this.formType === "edit") {
        return "Edit task";
      }
    }
  }
}
</script>

<style scoped>

</style>
