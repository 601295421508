<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <item-loader
      namespace="clients"
      :id="clientId"
      #default="clientSlot">
    <crud-page icon="mdi-account-group-outline" :actions="actions" :title="'Client admins '+clientSlot.item.name">
      <paginated-result-loader
          :listeners="['clientUsersUpdated']"
          :enable-exports="true"
          :queryParams="{clientId : clientId}"
          namespace="clientUsers">
        <template v-slot:default="props">
          <client-user-list :items="props.items"/>
        </template>
        <template v-slot:header>
          <v-menu
              :right="true"
              v-if="pendingItems.length > 0"
              :offset-y="true"
              :close-on-content-click="true"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                      :loading="pendingLoading"
                      color="primary"
                      elevation="0"
                      small
                      v-bind="attrs"
                      tooltip="View pending users"
                      v-on="{ ...tooltip, ...menu }"
                  >
                    <v-icon color="white" size="16">mdi-account-clock</v-icon>
                    <span class="mr-2 ml-2">Pending invites ({{ pendingItems.length }})</span>
                    <v-icon size="13">mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <span>View pending invites</span>
              </v-tooltip>
            </template>
            <v-card :loading="pendingLoading">
              <client-pending-list :items="pendingItems"></client-pending-list>
            </v-card>
          </v-menu>
        </template>
      </paginated-result-loader>
    </crud-page>
  </item-loader>
</template>

<script>

import CrudPage from "../../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import PaginatedResultLoader from "../../../../components/PaginatedResultLoader";
import UserList from "../../../../components/users/UserList";
import PendingList from "../../../../components/users/PendingList";
import ClientPendingList from "@/components/clients/ClientPendingList.vue";
import ClientUserList from "@/components/clients/ClientUserList.vue";
import listenersMixin from "@/mixins/listeners.mixin";
import emitter from "@/utils/emitter";
import ItemLoader from "@/components/ItemLoader.vue";

export default {
  name: 'ClientUsers',
  props: {
    clientId: {
      required: true
    }
  },
  components: {
    ItemLoader,
    ClientUserList,
    ClientPendingList,
    PendingList,
    UserList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    this.getPendingItems();
    emitter.on('clientUsersUpdated', () => {
      this.getPendingItems();
      this.$router.replace({name: 'ClientUsers', params: {clientId: this.clientId}}).catch(() => {
      });
    });
  },
  beforeDestroy() {
    emitter.off('clientUsersUpdated');
  },
  methods: {
    getPendingItems() {
      this.$store.dispatch('clientUsers/getPendingItems', this.clientId);
    },
  },
  watch: {},
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'user']),
    ...mapGetters('clientUsers', ['pendingItems', 'pendingLoading', 'pendingError']),
    actions() {
      var list = [];
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Invite client admin',
        route: {name: 'ClientUserAdd'},
        variant: 'success'
      });
      return list;
    }
  }
}
</script>
