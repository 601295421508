<template>
    <v-snackbar v-model="show" color="success" timeout="3000"  :top="true">
        {{ currentMessage }}
    </v-snackbar>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "AlertDisplay",
        data: () => ({
            show: false,
            currentMessage : null
        }),
        computed: {
            ...mapGetters('alerts', ['userMessage'])
        },
        watch: {
            userMessage(val) {
                if(val != null){
                    this.show = true;
                    this.currentMessage = val
                }
                this.setUserMessage(null);
            },
            show(val) {
                if(!val) {
                    this.setUserMessage(null);
                }
            }
        },
        methods : {
            ...mapActions('alerts', ['setUserMessage'])
        }
    }
</script>

<style scoped>

</style>
