import {baseActions, baseGetters, baseMutations, baseState} from "./baseStore";
import * as _ from "lodash";
import {endpoints, httpRequest} from "../network/amtag.client";

function state(){
    return {
        ...baseState(),
        pendingItems : [],
        pendingLoading : false,
        pendingError : {}
    }
}

function getters(){
    return {
        ...baseGetters(),
        pendingItems : state => state.pendingItems,
        pendingLoading : state => state.pendingLoading,
        pendingError : state => state.pendingError,
    }
}

function mutations(){
    return {
        ...baseMutations(),
        setPendingItems(state,data) {
            state.pendingItems = data.items;
        },
        setPendingLoading(state,data) {
            state.pendingLoading = data;
        },
        setPendingError(state,data) {
            state.pendingError = data;
        }
    }
}

function actions(){
    return {
        ...baseActions('users'),
        async onAddOrEdit({commit, state, dispatch}) {
            dispatch('getPendingItems');
        },
        async getPendingItems({commit, state}) {
            commit('setPendingError', {});
            commit('setPendingLoading', true);
            try {
                const data = {
                    page: 1
                };
                let res = await httpRequest(endpoints.users.getPendingItems, data);
                commit('setPendingItems', res.data);
            } catch (e) {
                console.log("pending error", e);
                console.log("pending error data", e.response.data);
                commit('setPendingError', e.response.data);
            } finally {
                commit('setPendingLoading', false);
            }
        },
        async deletePendingItem({commit, dispatch},id) {
            commit('setPendingError', {});
            commit('setPendingLoading', true);
            try {
                let res = await httpRequest(endpoints.users.deletePendingItem(id));
                await dispatch('getPendingItems')
            } catch (e) {
                console.log("pending error", e);
                console.log("pending error data", e.response.data);
                commit('setPendingError', e.response.data);
            } finally {
                commit('setPendingLoading', false);
            }
        }
    }
}

function usersModule()
{
    return {
        namespaced: true,
        state: state(),
        getters: getters(),
        mutations: mutations(),
        actions: actions()
    }
}
export default usersModule;
