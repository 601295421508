<template>
  <div>
    <v-combobox
        single-line
        clearable
        dense
        outlined
        filled
        :style="disabled ? 'opacity:0.5;' : ''"
        :readonly="disabled"
        :filter="filter"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        v-model="currentSelection"
        :hide-no-data="!search"
        :open-on-clear="true"
        :items="items"
        hide-selected
        hide-details
        hide-spin-buttons
        :search-input.sync="search"
        :return-object="true"
        :label="label"
    >
      <template v-if="!currentSelection" v-slot:no-data>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        {{ item[itemText] }}
      </template>
      <template v-slot:item="{ index, item }">
        {{ item[itemText] }}
      </template>
    </v-combobox>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import * as _ from "lodash";
import {endpoints, httpRequest} from "@/store/network/amtag.client";

export default {
  name: 'CustomFieldInput',
  props: {
    disabled: false,
    itemText: {
      type: String,
      default() {
        return "name"
      }
    },
    additionalFilters: {
      type: Array,
      default() {
        return []
      }
    },
    itemValue: {
      type: String,
      default() {
        return "id"
      }
    },
    beforeSave: {
      type: Function,
      required: true
    },
    label: {
      type: String
    },
    namespace: {
      type: String
    },
    value: {
      type: Object,
      default() {
        return null
      }
    },
    organisationId: {
      type: String,
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      items: [],
      search: null,
      currentSelection: this.value,
      loading: false,
      errors: {}
    }
  },
  created() {
    if(!this.disabled) {
      this.getItems();
    }
    console.log("INPUT IS ",this.input);
  },
  computed: {
    ...mapGetters('auth', ['organisation'])
  },
  methods: {
    async getItems() {
      try {
        this.loading = true;
        let data = {
          page: 1
        };
        let filtersFinal = {
          keywords: this.search
        };
        if (this.additionalFilters != null) {
          _.forEach(this.additionalFilters, function (customFilter) {
            filtersFinal[customFilter.key] = customFilter.value;
          })
        }
        data['filters'] = filtersFinal;
        let res = await httpRequest(endpoints[this.namespace].getItems, data);
        this.items = res.data.items;
      } catch (e) {
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    },
    async addItem(newFieldName) {
      try {
        this.loading = true;
        let data = this.beforeSave(newFieldName);
        let res = await httpRequest(endpoints[this.namespace].addItem, data);
        this.currentSelection = res.data;
      } catch (e) {
        console.log(e);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    },
    filter(item, queryText, itemText) {
      if (item.header) return false
      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
    }
  },
  watch: {
    additionalFilters(newVal, oldVal) {
      this.getItems()
    },
    search(newVal, oldVal) {
      this.getItems()
    },
    async currentSelection(newVal, oldVal) {
      //CHECK IF new value is an object
      if (newVal != null && typeof newVal === 'object') {
        this.$emit('input', newVal);
      } else {
        await this.addItem(this.currentSelection);
        //this.$emit('input', this.currentSelection);
      }
    },
    input(newVal,oldVal) {
      console.log("NEW INPUT IS",newVal);
      this.currentSelection = newVal;
    }
  }
}
</script>
