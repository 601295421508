<template>
    <div>
        <v-label v-if="treeView">
            {{ label }}
            <treeselect

                    v-model="selection"
                    :multiple="multiple"
                    :normalizer="normalizer"
                    :options="items">
            </treeselect>
        </v-label>
        <v-autocomplete
                autocomplete="off"
                v-else
                :chips="multiple"
                :loading="loading"
                v-model="selection"
                :items="items"
                :menu-props="{ maxHeight: '400' }"
                :label="label"
                :multiple="multiple"
                :item-value="itemValue"
                :item-text="itemText"
                :hint="hint"
                persistent-hint
                :search-input="searchInput"
                :search-input.sync="search"
                :error="error"
                :error-messages="errorMessages"
                append-icon="mdi-chevron-down"
        ></v-autocomplete>
    </div>

</template>

<script>
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import _ from 'lodash';
    import paginationMixin from "../mixins/pagination.mixin";

    export default {
        name: "ApiSelectBox",
        components: {Treeselect},
        props: {
            resetId: null,
            value: null,
            treeView: false,
            error: false,
            errorMessages: {
                type: Array,
                default() {
                    return []
                }
            },
            itemValue: {
                type: String,
                default() {
                    return 'id'
                }
            },
            itemText: {
                type: String,
                default() {
                    return 'name'
                }
            },
            label: {
                type: String,
                default() {
                    return 'Select'
                }
            },
            hint: null,
            multiple: false,
            namespace: {
                type: String,
                default: 'organisationSelect'
            },
            paginationMixin
        },
        data() {
            return {
                searchInput: null,
                selection: this.value,
                search: null,
            }
        },
        created() {

        },
        watch: {
            resetId(val) {
                this.searchInput = null;
                this.selection = null;
                console.log("RESETtING");
            },
            value(val) {

                if (val == null) {
                    this.selection = null;
                    this.search = null;
                } else {
                    this.selection = val;
                }

            },
            selection(value) {
                this.$emit("input", value);
                var selectedItem = this.items.filter((obj) => {
                    return obj.id === value
                })[0];
                this.$emit("selectedItem", selectedItem);
            },
            search(value) {
                var filters = this.filters;
                filters?.find(filter => filter.id === "keywords");
                this.filters = filters;
                console.log("NEW FILYTERS", this.filters);
                this.searchInput = null;
            }
        },
        methods: {
            normalizer(node) {
                console.log("NORMALIZING");
                return {
                    id: node[this.itemValue],
                    label: node[this.itemText],
                    children: node.children,
                }
            },
        },
        mixins: [paginationMixin()]
    }
</script>

<style scoped>

</style>
