import {baseActions, baseGetters, baseMutations, baseState} from "./baseStore";
import {endpoints, httpRequest} from "@/store/network/amtag.client";

const currentClient = () => {
    try {
        return {
            "id": 1,
            "name": "AmTag",
            "theme": "amtag"
        };
    } catch (e) {
        return null;
    }
}

function state(){
    let state = {
        ...baseState(),
        clientLoading: false,
        clientError: {},
    }
    state.item = currentClient();
    return state;
}

function getters(){
    return {
        ...baseGetters(),
        clientError: state => state.clientError,
        clientLoading: state => state.clientLoading
    }
}

function mutations(){
    return {
        ...baseMutations(),
        clientError(state, data) {
            state.clientError = data;
        },
        clientLoading(state, data) {
            state.clientLoading = data;
        },
    }
}

function actions(){
    return {
        ...baseActions('clients'),
        getLogoUrl({commit, state}) {
            if(!state.item.theme) {
                return "";
            }
            return require('../../assets/'+state.item.theme+"/logo.png");
        },
        async getCurrent({commit, state}) {
            commit('clientError', {});
            commit('clientLoading', true);
            try {
                let res = await httpRequest(endpoints.clients.current(), null);
                await localStorage.setItem('amtag_client',JSON.stringify(res.data));
                commit('updateItem', res.data);
            } catch (e) {
                console.log("error", e.response.data);
                commit('clientError', e.response.data);
            } finally {
                commit('clientLoading', false);
            }
        },
    }
}

function clientsModule()
{
    return {
        namespaced: true,
        state: state(),
        getters: getters(),
        mutations: mutations(),
        actions: actions()
    }
}
export default clientsModule;
