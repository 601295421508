<template>
  <v-simple-table>
    <template>
      <thead>
      <tr>
        <th v-if="clientAdmin" class="text-left">
          Organisation
        </th>
        <th class="text-left">
          Sector
        </th>
        <th class="text-left">
          Name
        </th>
        <th class="text-left">
          Frequency
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id">
        <td v-if="clientAdmin">
          {{ item.organisation.name }}
        </td>
        <td>
          {{ item.sector.name }}
        </td>
        <td>
          {{ item.name }}
        </td>
        <td>
          <check-timeline-chip short :check-timeline="item.checkTimeline"></check-timeline-chip>
        </td>
        <td class="text-right">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import CheckTimelineChip from "@/components/CheckTimelineChip";

export default {
  name: 'TaskList',
  components: {CheckTimelineChip, ActionDropdown},
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin'])
  },
  methods: {
    getDropdown(item) {
      return [
        {
          label: 'View full details',
          icon: 'mdi-arrow-expand-all',
          route: {name: 'TaskView', params: {taskId: item.id}}
        },
        {
          label: 'Edit',
          icon: 'mdi-pencil',
          route: {name: 'TaskEdit', params: {taskId: item.id}}
        },
        {
          label: 'Delete',
          icon: 'mdi-delete-outline',
          route: {name: 'TaskDelete', params: {taskId: item.id}}
        }
      ]
    }
  }
}
</script>
