<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <form-loader
            class="px-4"
            form-type="link"
            title="Link new customer"
            v-on:complete="onComplete"
            :id="organisationId"
            namespace="organisations">
        <template #default="{item,errors,onUpdate}">

            <v-text-field
                    persistent-hint
                    hint="6 character account code obtained from the customer"
                    label="Customer account code"
                    v-model="item.accountCode"
                    @input="onUpdate(item)"
                    :error="errors.accountCode != null"
                    :error-messages="errors.accountCode"
            />

        </template>
    </form-loader>
</template>

<script>
    import FormLoader from "../../../components/FormLoader";
    import {mapGetters} from "vuex";
    import ApiSelectBox from "../../../components/ApiSelectBox";
    import GlobalErrorInline from "../../../components/GlobalErrorInline";

    export default {
        name: "OrganisationLink",
        components: {GlobalErrorInline, ApiSelectBox, FormLoader},
        props: {
            organisationId: null,
        },
        computed: {
            ...mapGetters('users', ['item']),
            ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'currentOrganisationId']),
        },
        methods: {
            onComplete(data) {
              console.log("NEW DATA IS ",data);
              if(data && data.accountCode) {
                this.$router.back();
              }
            }
        }
    }
</script>

<style scoped>

</style>
