<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :save-disabled="note == null || note === ''"
      :on-save-listeners="['assetUpdated']"
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :before-save="beforeSave"
      :item-transform="itemTransform"
      :btn-text="formTitle"
      v-on:itemUpdated="assetUpdated"
      :initial-item="{assetId : assetId}"
      deleteBtnText="Archive"
      btn-icon="mdi-pencil"
      namespace="assetMeta">
    <template #default="{item,errors,onUpdate}">

        <v-textarea
            label="Note"
            v-model="item.notes"
            :error="errors.notes != null"
            :error-messages="errors.notes"
        />
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import ItemListLoader from "@/components/ItemListLoader.vue";

export default {
  name: "AssetNoteEdit",
  components: {ItemListLoader, ApiSelectBox, FormLoader},
  data() {
    return {
      asset: null,
      note : null
    }
  },
  props: {
    assetId: null
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation']),
    formType() {
      if (this.$router.currentRoute.name === "AssetNote") return 'add';
      return "delete";
    },
    formTitle() {
      return this.$router.currentRoute.name === "AssetNote" ? "Add Note" : "Archive Note";
    }
  },
  methods: {
    assetUpdated(item) {
      this.asset = item;
    },
    onAssetUpdate(asset) {
      console.log("asset UPDATED", asset);
      this.getItem(this.assetId);
    },
    onAssetLoaded(asset) {
      console.log("asset EMITTED ", asset);
    },
    beforeSave(item) {
      item.assetId = this.assetId;
      return item;
    },
    itemTransform(item) {
      return item;
    },
    ...mapActions('assets', ['getItem'])

  }
}
</script>

<style scoped>

</style>
