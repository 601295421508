<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-office-building-outline" :actions="actions" :title="clientAdmin ? 'Organisations' : 'Customers'">
    <paginated-result-loader
        :listeners="['organisationsUpdated']"
        :enable-exports="true"
        :custom-filters="clientAdmin ? {} : {excludeId: organisation.id}"
        namespace="organisations">
      <template v-slot:default="props">
        <OrganisationList :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";

export default {
  name: 'Organisations',
  components: {
    OrganisationList,
    PaginatedResultLoader,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'organisation']),
    actions() {
      var list = [];
      if (!this.clientAdmin) {
        list.push({
          icon: 'mdi-plus-circle-outline',
          title: ('Link new customer'),
          route: {name: 'OrganisationLink'},
          variant: 'success'
        });
      }
      return list;
    }
  }
}
</script>
