<template>
    <div>
            <h1  v-if="!hideTitle" class="primary--text">Privacy Policy</h1>
            <p>Welcome to amTAG Limited's privacy policy.</p>
            <p>amTAG Limited respects your privacy and is committed to protecting your personal data. This privacy
                policy
                will inform you as to how we look after your personal data when you visit our Website (regardless of
                where
                you visit it from) and/or use our mobile application. It tells you about your privacy rights and how the
                law
                protects you.</p>
            <h2 class="primary--text">Introduction</h2>
            <p>This policy (together with our end-user licence agreement as set out at <router-link :to="{name: 'InfoPage', params: {type:'end-user-license'}}" target="_blank">https://amtag.uk/info/end-user-license</router-link> <strong>(EULA)</strong> and
                any
                additional terms of use incorporated by reference into the EULA, together our <strong>Terms of Use</strong>) applies to
                your
                use of:</p>
            <ul>
                <li>Our Website www.amtag.uk (<strong>Website</strong>)</li>
                <li>amTAG mobile application software (<strong>App</strong>) hosted on our Website, once you have downloaded or streamed a
                    copy of the App onto your mobile telephone or handheld device (<strong>Device</strong>).</li>
                <li>Any of the services accessible through the App or Website (<strong>Services</strong>).</li>
            </ul>
            <p>This privacy policy is provided in a layered format so you can click through to the specific areas set
                out
                below. Alternatively, you can download a pdf version of the policy <a href="/hiTag-privacy-policy.pdf"
                                                                                      target="_blank">here</a>
                Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.
            </p>
            <ol>
                <li><a href="#1">IMPORTANT INFORMATION AND WHO WE ARE</a></li>
                <li><a href="#2">THE DATA WE COLLECT ABOUT YOU</a></li>
                <li><a href="#3">HOW IS YOUR PERSONAL DATA COLLECTED?</a></li>
                <li><a href="#4">INFORMATION OBTAINED FROM YOUR amTAG SUPPLIER AND SHARED WITH YOUR amTAG SUPPLIER</a></li>
                <li><a href="#5">HOW WE USE YOUR PERSONAL DATA</a></li>
                <li><a href="#6">DISCLOSURES OF YOUR PERSONAL DATA</a></li>
                <li><a href="#7">INTERNATIONAL TRANSFERS</a></li>
                <li><a href="#8">DATA RETENTION</a></li>
                <li><a href="#9">DATA SECURITY</a></li>
                <li><a href="#10">YOUR LEGAL RIGHTS</a></li>
                <li><a href="#11">GLOSSARY</a></li>
            </ol>
            <a id="1"></a>
            <h2 class="primary--text">1. Important information and who we are</h2>
            <h3>Purpose of this privacy policy</h3>
            <p>This privacy policy aims to give you information on how amTAG Limited collects and processes your
                personal
                data through your use of the Website, the App and the provision of our Services, including any data you
                may
                provide through the Website when an account is set up for you (whether by yourself or by a third party
                on
                your behalf), when you confirm your account, or use your account whether through the portal on the
                Website
                or through the App, as well as when you purchase a product or use our Service.</p>
            <p>Neither the Website nor the App are not intended for children and we do not knowingly collect data
                relating
                to children.</p>
            <p>It is important that you read this privacy policy together with any other privacy policy or fair
                processing
                policy we may provide on specific occasions when we are collecting or processing personal data about you
                so
                that you are fully aware of how and why we are using your data. This privacy policy supplements other
                notices and privacy policies and is not intended to override them.</p>
            <h3>Controller</h3>
            <p>amTAG Limited is the controller and responsible for your personal data (collectively referred to as "HI
                TAG", "we", "us" or "our" in this privacy policy).</p>
            <h3>Contact details</h3>
            <p>We have appointed a data privacy manager who is responsible for overseeing questions in relation to this
                privacy policy. If you have any questions about this privacy policy, including any requests to exercise
                your
                legal rights, please contact the data privacy manager using the details set out below</p>
            <p>Full name of legal entity: amTAG Limited<br/>
                Email address: info@amtag.uk<br/>
                Postal address: Unit 3 & 4 Royson Way, Hurn Road, Dereham, Norfolk, United Kingdom, NR19 1WD<br/>
                Telephone number:0330 030 9888</p>
            <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK
                regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal
                with
                any concerns you may have before you approach the ICO and should therefore be grateful if you would
                contact
                us in the first instance.</p>
            <h3>Changes to the privacy policy and your duty to inform us of changes</h3>
            <p>We keep our privacy policy under regular review. This version was last updated on January 2022. It may
                change and if it does, these changes will be posted on this page and, where appropriate, notified to you
                when you next start the App. The new policy may be displayed on-screen and you may be required to read
                and
                accept the changes to continue your use of the App or the Services.</p>
            <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed
                if
                your personal data changes during your relationship with us.</p>
            <h3>Third-party links</h3>
            <p>This Website may include links to third-party Websites, plug-ins and applications. Clicking on those
                links
                or enabling those connections may allow third parties to collect or share data about you. We do not
                control
                these third-party Websites and are not responsible for their privacy statements. When you leave our
                Website,
                we encourage you to read the privacy policy of every Website you visit.</p>
            <a id="2"></a>
            <h2 class="primary--text">2. The data we collect about you</h2>
            <p>Personal data, or personal information, means any information about an individual from which that person
                can
                be identified. It does not include data where the identity has been removed (anonymous data).</p>
            <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped
                together as follows:</p>
            <ul>
                <li><strong>Identity Data</strong> includes first name, last name, username or similar identifier, title and date of
                    birth.
                </li>
                <li><strong>Contact Data</strong> includes delivery address, business email address and telephone numbers, business
                    address,
                    employer and position.
                </li>
                <li><strong>Financial Data</strong> includes bank account, payment card details and billing address this is collected for
                    HI
                    TAG Suppliers only.
                </li>
                <li><strong>Transaction Data</strong> includes details about payments to and from you and other details of products and
                    services you have purchased from us.
                </li>
                <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version,
                    time
                    zone setting and location, browser plug-in types and versions, operating system and platform, and
                    other
                    technology on the devices you use to access this Website.
                </li>
                <li><strong>Profile Data</strong> includes your username and password, purchases or orders made by you, your interests,
                    preferences, feedback and survey responses.
                </li>
                <li><strong>Usage Data</strong> includes information about how you use our Website, products and services.</li>
                <li><strong>User Data</strong> includes personal information which you input to the App or Website in respect of your
                    activities relating to products incorporating amTAG Products such as the dates, locations, nature,
                    result
                    of any inspections you carry out together with any additional comments or views which you express
                    (where you
                    can be personally identified from the data inputted) and the serial numbers of any amTAG Products
                    that are
                    allocated to your account.
                </li>
                <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our
                    third
                    parties and your communication preferences.
                </li>
            </ul>

            <p>We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose.
                Aggregated Data could be derived from your personal data but is not considered personal data in law as
                this
                data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data
                to
                calculate the percentage of users accessing a specific Website feature. However, if we combine or
                connect
                Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the
                combined data as personal data which will be used in accordance with this privacy policy.</p>
            <p>We do not collect any Special Categories of Personal Data about you (this includes details about your
                race
                or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions,
                trade
                union membership, information about your health, and genetic and biometric data). Nor do we collect any
                information about criminal convictions and offences.</p>
            <h3>If you fail to provide personal data</h3>
            <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you
                fail to provide that data when requested, we may not be able to perform the contract we have or are
                trying
                to enter into with you (for example, to provide you with goods or services). In this case, we may have
                to
                cancel a product or service you have with us, but we will notify you if this is the case at the
                time.</p>
            <a id="3"></a>
            <h2 class="primary--text">3. How is your personal data collected?</h2>
            <p>We use different methods to collect data from and about you including through:</p>
            <ul>
                <li><strong>Direct interactions.</strong> You may give us your Identity, Contact, Financial and User Data by filling in
                    forms,
                    inputting data into the App or your account, or by corresponding with us by post, phone, email or
                    otherwise.
                    This includes personal data you provide when you:
                </li>
                <li>apply for our products or Services;</li>
                <li>create or approve an account on our Website or App;</li>
                <li>access and use your account with us on our Website or App;</li>
                <li>subscribe to our Service;</li>
                <li>provide feedback to us in relation to our Services or any products incorporating the amTAG
                    Products;
                </li>
                <li>request marketing to be sent to you;</li>
                <li>enter a competition, promotion or survey; or</li>
                <li>give us feedback or contact us.</li>
                <li>Automated technologies or interactions. As you interact with our Website and App, we will
                    automatically
                    collect Technical Data about your equipment, browsing actions and patterns. We collect this personal
                    data by
                    using cookies, server logs and other similar technologies.
                </li>
            </ul>
            <a id="4"></a>
            <h2 class="primary--text">4. Information obtained from your amTAG Supplier and shared with your amTAG Supplier.</h2>
            <p>When you purchase your amTAG product from a amTAG Supplier they will set up an account in your name or
                business name on your behalf. You will be able to access this account via our Website or App. The
                personal
                data which will be provided to us by your amTAG Supplier may include:</p>
            <ul>
                <li>Identity data being your first and last name and your job title;</li>
                <li>Contact data being your business address, email address and contact telephone number;</li>
                <li>User data being the serial numbers of the amTAG products that are allocated to your account.</li>
            </ul>
            <a id="5"></a>
            <h2 class="primary--text">5. How we use your personal data</h2>
            <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal
                data
                in the following circumstances:</p>
            <ul>
                <li>Where we need to perform the contract, we are about to enter into or have entered into with you.
                </li>
                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests
                    and
                </li>
                fundamental rights do not override those interests.
                <li>Where we need to comply with a legal obligation.</li>
            </ul>
            <p>Click <a href="#11">here</a> to find out more about the types of lawful basis that we will
                rely on to process your personal data.
            <p>
            <p>Generally, we do not rely on consent as a legal basis for processing your personal data although we will
                get
                your consent before installing the App on your device or sending third party direct marketing
                communications
                to you via email or text message. You have the right to withdraw consent to marketing at any time by
                contacting us.</p>
            <h3>Purposes for which we will use your personal data</h3>
            <p>We have set out below a description of all the ways we plan to use your personal data, and which of the
                legal bases we rely on to do so. We have also identified what our legitimate interests are where
                appropriate.</p>
            <p>Note that we may process your personal data for more than one lawful ground depending on the specific
                purpose for which we are using your data. Please contact us if you need details about the specific legal
                ground we are relying on to process your personal data where more than one ground has been set out in
                the
                table below.</p>
            <ul>
                <li>We will process your Identity, Contact and Profile Data in order to register you as a new customer
                    in
                    accordance with our legitimate interests and so as to carry out any contract with you or provide you
                    with
                    our Services.
                </li>
                <li>We will process your Identity, Contact, Profile and User Data where you are engaged in the process
                    of
                    servicing, reviewing, replacing or repairing any product to which the amTAG Product is affixed
                    and/or where
                    you have updated the records for any particular product to which the amTAG Product is affixed which
                    is kept
                    on our data base through the Website or App.
                </li>
                <li>We will process your Identity, Contact, Financial, Transaction and Marketing and Communications Data
                    in
                    order for us to process and deliver services to you or in the case of amTAG Suppliers to collect
                    and
                    recover money owed to us on the basis of performing the contract with you and/or which are necessary
                    for our
                    legitimate interests.
                </li>
                <li>We will process your Identity, Contact, Profile and Marketing and Communications Data in order to
                    manage
                    our relationship with you, such as notifying you about changes to our terms or privacy policy and/or
                    asking
                    you to leave a review or take a survey, on the basis of performing our contract with you and because
                    it is
                    necessary for our legitimate interests (i.e. to keep our records updated and to study how customers
                    use our
                    products/services)
                </li>
                <li>We will process your Identity, Contact and Technical Data in order to administer and protect our
                    business,
                    the Website and the App (including troubleshooting, data analysis, testing, system maintenance,
                    support,
                    reporting and hosting of data) because this is necessary for our legitimate interests (for running
                    our
                    business, provision of administration and IT services, network security, to prevent fraud and in the
                    context
                    of a business reorganisation).
                </li>
                <li>We will use your Identity, Contact, Profile, Usage, Marketing and Communication and Technical Data
                    in
                    order to deliver relevant Website and App content and advertisements to you and measure or
                    understand the
                    effectiveness of the advertising we serve on you, which is necessary for our legitimate interests
                    (to study
                    how customers use our product/services, to develop them, to grow our business and to inform our
                    marketing
                    strategy).
                </li>
                <li>We will process your Technical and Usage in order to use data analytics to improve our Website, App,
                    products and services, marketing, customer relationships and experiences which is necessary for our
                    legitimate interests (to define types of customers for our products and services, to keep our
                    Website and
                    App updated and relevant, to develop our business and to inform our marketing strategy).
                </li>
                <li>We will process your Identity, Contact, Technical, Usage, User, Profile and Marketing and
                    Communications
                    Data in order to make suggestions and recommendations to you about goods or services that may be of
                    interest
                    to you which is necessary for our legitimate interests (to develop our products and services and
                    grow our
                    business.)
                </li>
                <li>We will process your Identity, Contact, Financial and Technical Data in order to install the App and
                    register you as a new App user where you have provided your consent.
                </li>
                <li>We will process your Identity, Contact and User Data in order to provide you with the Services
                    requested
                    and so as to create a database of technical data relating to the performance of products to which
                    the amTAG
                    Product has been applied. This is for the benefit of our business and our customers in accordance
                    with our
                    legitimate interests. Please note the database itself will not include any personal data and your
                    personal
                    data will not be visible to other customers.
                </li>
            </ul>
            <h3>Marketing</h3>
            <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing
                and advertising. We have established the following personal data control mechanisms:</p>
            <div class="pl-5">
            <h4>Promotional offers from us</h4>
            <p>We may use your Identity, Contact, Technical, Usage, User and Profile Data to form a view on what we
                think
                you may want or need, or what may be of interest to you. This is how we decide which products, services
                and
                offers may be relevant for you (we call this marketing).</p>
            <p>You will receive marketing communications from us if you have requested information from us or purchased
                goods or services from us and you have not opted out of receiving that marketing.</p>
            <h4>Third-party marketing</h4>
            <p>We will get your express opt-in consent before we share your personal data with any third party for
                marketing purposes.</p>
            <h4>Opting out</h4>
            <p>You can ask us or third parties to stop sending you marketing messages at any time by contacting us at
                any
                time.</p>
            <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to
                us
                as a result of a product/service purchase, product/service experience or other transactions.</p>

            <h4>Cookies</h4>
            <p>You can set your browser to refuse all or some browser cookies, or to alert you when Websites set or
                access
                cookies. If you disable or refuse cookies, please note that some parts of this Website may become
                inaccessible or not function properly. This Website and the App only use strictly necessary cookies.</p>
            <h4>Change of purpose</h4>
            <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably
                consider that we need to use it for another reason and that reason is compatible with the original
                purpose.
                If you wish to get an explanation as to how the processing for the new purpose is compatible with the
                original purpose, please contact us.</p>
            <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the
                legal basis which allows us to do so.</p>
            <p>Please note that we may process your personal data without your knowledge or consent, in compliance with
                the
                above rules, where this is required or permitted by law.</p>
            </div>
            <a id="6"></a>
            <h2 class="primary--text">6. Disclosures of your personal data to third parties</h2>
            <p>Your personal data will only be disclosed to those of our employees or workers that have a need for such
                access for the purpose for which it was collected. Your personal data will not be disclosed to any other
                individuals or other entities except in the following circumstances:</p>
            <ul>
                <li>Your personal data may be shared with your (or your employer or principals) amTAG Supplier for the
                    purposes of account management and so that they can manage their relationship with you in accordance
                    with
                    our and your amTAG Supplier’s legitimate interests.
                </li>
                <li>On occasion, we need to hire other companies to help us to serve you better and in some of these
                    cases we
                    may need to share personal data that is necessary to perform tasks for us.
                </li>
                <li>Where it is necessary for the performance of our contract with you, including where you have asked
                    us to
                    do so or where we need to take steps to enforce any contract which may be entered into between us.
                </li>
                <li>Where we are under a legal duty to do so in order to comply with any legal obligation.</li>
                <li>In order to protect the rights, property or safety of our business, our employees and workers,
                    customers,
                    suppliers and others. This includes exchanging information with other companies and organisations
                    for the
                    purposes of fraud prevention and credit risk reduction.
                </li>
                <li>If we or substantially all of our assets are acquired by a third party, in which case personal data
                    that
                    we hold about our customers will be one of the transferred assets.
                </li>
            </ul>
            <p>We require all third parties that process personal data on our behalf to respect the security of your
                personal data and to treat it in accordance with the law. We do not allow our third-party service
                providers
                to use your personal data for their own purposes and only permit them to process your personal data for
                specified purposes and in accordance with our instructions.</p>
            <a id="7"></a>
            <h2 class="primary--text">7. International transfers</h2>
            <p>Some of our external third parties are based outside the UK so their processing of your personal data
                will
                involve a transfer of data outside the UK.</p>
            <p>Whenever we transfer your personal data out of the UK, we ensure a similar degree of protection is
                afforded
                to it by ensuring at least one of the following safeguards is implemented:</p>
            <ul>
                <li>We will only transfer your personal data to countries that have been deemed to provide an adequate
                    level
                    of protection for personal data.
                </li>
                <li>Where we use certain service providers, we may use specific contracts approved for use in the UK
                    which
                    give personal data the same protection it has in the UK.
                </li>
            </ul>
            <p>
                Please contact us if you want further information on the specific mechanism used by us when transferring
                your personal data out of the UK.</p>
            <a id="8"></a>
            <h2 class="primary--text">8. Data security</h2>
            <p>We have put in place appropriate security measures to prevent your personal data from being accidentally
                lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to
                your
                personal data to those employees, agents, contractors and other third parties who have a business need
                to
                know. They will only process your personal data on our instructions, and they are subject to a duty of
                confidentiality.</p>
            <p> We have put in place procedures to deal with any suspected personal data breach and will notify you and
                any
                applicable regulator of a breach where we are legally required to do so.</p>
            <a id="9"></a>
            <h2 class="primary--text">9. Data retention</h2>
            <h3>How long will you use my personal data for?</h3>
            <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we
                collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or
                reporting requirements. We may retain your personal data for a longer period in the event of a complaint
                or
                if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
            <p>To determine the appropriate retention period for personal data, we consider the amount, nature and
                sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
                personal data, the purposes for which we process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal, regulatory, tax, accounting or other
                requirements.</p>
            <p>We have to keep basic information about our customers (including Contact, Identity, Financial and
                Transaction Data) for six years after they cease being customers for tax purposes.</p>
            <p>In some circumstances you can ask us to delete your data: see your legal rights below for further
                information.</p>
            <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with
                you)
                for research or statistical purposes and to enable us to develop a technical database relating to
                products
                incorporating the amTAG Product, in which case we may use this information indefinitely without further
                notice to you.</p>
            <a id="10"></a>
            <h2 class="primary--text">10. Your legal rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li><strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This
                    enables you
                    to receive a copy of the personal data that we hold about you and to check that we are lawfully
                    processing
                    it.
                </li>

                <li><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any
                    incomplete or
                    inaccurate data that we hold about you corrected, although we may need to verify the accuracy of the
                    new
                    personal data that you provide to us.
                </li>

                <li><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data
                    where
                    there is no good reason for us continuing to process it. You also have the right to ask us to delete
                    or
                    remove your personal data where you have successfully exercised your right to object to processing
                    (see
                    below), where we may have processed your personal data unlawfully or where we are required to erase
                    your
                    personal data to comply with local law. Please note, however, that we may not always be able to
                    comply with
                    your request of erasure for specific legal reasons which will be notified to you, if applicable, at
                    the time
                    of your request. Please note that if you request the removal of all of your personal data we may no
                    longer
                    be able to provide you with the services.
                </li>

                <li><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those
                    of a
                    third party) and there is something about your particular situation which makes you want to object
                    to
                    processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also
                    have the
                    right to object where we are processing your personal data for direct marketing purposes. In some
                    cases, we
                    may demonstrate that we have compelling legitimate grounds to process your personal data which
                    override your
                    rights and freedoms.
                </li>

                <li><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the
                    processing of your personal data in the following scenarios: (a) if you want us to establish the
                    data's
                    accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where
                    you need
                    us to hold the data even if we no longer require it as you need it to establish, exercise or defend
                    legal
                    claims; or (d) you have objected to our use of your data but we need to verify whether we have
                    overriding
                    legitimate grounds to use it.
                </li>

                <li><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a
                    third
                    party you have chosen, your personal data in a structured, commonly used, machine-readable format.
                    Note that
                    this right only applies to automated information which you initially provided consent for us to use
                    or where
                    we used the information to perform a contract with you.
                </li>

                <li><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However,
                    this
                    will not affect the lawfulness of any processing carried out before you withdraw your consent. If
                    you
                    withdraw your consent, we may not be able to provide certain products or services to you. We will
                    advise you
                    if this is the case at the time you withdraw your consent. You can ask us to stop sending you
                    marketing
                    messages at any time by following the “unsubscribe” (or similar) links on any marketing message sent
                    to you
                    or by contacting us at any time. Where you opt out of receiving these marketing messages, this will
                    not
                    apply to personal data provided to us as a result of a purchase, product/service experience or other
                    transactions.
                </li>
            </ul>
            <p>If you wish to exercise any of your above rights, please send a written request to us at the address
                listed
                above addressed for the attention of the data privacy manager.
            <p>
            <p>You will not have to pay a fee to access your personal data or to exercise any of your other rights.
                However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
                Alternatively, we may refuse to comply with your request in these circumstances.</p>
            <p>We may need to request specific information from you to help us confirm your identity and ensure your
                right
                to access your personal data (or to exercise any of your other rights). This is a security measure to
                ensure
                that personal data is not disclosed to any person who has no right to receive it. We may also contact
                you to
                ask you for further information in relation to your request to speed up our response.</p>
            <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a
                month if your request is particularly complex or you have made a number of requests. In this case, we
                will
                notify you and keep you updated.</p>
            <a id="11"></a>
            <h2 class="primary--text">11. Glossary</h2>
            <h3>LAWFUL BASIS</h3>
            <p><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance
                with
                a legal obligation that we are subject to.</p>
            <p><strong>Consent</strong> means processing your personal data where you have signified your agreement by a statement or
                clear
                opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given,
                specific,
                informed and unambiguous indication of what you want. You can withdraw your consent at any time by
                contacting us.</p>
            <p>amTAG Supplier means the third party authorised retailer or trade merchant from whom you purchased the
                Hi
                Tag product(s).</p>
            <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable
                us
                to give you the best service/product and the best and most secure experience. We make sure we consider
                and
                balance any potential impact on you (both positive and negative) and your rights before we process your
                personal data for our legitimate interests. We do not use your personal data for activities where our
                interests are overridden by the impact on you (unless we have your consent or are otherwise required or
                permitted to by law). You can obtain further information about how we assess our legitimate interests
                against any potential impact on you in respect of specific activities by contacting us.</p>
            <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the performance of a
                contract
                to which you are a party or to take steps at your request before entering into such a contract.</p>

    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy",
        props: {
            hideTitle: false
        }
    }
</script>

<style scoped lang="scss">
    h2,h3 {
        margin-top: 10px;
    }
    ul {
        margin-bottom: 16px;
    }
</style>
