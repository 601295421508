<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <item-list-loader
      :disable-filters="true"
      :enable-exports="false"
      :hide-card="true"
      :custom-filters="{assetId: assetId, checkId: checkId}"
      namespace="completedChecks">,
    <template #noResults>
      <v-alert class="ma-4" type="info">
        No checks have been completed for this asset yet.
      </v-alert>
    </template>
    <template #main="props">

      <v-timeline
          align-top
          dense
      >
        <asset-timeline-item
            v-for="item in props.items"
            :uploads="item.uploads"
            :key="item.id"
            :color="$options.filters.statusToColor(item.status)"
            :title="item.check.name">
          <div>{{ item.user?.fullName ? 'by '+item.user?.fullName+' on ' : '' }} {{ $options.filters.formatDate(item.createdAt) }}</div>
          <check-status :status="item.status"></check-status>
          <v-alert v-if="item.notes">
            {{ item.notes }}
          </v-alert>
        </asset-timeline-item>
      </v-timeline>
    </template>
  </item-list-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import HoseConfigurationView from "../../../components/asset-types/AssetTypeView";
import AssetTypeView from "@/components/asset-types/AssetTypeView";
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import CompletedTaskList from "@/components/completed-tasks/CompletedTaskList";
import AssetTimelineItem from "@/views/portal/assets/AssetTimelineItem";
import CheckStatus from "@/components/checks/CheckStatus";
import ItemListLoader from "@/components/ItemListLoader.vue";

export default {
  name: "AssetCheckTimeline",
  components: {
    ItemListLoader,
    CheckStatus,
    AssetTimelineItem,
    CompletedTaskList, PaginatedResultLoader, AssetTypeView, HoseConfigurationView, ApiSelectBox, FormLoader
  },
  data() {
    return {}
  },
  props: {
    assetId: null,
    checkId: null,
  },
  methods: {
    ...mapActions('assets', ['getItem'])
  },
  computed: {
    ...mapGetters('assets', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation']),
  }
}
</script>

<style scoped>

</style>
