<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-database-cog" title="Clients" :actions="[
    {icon: 'mdi-plus', variant: 'success', title: 'Add client', route: {name : 'ClientAdd'}}
  ]">
    <paginated-result-loader
        :enable-exports="false"
        namespace="clientsEdit">
      <template v-slot:default="props">
        <client-list :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import ClientList from "@/components/clients/ClientList.vue";


export default {
  name: 'Clients',
  components: {
    ClientList,
    PaginatedResultLoader,
    CrudPage
  },
}
</script>
