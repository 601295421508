<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <centered-layout :large="true" :boxed="false">
        <v-form @submit.prevent="save">
            <h1>Select your organisation type:</h1>
            <v-card>
                <div class="container ma-0">
                    <v-row class="text-center">
                        <v-col :cols="6" @click="() => { type = 'd' }"
                               :class="type === 'd' ? 'primary white--text rounded cursor' : 'cursor'">
                            <div class="pa-3 border_right">
                                <v-icon size="40" class="mb-2" :color="type === 'd' ? 'white' : 'primary'">mdi-sitemap
                                </v-icon>
                                <h2>Distributor</h2>
                                <p>My business distributes assets to multiple customers</p>

                            </div>
                        </v-col>
                        <v-col :cols="6" @click="() => { type = 'c' }"
                               :class="type === 'c' ? 'primary white--text rounded cursor' : 'cursor'">
                            <div class="pa-3 border_right">
                                <v-icon size="40" class="mb-2" :color="type === 'c' ? 'white' : 'primary'">
                                    mdi-account-tie
                                </v-icon>
                                <h2>Customer</h2>
                                <p>My business uses assets within the organisation</p>

                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <template v-if="type !== null">
                <h1 class="mt-4">Business info:</h1>
                <v-card>
                    <div class="container ma-0">
                        <v-row>
                            <v-col :cols="12">
                                <v-text-field
                                        label="Organisation name*"
                                        v-model="item.name"
                                        :error="errors.name != null"
                                        :error-messages="errors.name"
                                />
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field label="Contact name*"
                                              v-model="item.contactName"
                                              :error="errors.contactName != null"
                                              :error-messages="errors.contactName"/>
                            </v-col>
                            <v-col :cols="6">
                                <v-text-field
                                        label="Contact email*"
                                        v-model="item.contactEmail"
                                        :error="errors.contactEmail != null"
                                        :error-messages="errors.contactEmail"/>
                            </v-col>

                            <v-col :cols="6">
                                <v-text-field
                                        label="Address line 1*"
                                        v-model="item.addressLine1"
                                        :error="errors.addressLine1 != null"
                                        :error-messages="errors.addressLine1"/>
                            </v-col>

                            <v-col :cols="6">
                                <v-text-field
                                        label="Address line 2"
                                        v-model="item.addressLine2"
                                        :error="errors.addressLine2 != null"
                                        :error-messages="errors.addressLine2"/>
                            </v-col>

                            <v-col :cols="6">
                                <v-text-field
                                        label="Town/City*"
                                        v-model="item.town"
                                        :error="errors.town != null"
                                        :error-messages="errors.town"/>
                            </v-col>

                            <v-col :cols="6">
                                <v-text-field
                                        label="Post code*"
                                        v-model="item.postCode"
                                        :error="errors.postCode != null"
                                        :error-messages="errors.postCode"/>
                            </v-col>

                            <v-col :cols="12">
                                <global-error-inline :errors="errors"></global-error-inline>
                                * denotes required fields
                            </v-col>

                        </v-row>
                    </div>
                </v-card>

                <div class="d-flex justify-end">
                    <v-btn type="submit" class="mt-4" color='primary' depressed dark :loading="loading">
                        <v-icon small class="mr-1">mdi-plus</v-icon>
                        Create organisation
                    </v-btn>
                </div>

            </template>
        </v-form>
    </centered-layout>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import CenteredLayout from "../layouts/CenteredLayout";
    import GlobalErrorInline from "../components/GlobalErrorInline";
    import ApiSelectBox from "../components/ApiSelectBox";
    import {endpoints, httpRequest} from "../store/network/amtag.client";

    export default {
        name: 'OrganisationCreate',
        data: () => ({
            loading: false,
            email: null,
            password: null,
            type: null,
            item: {
                type: Object,
                default: {}
            },
            errors: {
                type: Object,
                default: {}
            },
        }),
        computed: {
            ...mapGetters('auth', ['user', 'multiOrg', 'clientAdmin', 'loginError','systemAdmin']),
        },
        created() {
            if (!this.item.contactName) {
                this.item.contactName = this.user.firstName + ' ' + this.user.lastName
            }
            if (!this.item.contactEmail) {
                this.item.contactEmail = this.user.email
            }
        },
        watch: {
            item(newVal,oldVal) {
                if(this.item.id) {
                    //SYNC THE USER
                    this.me().then(() => {
                        console.log("USER SYNCED");
                        if(this.clientAdmin || this.systemAdmin) {
                            this.$router.push({ name : 'Portal', params: {currentOrganisationId : 'admin'}}).then(() => {
                                this.setUserMessage("Organisation created, the new account code is " + this.item.accountCode);
                            })
                        } else {
                          this.$router.push({
                            name: 'Portal',
                            params: {currentOrganisationId: this.item.id}
                          }).then(() => {
                            this.setUserMessage("Organisation created, your new account code is " + this.item.accountCode);
                          });
                        }
                    });
                }
            },
        },
        methods: {
            ...mapActions('auth', ['login','me']),
            ...mapActions('alerts', ['setUserMessage']),
            async save() {
                this.errors = {};
                this.loading = true;
                this.item.isDistributor = this.type === 'd';
                try {
                    var res = await httpRequest(endpoints.organisations.addItem, this.item);
                    this.item = res.data;
                } catch (e) {
                    console.log("error", e.response.data);
                    this.errors = e.response.data;
                } finally {
                    this.loading = false;
                }
            }
        },
        components: {ApiSelectBox, GlobalErrorInline, CenteredLayout},
    }
</script>
