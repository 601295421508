<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-view-dashboard-outline" title="Dashboard">
    <template v-if="systemAdmin && !clientAdmin && !customer && !distributor">
      <v-alert type="info" outlined>
        <div class="d-flex align-center justify-space-between">
          <span>You are a system admin, but not a client admin of this system. To access data from this client please login as an admin for this client.</span>
          <v-btn color="primary" small :to="{name : 'ClientUsers', params :{clientId : currentClient.id}}">Login as admin</v-btn>
        </div>
      </v-alert>
    </template>

    <template v-else>

      <template v-if="clientAdmin">
        <item-list-loader
            namespace="organisations"
            :custom-filters="{isDistributor: true}"
            :limit="1000"
        >
          <template v-slot:main="orgSlot">
            <v-autocomplete
                class="mb-3"
                :deletable-chips="true"
                :hide-no-data="true"
                :hide-selected="true"
                solo
                :clearable="true"
                :hide-details="true"
                v-model="selectedOrgs"
                :chips="true"
                :multiple="true"
                :items="orgSlot.items"
                item-text="name"
                item-value="id"
                :label="selectedOrgs.length > 0 ? 'Showing ' + selectedOrgs.length + ' selected' : 'Select Distributors'"
                placeholder="Showing all"
            ></v-autocomplete>
          </template>

        </item-list-loader>
      </template>


      <template v-if="clientAdmin">
        <v-row align="stretch">
        <v-col>
          <ApiCountCard :custom-filters="addSelectedOrgs({isDistributor:'true'},'id')"
                        :to="{name:'Organisations', query: { filters : filtersString({isDistributor: true})}}"
                        text="Distributors"
                        classes=""
                        namespace="organisations"/>
        </v-col>
        <v-col>
          <ApiCountCard :custom-filters="addSelectedOrgs({isDistributor:'false'},'distributorId')"
                        :to="{name:'Organisations', query: { filters : filtersString({isDistributor: false})}}"
                        text="Customers"
                        classes=""
                        namespace="organisations"/>
        </v-col>

        <v-col>
          <ApiCountCard :to="{name:'Assets'}" text="Tagged Assets"
                        :custom-filters="addSelectedOrgs({})"
                        classes="" namespace="assets"/>
        </v-col>
        </v-row>
        <v-row align="stretch">
        <v-col>
          <ApiCountCard
              :to="{name:'Assets', query: { filters : filtersString({installationStatus:'2'})}}"
              :custom-filters="addSelectedOrgs({installationStatus : '2'})" text="Unallocated Assets"
              classes="grey" namespace="assets"/>
        </v-col>

        <v-col>
          <ApiCountCard
              :to="{name:'Assets', query: { filters : filtersString({taskStatus: '1'})}}"
              text="Tasks Due" :custom-filters="addSelectedOrgs({due: '1'})"
              classes="accent white--text" namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '2'})}}"
                        text="Tasks to Monitor" :custom-filters="addSelectedOrgs({taskResult: ['2']})" classes="warning white--text"
                        namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '3'})}}"
                        text="Tasks to Action" :custom-filters="addSelectedOrgs({taskResult: ['3']})" classes="error white--text"
                        namespace="checksDue"/>
        </v-col>
        </v-row>

      </template>
      <template v-else-if="customer">
        <v-row align="stretch">
        <v-col>
          <ApiCountCard :to="{name:'Assets'}" text="Tagged Assets"
                        classes="" namespace="assets"/>
        </v-col>
        <v-col>
          <ApiCountCard

              :to="{name:'Assets', query: { filters : filtersString({installationStatus : '2'})}}"
              :custom-filters="{installationStatus : '2'}" text="Unallocated Assets"
              classes="grey" namespace="assets"/>
        </v-col>

        <v-col>
          <ApiCountCard
              :to="{name:'Assets', query: { filters : filtersString({taskStatus: '1'})}}"
              text="Tasks Due" :custom-filters="{due: '1'}"
              classes="accent white--text" namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '2'})}}"
                        text="Tasks to Monitor" :custom-filters="{taskResult: '2'}" classes="warning white--text"
                        namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '3'})}}"
                        text="Tasks to Action" :custom-filters="{taskResult: '3'}" classes="error white--text"
                        namespace="checksDue"/>
        </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row align="stretch">
        <v-col>
          <ApiCountCard :to="{name:'Assets'}" text="Tagged Assets"
                        classes="" namespace="assets"/>
        </v-col>
        <v-col>
          <ApiCountCard
              :to="{name:'Assets', query: { filters : filtersString({installationStatus: '2'})}}"
              :custom-filters="{installationStatus : '2'}" text="Unallocated Assets"
              classes="grey" namespace="assets"/>
        </v-col>

        <v-col>
          <ApiCountCard
              :to="{name:'Assets', query: { filters : filtersString({taskStatus: '1'})}}"
              text="Tasks Due" :custom-filters="{due: '1'}"
              classes="accent white--text" namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '2'})}}"
                        text="Tasks to Monitor" :custom-filters="{taskResult: '2'}" classes="warning white--text"
                        namespace="checksDue"/>
        </v-col>
        <v-col>
          <ApiCountCard :to="{name:'Assets', query: { filters : filtersString({taskResult: '3'})}}"
                        text="Tasks to Action" :custom-filters="{taskResult: '3'}" classes="error white--text"
                        namespace="checksDue"/>
        </v-col>
        </v-row>
      </template>


    <v-row align="stretch">
      <template v-if="clientAdmin">
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Distributors</h2>
            <v-btn depressed color="white" :to="{name : 'Organisations'}" small>See all</v-btn>
          </div>
          <item-list-loader
              cardHeight="450"
              :custom-filters="addSelectedOrgs({isDistributor : true},'id')"
              :disable-filters="true"
              namespace="organisations">
            <template v-slot:main="props">
              <organisation-list :full="false" :items="props.items"/>
            </template>
          </item-list-loader>
        </v-col>
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Customers</h2>
            <v-btn depressed color="white" :to="{name : 'Organisations'}" small>See all</v-btn>
          </div>
          <item-list-loader
              cardHeight="450"
              :custom-filters="addSelectedOrgs({isDistributor : false},'distributorId')"
              :disable-filters="true"
              namespace="organisations">
            <template v-slot:main="props">
              <organisation-list :full="false" :items="props.items"/>
            </template>
          </item-list-loader>
        </v-col>
      </template>
      <template v-else-if="customer">
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Tasks Due</h2>
          </div>
          <paginated-result-loader
              cardHeight="450"
              :disable-filters="true"
              :custom-filters="{due:'1'}"
              namespace="checksDueDashboard1">
            <template v-slot:default="props">
              <check-list :full="false" :items="props.items"/>
            </template>
          </paginated-result-loader>
        </v-col>
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Tasks to Action</h2>
          </div>
          <paginated-result-loader
              cardHeight="450"
              :disable-filters="true"
              :custom-filters="{taskResult:'3'}"
              namespace="checksDueDashboard2">
            <template v-slot:default="props">
              <check-list :full="false" :items="props.items"/>
            </template>
          </paginated-result-loader>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Customers</h2>
          </div>
          <paginated-result-loader
              cardHeight="450"
              :custom-filters="clientAdmin ? [] : [{key: 'excludeId',value: organisation.id}]"
              :disable-filters="true"
              namespace="organisationsDashboard">
            <template v-slot:default="props">
              <organisation-list :items="props.items"/>
            </template>
          </paginated-result-loader>
        </v-col>
        <v-col cols="12" :lg="6" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 class="card-header mb-2 flex-fill">Tasks to Action</h2>
          </div>
          <paginated-result-loader
              cardHeight="450"
              :disable-filters="true"
              :custom-filters="{taskResult:'3'}"
              namespace="checksDueDashboard2">
            <template v-slot:default="props">
              <check-list :full="false" :items="props.items"/>
            </template>
          </paginated-result-loader>
        </v-col>

      </template>


    </v-row>
    </template>
  </crud-page>
</template>

<script>

import CrudPage from "../../components/CrudPage";
import ApiCountCard from "./ApiCountCard";
import PaginatedResultLoader from "../../components/PaginatedResultLoader";
import OrganisationList from "../../components/organisations/OrganisationList";
import {mapGetters} from "vuex";
import HoseList from "../../components/assets/AssetList";
import AssetList from "@/components/assets/AssetList";
import CheckList from "@/components/checks/CheckList";
import ItemListLoader from "@/components/ItemListLoader.vue";


export default {
  name: 'Dashboard',
  components: {
    ItemListLoader,
    CheckList,
    AssetList,
    HoseList,
    OrganisationList,
    PaginatedResultLoader,
    ApiCountCard,
    CrudPage,
  },
  data() {
    return {
      selectedOrgs: []
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor', 'customer', 'organisation','systemAdmin']),
  },
  methods: {
    addSelectedOrgs(currentFilters, key = 'distributorOrganisationId') {
      if(this.selectedOrgs.length > 0) {
        currentFilters[key] = this.selectedOrgs
      }
      return currentFilters;
    },
    addSelectedOrgsArray(currentFilters, key = 'distributorOrganisationId') {
      if(this.selectedOrgs.length > 0) {
        currentFilters.push({key : key, value : this.selectedOrgs});
      }
      return currentFilters;
    },
    filtersString(data) {
      return encodeURI(JSON.stringify(data));
    }
  }
}
</script>
