<template>
  <div>
    <div><small v-html="label">{{ label }}:</small></div>
    <paginated-result-loader
        :hide-card="true"
        :no-results="noResults"
        :custom-filters="customFilters"
        :namespace="namespace" :disable-filters="true">
      <template v-slot:default="props">
        <div v-for="task in props.items" class="rounded light pa-2 mt-1">
          <div @click="toggleTask(task)"
               :class="disableSelection ? 'd-flex justify-space-between' : 'd-flex justify-space-between cursor'">
            <div class="d-flex">
              <v-icon v-if="!disableSelection" class="mr-2">
                {{ isTaskSelected(task.id) ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline' }}
              </v-icon>
              <div class="font-weight-bold"><small>{{ task.name }}</small></div>
            </div>
            <check-timeline-chip v-if="!editableFrequency || !isTaskSelected(task.id)" short
                                 :check-timeline="task.checkTimeline"/>

          </div>
          <v-chip small  v-if="task.organisationId !== currentOrganisationId" color="info">Configured by {{ task.organisation.name }}</v-chip>
          <check-timeline-input
              v-if="editableFrequency && isTaskSelected(task.id)"
              v-model="selectedTasks.filter(t => t.id === task.id)[0].checkTimeline"></check-timeline-input>

        </div>
      </template>
    </paginated-result-loader>
  </div>
</template>

<script>
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import CheckTimelineChip from "@/components/CheckTimelineChip";
import CheckTimelineInput from "@/components/CheckTimelineInput";
import {mapGetters} from "vuex";

export default {
  name: "TaskSelectionInput",
  components: {CheckTimelineInput, CheckTimelineChip, PaginatedResultLoader},
  props: {
    editableFrequency: false,
    namespace: {
      type: String,
      default() {
        return "checks";
      }
    },
    assetId: {
      type: String,
      default() {
        return null;
      }
    },
    excludeAssetId: {
      type: String,
      default() {
        return null;
      }
    },
    disableSelection: false,
    maxSelection: {
      type: Number,
      default() {
        return null;
      }
    },
    noResults: {
      type: String,
      default() {
        return "No tasks found for this sector";
      }
    },
    sectorId: {
      type: String,
      default() {
        return null;
      }
    },
    value: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default() {
        return "Select recommended tasks for this asset </br> (Selected tasks will appear at the top of the list)"
      }
    }
  },
  data() {
    return {
      selectedTasks: this.value,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentOrganisationId']),
    customFilters() {
      var data = [{key: 'sectorId', value: this.sectorId}];
      if (this.assetId != null) {
        data.push({key: 'assetId', value: this.assetId})
      }
      if (this.excludeAssetId != null) {
        data.push({key: 'excludeAssetId', value: this.excludeAssetId})
      }
      return data;
    }
  },
  methods: {
    toggleTask(task) {
      if (this.disableSelection) return;

      if (this.editableFrequency) {

        if (!this.isTaskSelected(task.id)) {
          this.selectedTasks.push({
            id: task.id,
            checkTimeline: task.checkTimeline
          });
        } else {
          this.selectedTasks = this.selectedTasks.filter(t => t.id !== task.id);
        }
      } else {
        if (this.selectedTasks === undefined) {
          this.selectedTasks = [];
        }
        var currentIndex = this.selectedTasks.indexOf(task.id);
        if (currentIndex === -1) {
          this.selectedTasks.push(task.id);
        } else {
          this.selectedTasks.splice(currentIndex, 1);
        }
      }
    },
    isTaskSelected(taskId) {
      if (!this.selectedTasks) return false;
      if (this.editableFrequency) {
        return this.selectedTasks.filter(t => t.id === taskId).length > 0
      } else {
        var currentIndex = this.selectedTasks.indexOf(taskId);
        return currentIndex !== -1;
      }
    },
  },
  watch: {
    selectedTasks(newVal) {
      this.$emit('input', this.selectedTasks);
    },
    sectorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedTasks = [];
      }
    }
  }
}
</script>

<style scoped>

</style>
