<template>
  <component :is="component">
    <slot v-if="loading" name="loader" v-bind:itemid="item">
      <v-progress-circular v-if="(item === {} || item === null || Object.keys(item).length === 0) && loading" :indeterminate="true"
                           color="primary"></v-progress-circular>
      <v-progress-linear v-if="(item !== {} && item !== null && Object.keys(item).length > 0) && loading" :indeterminate="true"
                         color="primary"></v-progress-linear>
    </slot>
    <template v-if=" forceSlot || (item !== undefined && item !== {} && item != null && Object.keys(item).length > 0)">
      <slot v-bind:item="item" v-bind:onUpdate="onUpdate">

      </slot>
    </template>
  </component>
</template>

<script>
import GlobalErrorInline from "./GlobalErrorInline";
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import emitter from "@/utils/emitter.js";

export default {
  name: "ItemLoader",
  components: {GlobalErrorInline},
  emits: ['itemUpdated'],
  props: {
    customEndpoint : null,
    hideOnLoading : false,
    listeners: {
      type: Array,
      default() {
        return [];
      }
    },
    component: {
      type: String,
      default() {
        return "div"
      }
    },
    forceSlot: false,
    id: null,
    namespace: null,
    noResults: "No results for your filter",
    itemTransform: {
      type: Function,
      default: null
    },
    resetId: {
      type: String,
      default() {
        return "123";
      }
    },
    initialItem: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data: () => ({
    errors: {},
    lastUpdated: null,
    item: {},
    loading: false,

  }),
  created() {
    this.item = this.initialItem
    if (this.id != null)
      this.getItem();
    this.listeners.forEach(listenerKey => {
      emitter.on(listenerKey, () => {
        this.getItem();
      });
    })
  },
  beforeDestroy() {
    this.listeners.forEach(listenerKey => {
      emitter.off(listenerKey);
    });
  },
  watch: {
    item(newVal, oldVal) {
      if (this.itemTransform != null) {
        this.item = this.itemTransform(newVal)
      } else {
        this.item = newVal;
      }
      this.lastUpdated = this.item.updatedAt;
      this.$emit("itemUpdated", this.item);
    },
    resetId(val) {
      this.getItem()
    },
    id(newVal, oldVal) {
      console.log("ID CHANGED " + this.namespace + " " + this.id)
      this.getItem();
    },
    saveError(newVal, oldVal) {
      console.log("NEW ERRORS");
      this.errors = newVal;
    },
    initialItem(newVal, oldVal) {
      this.item = newVal;
    }
  },
  computed: {},
  methods: {
    async getItem() {
      this.loading = true;
      try {
        var finalEndpoint = this.customEndpoint != null ? this.customEndpoint : endpoints[this.namespace].getItem(this.id);
        let res = await httpRequest(finalEndpoint, null);
        this.item = res.data;
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    },
    onUpdate(item) {
      this.item = item;
      this.$emit("itemUpdated", item);
    },
  },
}
</script>

<style lang="scss">
.card-header {
  color: var(--v-text-base);
}

.v-list-item__icon {
  margin-right: 10px !important;
}
</style>
