<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-toolbox" title="Asset import">
    <v-card class="pa-4" v-if="clientAdmin">
      <item-list-loader
          namespace="organisations"
          :custom-filters="{isDistributor: true}"
          :limit="1000"
      >
        <template v-slot:main="orgSlot">
          <v-select
              :hide-details="true"
              v-model="selectedOrg"
              :items="orgSlot.items"
              item-text="name"
              item-value="id"
              label="Select organisation"
          ></v-select>
        </template>
      </item-list-loader>

    </v-card>

    <v-card v-if="selectedOrg" class="pa-4 mt-4">
      <item-loader namespace="organisations" :id="selectedOrg">
        <template v-slot:default="singleSlot">
          <h2 class="text-h6">Import assets for {{ singleSlot.item.name }}</h2>
          <v-alert color="light" class="mb-4">
            <div class="d-flex justify-space-between">
              You can import assets for this organisation by editing and uploading this blank template CSV file.
              <v-btn depressed color="info" :loading="exportLoading" @click="exportExisting(true)" x-small>Download
                blank template
              </v-btn>
              <global-error-inline :errors="exportErrors"/>
            </div>
          </v-alert>
          <v-file-input
              v-model="file"
              :label="file ? file.name : 'Select CSV file'"
              outlined
              accept=".csv"
              @change="readFile"
          ></v-file-input>

          <global-error-inline :errors="errors"/>

          <!-- read lines -->
          <div v-if="readLines.length > 0" class="mt-4">
            <item-list-loader
                namespace="assetTypes"
                :limit="10000"
                :disable-no-results="true"
                :on-new-data="updateAssetTypes"
                :custom-filters="{organisationId: [selectedOrg]}">
              <template #main="assetTypesSlot">
                <item-list-loader
                    namespace="sectors"
                    :limit="1000"
                    :disable-no-results="true"
                    :on-new-data="updateSectors"
                    :custom-filters="{organisationId: [selectedOrg]}">
                  <template #main="sectorSlot">
                    <v-simple-table class="mb-4" dense>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Validation</th>
                          <th v-for="header in readHeaders" :key="header">{{ header }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(line,lineIndex) in readLines" :key="line.id">
                          <td>
                            <v-menu
                                :offset-y="true"
                                :close-on-content-click="true"
                            >
                              <template v-slot:activator="{ on: menu, attrs }">
                                <v-btn
                                    color="light"
                                    elevation="0"
                                    class="px-0"
                                    small
                                    v-bind="attrs"
                                    :color="alerts(line,assetTypesSlot.items,sectorSlot.items).type"
                                    tooltip="View messages"
                                    v-on="{ ...menu }"
                                >
                                  <v-icon size="13" color="white">{{
                                      typeToIcon(alerts(line, assetTypesSlot.items, sectorSlot.items).type)
                                    }}
                                  </v-icon>
                                  <v-icon size="13">mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-card style="width: 150px" class="pa-3">
                                <div :class="alert.type+'--text'" style="font-size: 12px;"
                                     v-for="(alert,alertKey) in alerts(line,assetTypesSlot.items,sectorSlot.items).alerts"
                                     :key="alert.message">
                                  <v-icon size="12" class="mr-2" :color="alert.type">{{
                                      typeToIcon(alert.type)
                                    }}
                                  </v-icon>
                                  {{ alert.message }}
                                </div>
                              </v-card>
                            </v-menu>

                          </td>
                          <td v-for="(header,headerIndex) in readHeaders"
                              :key="line[header]+'-'+lineIndex+'-'+headerIndex">
                            {{ line[header] }}
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <v-alert color="error" class="white--text font-weight-bold"
                             v-if="alertData.filter(_ => _.type === 'error').length > 0">
                      There are errors in the file. Please fix them before importing
                    </v-alert>
                    <v-alert color="warning" class="white--text font-weight-bold"
                             v-else-if="alertData.filter(_ => _.type === 'warning').length > 0">
                      There are warnings in the file. Please review them before importing
                    </v-alert>

                    <all-error-inline :errors="importErrors"/>

                    <v-btn v-if="alertData.filter(_ => _.type === 'error').length === 0" color="success"
                           @click="importAssetTypes" :loading="importLoading"
                           :disabled="loading || readLines.length === 0">Process import for {{ readLines.length }}
                      assets
                    </v-btn>
                  </template>
                </item-list-loader>
              </template>
            </item-list-loader>

          </div>
        </template>
      </item-loader>
    </v-card>

  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import ApiSelectBox from "@/components/ApiSelectBox.vue";
import ItemListLoader from "@/components/ItemListLoader.vue";
import ItemLoader from "@/components/ItemLoader.vue";
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import GlobalErrorInline from "@/components/GlobalErrorInline.vue";
import Papa from "papaparse";
import AllErrorInline from "@/components/AllErrorInline.vue";

export default {
  name: 'AssetTypesImport',
  data() {
    return {
      selectedOrg: null,
      searchResetId: 0,
      loading: false,
      assetTypes: [],
      sectors: [],
      errors: {},
      file: null,
      exportErrors: {},
      exportLoading: false,
      importErrors: {},
      importLoading: false,
      readHeaders: [],
      readLines: [],
      alertData: [],
      headers: [
        "Asset Name",
        "Sector",
        "Notes",
      ]
    }
  },
  components: {
    AllErrorInline,
    GlobalErrorInline,
    ItemLoader,
    ItemListLoader,
    ApiSelectBox,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    if (!this.clientAdmin) {
      this.selectedOrg = this.currentOrganisationId;
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'currentOrganisationId'])
  },
  methods: {
    async importAssetTypes() {
      this.importLoading = true;
      this.importErrors = {};
      try {
        let res = await httpRequest(endpoints.assetTypes.import(this.selectedOrg), {
          assets: this.readLines.map(line => {
            let additionalFields = {
              ...line
            }
            delete additionalFields['DO-NOT-EDIT'];
            delete additionalFields.Name;
            delete additionalFields.Sector;
            delete additionalFields.Notes;
            return {
              id: line['DO-NOT-EDIT'] !== '' ? line['DO-NOT-EDIT'] : null,
              name: line['Asset Name'],
              sector: line.Sector,
              notes: line.Notes,
              additionalFields: additionalFields
            }
          })
        });
        await this.$router.push({name: 'AssetTypes'});
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.importErrors = e.response.data;
      } finally {
        this.importLoading = false;
      }
    },
    typeToIcon(type) {
      switch (type) {
        case 'error':
          return 'mdi-alert';
        case 'warning':
          return 'mdi-alert-circle';
        case 'success':
          return 'mdi-check-circle';
      }
    },
    alerts(line, existingAssets, sectors) {
      let alerts = [];
      //Check for duplicate line ids except empty ones
      var currentId = line['DO-NOT-EDIT'];

      if (currentId !== '') {
        if (this.readLines.filter(l => l['DO-NOT-EDIT'] === currentId).length > 1) {
          alerts.push({type: 'error', message: 'Duplicate line'});
        }
      }
      // if (existingAssets.some(config => config.name.toLowerCase() === line.Name.toLowerCase() && config.sector.name.toLowerCase() === line.Sector.toLowerCase() && config.id.toLowerCase() !== currentId.toLowerCase())) {
      //   alerts.push({type: 'error', message: 'Name already exists within this sector'});
      // }
      // //Check for duplicates within upload
      // if (this.readLines.filter(l => l.Name.toLowerCase() === line.Name.toLowerCase() && l.Sector.toLowerCase() === line.Sector.toLowerCase()).length > 1) {
      //   alerts.push({type: 'error', message: 'Asset names must be unique within each sector'});
      // }

      if (!sectors.some(sector => sector.name.toLowerCase() === line.Sector.toLowerCase())) {
        alerts.push({type: 'error', message: 'Sector does not exist :' + line.Sector});
      }

      if (alerts.filter(alert => alert.type === 'error').length > 0) {
        alerts.push({type: 'error', message: 'This line will prevent file being imported'});
      } else {
        if (currentId !== '') {
          if (existingAssets.some(config => config.id.toLowerCase() === currentId.toLowerCase())) {
            alerts.push({type: 'success', message: 'Updating asset'});
          } else {
            alerts.push({type: 'error', message: 'Please leave DO-NOT-EDIT empty for new assets'});
          }
        } else {
          alerts.push({type: 'success', message: 'Creating new asset'});
        }
      }
      return {
        type: alerts.filter(alert => alert.type === 'error').length > 0 ? 'error' : alerts.filter(alert => alert.type === 'warning').length > 0 ? 'warning' : 'success',
        alerts: alerts
      };
    },
    updateAssetTypes(assets) {
      this.assetTypes = assets;
      this.updateAlerts();
    },
    updateSectors(sectors) {
      this.sectors = sectors;
      this.updateAlerts();
    },
    updateAlerts() {
      let vm = this;
      this.alertData = this.readLines.map(line => vm.alerts(line, vm.assets, vm.sectors));
    },
    readFile() {
      this.errors = {};
      this.loading = true;
      this.readLines = [];

      if (this.file) {
        console.log("READING");
        var _this = this;
        Papa.parse(this.file, {
          headers: true,
          skipEmptyLines: true,
          complete: (results) => {
            console.log("results", results);
            let headers = results.data[0];
            //Get the rest of the data in a variable skipping the header
            // check if header values match
            let headerErrors = [];
            headers.forEach((header, index) => {
              // Trim quotes if necessary
              header = header.replace(/^"|"$/g, '');
              if (index === 0) {
                if (header !== 'DO-NOT-EDIT') {
                  headerErrors.push(`Expected DO-NOT-EDIT but got ${header}`);
                }
              } else if (index < this.headers.length) {
                if (header !== this.headers[index - 1]) {
                  headerErrors.push(`Expected ${this.headers[index - 1]} but got ${header}`);
                }
              }
            });
            if (headerErrors.length > 0) {
              _this.errors = {global: headerErrors};
              _this.loading = false;
              return;
            }
            if (results.data.length === 1) {
              _this.errors = {global: ["No data in file"]};
              _this.loading = false;
              return;
            }
            //Set the readHeaders but cut the first one
            this.readHeaders = headers.slice(1);
            let lines = [];
            for (let i = 1; i < results.data.length; i++) {
              // Properly split each line considering commas inside quotes
              let data = results.data[i];
              if (data.length === headers.length) {
                let tarr = {};
                for (let j = 0; j < headers.length; j++) {
                  tarr[headers[j]] = data[j];
                }
                lines.push(tarr);
              }
            }
            _this.readLines = lines;
            _this.updateAlerts();
            _this.loading = false;
          },
          error: (error) => {
            _this.errors = {global: [error.message]};
            _this.loading = false;
          }
        });
      }
    },
    async exportExisting(blank = false) {
      this.exportErrors = {}
      this.exportLoading = true;
      try {
        let res = await httpRequest(endpoints.assetTypes.exportTemplate(this.selectedOrg, blank), {});
        window.open(res.data.url, '_blank');
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.exportErrors = e.response.data;
      } finally {
        this.exportLoading = false;
      }
    },
  }
}
</script>
