<template>
  <v-row class="flex-fill" align="stretch">
    <v-col v-if="!disableFilters"
           cols="12"
           md="2"
           class="d-flex flex-column"
    >
      <h2 class="card-header mb-2">Filters</h2>
      <v-card elevation="1">
        <filter-menu v-model="filters"/>
      </v-card>
    </v-col>
    <v-col
        cols="12"
        :md="!disableFilters ? 10 : 12"
        class="d-flex flex-column"
    >
      <div class="d-flex flex-row align-center">
        <div v-if="!disableFilters" class="flex-wrap">
          <v-btn @click="getItems" icon>
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
        <h2 v-if="!disableFilters" class="card-header mb-2 flex-fill">Results</h2>
        <div class="flex-wrap">
          <slot name="header"></slot>
          <div v-if="enableExports && (isAdmin || clientAdmin)">
            <csv-export-button :namespace="namespace" :filters="filters" :item-count="totalItems"></csv-export-button>
          </div>
        </div>
      </div>
      <v-card
          :style="cardHeight != null ? 'max-height:'+cardHeight+'px; overflow:auto;' : ''"
          class="flex-fill"
          :color="hideCard ? '#00000000' : 'white'"
          :loading="loading"
          :elevation="hideCard ? 0 : 1">
        <slot v-if="totalItems > 0" v-bind:items="items">
        </slot>
        <div v-if="totalItems === 0 && !loading && !loadingError.global" class="">
          <slot name="empty">
            <div class="pa-5 text-center">
              {{ noResults }}
            </div>
          </slot>
        </div>
        <global-error-inline errors="loadingError"></global-error-inline>
      </v-card>
      <div class="mt-2 d-flex justify-space-between align-center" style="height:45px;">
        <div v-if="totalItems > 0" class="pa-2">
          Displaying results {{ (currentPage - 1) * perPage + 1 }} - {{
            (currentPage - 1) * perPage +
            items.length
          }} of {{ totalItems }}
        </div>
        <v-pagination
            v-if="totalPages > 1 || currentPage > 1"
            v-model="currentPage"
            total-visible="6"
            :length="totalPages"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
        ></v-pagination>

      </div>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import FilterField from "./FilterField";
import _ from 'lodash';
import FilterMenu from "./FilterMenu";
import GlobalErrorInline from "./GlobalErrorInline";
import paginationMixin from "../mixins/pagination.mixin";
import CsvExportButton from "@/components/CsvExportButton";

export default {
  name: "PaginatedResultLoader",
  components: {CsvExportButton, GlobalErrorInline, FilterMenu, FilterField},
  props: {
    enableExports: false,
    disableFilters: false,
    hideCard: false,
    namespace: null,
    noResults: {
      type: String,
      default() {
        return "Sorry, no results for your filter"
      }
    },
    cardHeight: null
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'isAdmin']),
  },
  mixins: [paginationMixin()]
}
</script>

<style lang="scss">
.card-header {
  color: var(--v-text-base);
}

.v-list-item__icon {
  margin-right: 10px !important;
}
</style>
