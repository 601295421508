<template>
  <div>
    <filter-field v-for="(filter,index) in filters"
                  :key="filter.id" v-model="filters[index]"/>
  </div>
</template>

<script>
import FilterField from "./FilterField";
import _ from "lodash";

export default {
  name: "FilterMenu",
  components: {FilterField},
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      filters: this.value
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        var oldFilters = oldVal != null ? _.concat(oldVal.map(f => f.id+_.concat(f.options?.map(fo => fo.value+fo.isSelected)))) : null;
        var newFilters = newVal != null ? _.concat(newVal.map(f => f.id+_.concat(f.options?.map(fo => fo.value+fo.isSelected)))) : null;
        console.log("Filters updated VALUE", oldFilters);
        console.log("Filters updated VALUE", newFilters);
        if (!_.isEqual(newFilters, oldFilters)) {
          console.log("Filters CHECK PASSED", newVal);
          this.filters = newVal;
        }
      }
    },
    filters: {
      deep: true,
      handler(newVal, oldVal) {
        console.log("Filters updated", newVal);
        this.$emit('input', newVal);
      }
    }
  },
  computed: {},
  methods: {}

}
</script>
