<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          {{ clientAdmin ? 'Customer/Distributor' : (distributor ? 'Customer' : 'Distributor') }}
        </th>
        <th v-if="full">
          Asset
        </th>

        <th>Task</th>
        <th>Result</th>
        <th>

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          class="cursor"
          v-for="item in items"
          :key="item.id"
          @click="() => { $router.push({name : 'AssetDetail',params: { assetId : item.asset.id }}) }"
      >
        <td v-if="clientAdmin">
          <template v-if="item.asset.distributor"><strong >Distributor:</strong> {{ item.asset.distributor.name }}<br/></template>
          <template v-if="item.asset.customer"><strong>Customer:</strong> {{ item.asset.customer.name }}</template>
        </td>
        <td v-else>
          {{ distributor ? item.asset.customer.name : (item.asset.distributor != null ? item.asset.distributor.name : "") }}
        </td>
        <td>
          <template v-if="item.asset.assetType">
            <div class="d-flex flex-row">
              <div class="flex-fill">
                <div class="font-weight-bold">{{ item.asset.assetType.name }}</div>
              </div>
              <div class="flex-wrap d-flex flex-column justify-center ml-2">
                <asset-type-dropdown :id="item.asset.assetTypeId"/>
              </div>
            </div>
          </template>
          <div v-if="item.asset.name || !item.asset.organisationZone">
            <strong>Ref : {{ !item.asset.organisationZone ? "Uninstalled" : item.asset.name }}</strong>
          </div>

        </td>

        <td>
          {{ item.check ? item.check.name : "" }}
        </td>
        <td class="py-3">
          <check-status :status="item.status"/>
          <div>{{ convertDate(item.createdAt) }} <strong v-if="item.user?.fullName">by {{ item.user.fullName }}</strong>
          </div>
        </td>


        <td class="text-right ">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import SiteDropdown from "../sites/SiteDropdown";
import AssetTypeDropdown from "@/components/asset-types/AssetTypeDropdown";
import CheckStatus from "@/components/checks/CheckStatus";

export default {
  name: 'CompletedTaskList',
  components: {
    CheckStatus,
    AssetTypeDropdown, SiteDropdown, AssetTypeDropdown, HoseConfigurationView, ActionDropdown
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      hoseConfigOpen: false,
      badgeClass: 'd-inline-block rounded mt-1 py-1 px-2 font-weight-bold text-sm-left'
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),

  },
  methods: {
    convertDate(date) {
      return new moment(date).format("DD/MM/yyyy");
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-gesture',
          route: {name: 'AssetDetail', params: {assetId: item.asset.id}}
        }
      ];
      return data;
    }
  }
}
</script>
