<template>
    <div>
        <label>{{ label }}</label>
        <div class="val">{{ value }}</div>
    </div>
</template>
<script>
    export default {
        name: 'LabelValueVertical',
        props: {
            label : null,
            value : null
        }
    }
</script>
<style scoped lang="scss">
    label {
        color: var(--v-textSubtle-base);
        font-weight: 700;
        font-size: 0.875rem;
    }

    .col {
        padding-bottom: 0;
        padding-top: 0;
    }
    .val {
        font-size: 0.75rem;
    }
</style>
