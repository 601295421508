export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
export const getFileType = function(file) {
    switch (file.type) {
        case "image/jpg":
        case "image/jpeg":
        case "image/png":
            return 1;
        case "application/pdf":
            return 2;
        case "application/msword":
            return 3;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return 4;
        case "application/vnd.ms-excel":
            return 5;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return 6;
    }
    return null;
};
