<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :on-save-listeners="['manufacturerUpdated']"
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :id="manufacturerId"
      :initial-item="formType === 'edit' ? {id : manufacturerId} : {}"
      namespace="manufacturers">
    <template #default="{item,errors,onUpdate}">
      <v-text-field
          label="Manufacturer name"
          v-model="item.name"
          @input="onUpdate(item)"
          :error="errors.name != null"
          :error-messages="errors.name"
      />
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import CrudPage from "../../../components/CrudPage";

export default {
  name: "ManufacturerAdd",
  components: {CrudPage, ApiSelectBox, FormLoader},
  props: {
    manufacturerId: null,
  },
  data() {
    return {
      currentItem: {
        type: Object,
        default: {},
      }
    }
  },
  methods : {
  },
  computed: {
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'organisation', 'optionalFields']),
    formType() {
      if (this.$router.currentRoute.name === "ManufacturerDelete") return 'delete';
      if (this.$router.currentRoute.name === "ManufacturerView") return 'view';
      return this.manufacturerId != null ? "edit" : "add";
    },
    formTitle() {
      if (this.formType === "delete") {
        return "Delete manufacturer";
      } else if (this.formType === "view") {
        return "View manufacturer";
      } else if (this.formType === "add") {
        return "Create manufacturer";
      } else if (this.formType === "edit") {
        return "Edit manufacturer";
      }
    }
  }
}
</script>

<style scoped>

</style>
