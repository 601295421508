<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

  <crud-page icon="mdi-clipboard-list-outline" :title="formTitle">
    <div class="px-4">
      <form-loader
          :form-type="formType"
          :title="null"
          :id="clientId"
          namespace="clientsEdit">

        <template #default="{item,errors,onUpdate}">
          <v-card class="pa-5 mb-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Client Name"
                    v-model="item.name"
                    @input="onUpdate(item)"
                    :error="errors.name != null"
                    :error-messages="errors.name"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    label="Primary URL"
                    v-model="item.primaryUrl"
                    @input="onUpdate(item)"
                    :error="errors.primaryUrl != null"
                    :error-messages="errors.primaryUrl"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-combobox
                    :multiple="true"
                    :chips="true"
                    label="Hostnames"
                    v-model="item.hostnames"
                    @input="onUpdate(item)"
                    :error="errors.hostnames != null"
                    :error-messages="errors.hostnames"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                    :items="Object.keys($vuetify.theme.defaults).filter(key => key !== 'dark' && key !== 'light' && key !== 'defaultTheme')"
                    label="Theme Name"
                    v-model="item.theme"
                    @input="onUpdate(item)"
                    :error="errors.theme != null"
                    :error-messages="errors.theme"
                />
              </v-col>



            </v-row>
          </v-card>
          <v-card class="pa-5 mb-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Contact name"
                    v-model="item.contactName"
                    @input="onUpdate(item)"
                    :error="errors.contactName != null"
                    :error-messages="errors.contactName"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Contact email"
                    v-model="item.contactEmail"
                    @input="onUpdate(item)"
                    :error="errors.contactEmail != null"
                    :error-messages="errors.contactEmail"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Contact phone"
                    v-model="item.contactPhone"
                    @input="onUpdate(item)"
                    :error="errors.contactPhone != null"
                    :error-messages="errors.contactPhone"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    label="Website"
                    v-model="item.website"
                    @input="onUpdate(item)"
                    :error="errors.website != null"
                    :error-messages="errors.website"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-5 mb-4">
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                    label="Meta Title"
                    v-model="item.metaTitle"
                    @input="onUpdate(item)"
                    :error="errors.metaTitle != null"
                    :error-messages="errors.metaTitle"
                />
              </v-col>
              <v-col cols="12" md="6">

                <v-textarea
                    label="Meta Description"
                    v-model="item.metaDescription"
                    @input="onUpdate(item)"
                    :error="errors.metaDescription != null"
                    :error-messages="errors.metaDescription"
                />
              </v-col>
            </v-row>
          </v-card>

          <v-card class="pa-5">
            <v-row>
              <v-col cols="12" md="6">
                <div class="mb-2">Terms and conditions:</div>
                <tiptap-vuetify
                    v-model="item.termsAndConditions"
                    :extensions="extensions"
                />
              </v-col>
              <v-col cols="12" md="6">
                <div class="mb-2">Privacy Policy:</div>
                <tiptap-vuetify
                    v-model="item.privacyPolicy"
                    :extensions="extensions"
                />
              </v-col>
            </v-row>
          </v-card>

        </template>
      </form-loader>
    </div>
  </crud-page>
</template>
<style>
.tiptap-vuetify-editor__content {
  max-height: 350px;
}
</style>
<script>

import CrudPage from "../../../components/CrudPage";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import FormLoader from "@/components/FormLoader.vue";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import ClientList from "@/components/clients/ClientList.vue";

export default {
  name: 'ClientEdit',
  props: {
    clientId: null
  },
  data: () => ({
    // declare extensions you want to use
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    // starting editor's content
  })
  ,
  computed: {
    formType() {
      return this.clientId == null ? 'add' : (this.$route.name === 'ClientDelete' ? 'delete' : 'edit');
    },
    formTitle() {
      return this.clientId == null ? 'Create Client' : (this.$route.name === 'ClientDelete' ? 'Delete client' : 'Edit Client');
    }
  },
  components: {
    TiptapVuetify,
    FormLoader,
    ClientList,
    PaginatedResultLoader,
    CrudPage
  },
}
</script>
