<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-menu
            :offset-y="true"
            :close-on-content-click="true"
    >
        <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            color="light"
                            elevation="0"
                            small
                            v-bind="attrs"
                            tooltip="Quick view"
                            v-on="{ ...tooltip, ...menu }"
                    >
                        <v-icon color="primary" size="18" class="mr-1">mdi-map-legend</v-icon>
                        <span v-if="title">{{ title }}</span>
                        <v-icon class="ml-1" size="13">mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <span>View full location trail</span>
            </v-tooltip>
        </template>
        <v-card class="pa-3">
            <site-view :id="id" ></site-view>
        </v-card>
    </v-menu>
</template>

<script>

    import SiteView from "./SiteView";
    export default {
        name: "SiteDropdown",
        components: {SiteView},
        props: {
            title: null,
            subtitle: null,
            id: null
        }
    }
</script>

<style scoped>

</style>
