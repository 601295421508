<template>
  <v-btn v-if="itemCount > 0" small depressed color="info" :disable="loading" :loading="loading" @click="exportData">
    <template v-if="!loading">
      Export {{ this.itemCount }} items
    </template>
  </v-btn>
</template>

<script>
import * as _ from "lodash";
import {endpoints, httpRequest} from "@/store/network/amtag.client";

export default {
  name: "CsvExportButton",
  data() {
    return {
      errors: {},
      loading: false,
    }
  },
  props: {
    namespace: null,
    itemCount: 0,
    filters: {
      type: Array,
      default() {
        return []
      }
    },
  },
  methods: {
    async exportData() {
      this.errors = {}
      this.loading = true;
      try {
        const data = {};
        const filtersFinal = {};
        _.forEach(this.filters, function (filter) {
          if (filter.type === 0) {
            if (filter.textValue != null && !_.isEmpty(filter.textValue))
              filtersFinal[filter.id] = filter.textValue;
          } else if (filter.type === 1) {
            let selected = _.find(filter.options, (option) => option.isSelected);
            if (selected != null)
              filtersFinal[filter.id] = selected.value;
          } else if(filter.type === 2) {
            let selected = filter.options.filter((option) => option.isSelected).map((option) => option.value);
            if(selected.length > 0) {
              filtersFinal[filter.id] = selected;
            }
          }
        });
        data['filters'] = filtersFinal;
        console.log("ENDPOINT IS",endpoints[this.namespace]);
        let res = await httpRequest(endpoints[this.namespace].exportItems, data);
        console.log("RES IS ",res);
        window.open(res.data.url, '_blank');
      } catch (e) {
        console.log("error", e);
        //console.log("error data", e.response.data);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
