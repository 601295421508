import {baseActions, baseGetters, baseMutations, baseState} from "./baseStore";

function state(){
    return {
        ...baseState(),
    }
}

function getters(){
    return {
        ...baseGetters()
    }
}

function mutations(){
    return {
        ...baseMutations()
    }
}

function actions(){
    return {
        ...baseActions('uploads')
    }
}

function uploadsModule()
{
    return {
        namespaced: true,
        state: state(),
        getters: getters(),
        mutations: mutations(),
        actions: actions()
    }
}
export default uploadsModule;
