<template>
  <router-view :class="'theme-amtag'" />
<!--  <div v-else-if="clientLoading">-->
<!--    <v-progress-circular-->
<!--        indeterminate-->
<!--        v-if="clientLoading"-->
<!--        color="primary"/>-->
<!--  </div>-->
<!--  <div v-else-if="clientError">-->
<!--    <all-error-inline :message="clientError" />-->
<!--  </div>-->
</template>
<style>
@font-face {
  font-family: "Carlito";
  src: url(./fonts/carlito.bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Carlito";
  src: url(./fonts/carlito.bold-italic.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Carlito";
  src: url(./fonts/carlito.regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Carlito";
  src: url(./fonts/carlito.italic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import AlertDisplay from "./components/AlertDisplay";
import AllErrorInline from "@/components/AllErrorInline.vue";

export default {
  name: 'App',
  head() {
    var theme = 'amtag';
    return {
      title: 'AmTAG - Asset Management',
      link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/'+theme+'/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/'+theme+'/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/'+theme+'/favicon-16x16.png' },
        { rel: 'manifest', href: '/'+theme+'/site.webmanifest' },
        { rel: 'mask-icon', href: '/'+theme+'/safari-pinned-tab.svg', color: '#111111' },
        { rel: 'shortcut icon', href: '/'+theme+'/favicon.ico' }
      ],
      meta: [
        { name: 'msapplication-TileColor', content: '#ffffff' },
        { name: 'theme-color', content: '#ffffff' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' }
      ]
    }
  },

  components: {AllErrorInline, AlertDisplay},
  data: () => ({}),
  computed: {
    ...mapGetters('clients',['item','clientLoading','clientError']),
    ...mapGetters('auth', ['authenticated', 'clientAdmin', 'distributor']),
  },
  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        console.log(this.$vuetify.theme);
        if (newVal && newVal.id) {
          this.$vuetify.theme.themes.dark = this.$vuetify.theme.defaults[newVal.theme];
          this.$vuetify.theme.themes.light = this.$vuetify.theme.defaults[newVal.theme];
        }
      }
    }
  },
  methods: {
    ...mapActions('auth', ['me']),
    ...mapActions('clients', ['getCurrent'])
  },
  async created() {
    if (this.authenticated) {
      await this.me();
    }
    // await this.getCurrent();
  },
};
</script>
