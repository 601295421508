<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-toolbox" :title="formTitle">
    <form-loader
        class="px-4"
        :form-type="formType"

        :item-transform="itemTransform"
        v-on:itemUpdated="onItemUpdated"
        :before-save="beforeSave"
        :id="assetTypeId"
        :initial-item="clientAdmin ? {defaultChecks : [], fields: [] } : {organisationId : organisation.id, defaultChecks : [] , fields: []}"
        namespace="assetTypes">
      <template #default="{item,errors,onUpdate}">
        <v-alert type="error" class="mb-3" v-if="item.assetCount">
          <div class="flex-fill">Any changes made to this asset will effect all {{ item.assetCount }} assets
            associated
            with it
          </div>
        </v-alert>


        <item-list-loader
            v-if="clientAdmin && formType === 'add'"
            namespace="organisations"
            :custom-filters="{}"
            :limit="1000"
            class="mb-4"
        >
          <template v-slot:main="orgSlot">
            <v-select
                :hide-details="true"
                v-model="item.organisationId"
                :items="orgSlot.items"
                item-text="name"
                item-value="id"
                label="Select organisation"
                hint="Select organisation to add this asset to"
            ></v-select>
          </template>
        </item-list-loader>

        <template v-if="item.organisationId">
          <h3>Basic Info:</h3>
          <v-card class="pa-3 mb-4">
            <v-text-field
                v-if="formType === 'add' || formType === 'edit'"
                label="Asset Name"
                v-model="item.name"
                @input="onUpdate(item)"
                :error="errors.name != null"
                :error-messages="errors.name"
            />

            <div v-else class="d-flex flex-row align-center pa-3 rounded light"
            >
              <div class="mr-2">
                <strong>Asset Name</strong>:
              </div>
              <div class="flex-fill">
                {{ item.name }}
              </div>
            </div>

            <item-list-loader
                v-if="formType === 'add'"
                namespace="sectors"
                class="mb-4"
                :custom-filters="{organisationId : [item.organisationId]}"
                :limit="1000"
            >
              <template #noResults>
                <v-alert type="error">No sectors for this organisation</v-alert>
              </template>
              <template v-slot:main="orgSlot">
                <v-select
                    :hide-details="true"
                    v-model="item.sectorId"
                    :items="orgSlot.items"
                    item-text="name"
                    item-value="id"
                    label="Select sector"
                    hint="Select organisation to add this asset to"
                ></v-select>
              </template>
            </item-list-loader>
          </v-card>
          <template v-if="item.sectorId">

            <v-row v-if="item.sectorId">
              <v-col class="d-flex flex-column" cols="6">
                <h3>Recommended tasks:</h3>
                <v-card class="flex-fill" style="overflow: scroll;" max-height="400">
                  <v-alert v-if="item?.defaultChecks.length === 0" type="info" class="ma-3">
                    No recommended tasks for the asset
                  </v-alert>
                  <template v-for="(checkItem,checkItemKey) in item.defaultChecks">
                    <v-list-item class="py-3" :key="checkItem.id">
                      <v-list-item-title :style="toRemove.indexOf(checkItem.checkId) !== -1 ? 'opacity: 0.3' : ''">
                        <div>
                          <div class="mb-2">{{ checkItem.checkName }}
                            <v-chip color="success" v-if="toAdd.indexOf(checkItem.checkId) > -1" x-small>New</v-chip>
                          </div>
                          <check-timeline-chip :check-timeline="checkItem.check.checkTimeline"/>
                        </div>
                      </v-list-item-title>
                      <v-list-item-action
                          v-if="formType !== 'view' && formType !== 'delete'"
                          class="pr-2">
                        <v-btn color="error" small depressed
                               @click="removeTask(item,checkItem)">
                          {{ toRemove.indexOf(checkItem.checkId) !== -1 ? 'Cancel' : 'Remove' }}
                        </v-btn>
                      </v-list-item-action>

                    </v-list-item>
                    <v-divider v-if="checkItemKey !== item.defaultChecks.length-1"/>
                  </template>
                </v-card>
              </v-col>
              <v-col v-if="formType !== 'view' && formType !== 'delete'" class="d-flex flex-column" cols="6">
                <h3>Add New Recommended Tasks:</h3>
                <v-card class="flex-fill pa-0" style="overflow: scroll;" max-height="400">
                  <item-list-loader
                      namespace="checks"
                      :limit="1000"
                      :custom-filters="{organisationId : currentOrganisationId, excludeAssetTypeId : item.id, sectorId : [item.sectorId]}">
                    <template #noResults>
                      <v-alert class="ma-3" type="info">
                        No tasks available within this sector
                      </v-alert>
                    </template>
                    <template #main="{items}">
                      <v-text-field
                          hide-details
                          placeholder="Search Tasks"
                          clearable
                          prepend-inner-icon="mdi-magnify"
                          solo
                          outlined
                          flat
                          class="ml-3 mr-3 mt-3"
                          v-model="taskSearch"></v-text-field>
                      <v-list class="rounded">
                        <v-alert class="ma-3" type="warning"
                                 v-if="taskSearch != null && items.length > 0 && items.filter(filterItem => (taskSearch == null || filterItem.name.toLowerCase().includes(taskSearch.toLowerCase())) &&  !item.defaultChecks?.some(check => check.checkId === filterItem.id)).length === 0">
                          No tasks found
                        </v-alert>
                        <template
                            v-for="(checkItem,checkItemKey) in items.filter(filterItem => (taskSearch == null || filterItem.name.toLowerCase().includes(taskSearch.toLowerCase())) &&  !item.defaultChecks?.some(check => check.checkId === filterItem.id))">

                          <v-list-item class="py-3" :key="checkItem.id">
                            <v-list-item-title>
                              <div>
                                <div class="mb-2">{{ checkItem.name }}</div>
                                <check-timeline-chip :check-timeline="checkItem.checkTimeline"></check-timeline-chip>
                              </div>
                            </v-list-item-title>

                            <v-list-item-action class="pr-2">
                              <v-btn color="success" small
                                     depressed
                                     @click="addTask(item,checkItem)">Add
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider v-if="checkItemKey !== items.length-1"/>
                        </template>
                      </v-list>
                    </template>
                  </item-list-loader>
                </v-card>
              </v-col>

              <v-col class="d-flex flex-column">
                <h3>Additional Details:</h3>
                <v-card class="flex-fill pa-3">
                  <div :class="item.fields.length > 0 ? 'mb-4' : ''"
                       v-if="formType === 'view' || formType === 'delete'">
                    <div class="d-flex mb-2 flex-row align-center pa-3 rounded light" v-for="field in item.fields"
                         :key="field.ident">
                      <div style="width: 125px;">
                        <strong>{{ field.additionalField }}</strong>:
                      </div>
                      <div class="flex-fill">
                        {{ field.additionalFieldValue }}
                      </div>
                    </div>

                  </div>
                  <div class="mb-4" v-else>
                    <custom-field-creator-input :sector-id="item.sectorId" v-if="item.sectorId" v-model="item.fields"/>
                  </div>

                  <v-alert class="mb-0" style="white-space: pre-wrap;" color="light"
                           v-if="item.notes && (formType === 'view' || formType === 'delete')" title="Notes">
                    <h3>Notes</h3>
                    <div style="overflow-wrap: break-word; word-wrap: break-word; word-break: break-all;">
                      {{ item.notes }}
                    </div>
                  </v-alert>

                  <v-textarea
                      v-else-if="formType !== 'view' && formType !== 'delete'"
                      class="mt-6"
                      outlined
                      label="Notes"
                      v-model="item.notes"
                      @input="onUpdate(item)"
                      :error="errors.notes != null"
                      :error-messages="errors.notes"/>

                </v-card>
              </v-col>

            </v-row>

            <v-row v-if="formType === 'view' && item.audits && item.audits.length > 0">
              <v-col cols="12">
                <h3>Audit log:</h3>
                <v-card>
                  <asset-audit-list :items="item.audits"></asset-audit-list>
                </v-card>
              </v-col>
            </v-row>

            <v-alert class="mt-4" type="error" v-if="formType === 'edit' && item.assetCount > 0">
              Any changes made to this asset will effect all {{ item.assetCount }} assets
              associated
              with it
            </v-alert>

          </template>
        </template>
      </template>
    </form-loader>
  </crud-page>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import CrudPage from "../../../components/CrudPage";
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import CheckTimelineChip from "@/components/CheckTimelineChip";
import TaskSelectionInput from "@/components/tasks/TaskSelectionInput";
import CustomFieldCreatorInput from "@/components/asset-types/CustomFieldCreatorInput";
import {v4 as uuidv4} from 'uuid';
import LabelValueHorizontal from "@/components/LabelValueHorizontal";
import ItemListLoader from "@/components/ItemListLoader.vue";
import AllErrorInline from "@/components/AllErrorInline.vue";
import _ from "lodash";
import AssetAuditList from "@/components/asset-types/AssetAuditList.vue";

export default {
  name: "AssetTypeAdd",
  components: {
    AssetAuditList,
    AllErrorInline,
    LabelValueHorizontal,
    CustomFieldCreatorInput,
    TaskSelectionInput, CheckTimelineChip, PaginatedResultLoader, CrudPage, ApiSelectBox, FormLoader, ItemListLoader
  },
  props: {
    assetTypeId: null,
  },
  data() {
    return {
      currentItem: {},
      selectedTasks: {},
      toRemove: [],
      toAdd: [],
      taskSearch: null,
      currentOrganisationId: null,
    }
  },
  created() {
    if (!this.clientAdmin) {
      this.currentOrganisationId = this.organisation.id;
    }
  },
  methods: {
    addTask(currentItem, task) {
      this.toAdd.push(task.id);
      this.toRemove = this.toRemove.filter(item => item !== task.id);
      currentItem.defaultChecks.push({
        checkId: task.id,
        check: task,
        checkName: task.name,
        checkTimeline: task.checkTimeline
      });
    },
    removeTask(currentItem, task) {
      if (this.toAdd.indexOf(task.checkId) !== -1) {
        this.toAdd = this.toAdd.filter(item => item !== task.checkId);
        currentItem.defaultChecks = currentItem.defaultChecks.filter(item => item.checkId !== task.checkId);
      } else {
        if (this.toRemove.indexOf(task.checkId) === -1) {
          this.toRemove.push(task.checkId);
        } else {
          this.toRemove = this.toRemove.filter(item => item !== task.checkId);
        }
      }
    },
    beforeSave(item) {
      var finalFields = [];
      _.forEach(item.fields, (field) => {
        if (field.additionalField && field.additionalFieldValue) {
          finalFields.push({
            additionalField: field.additionalField,
            additionalFieldValue: field.additionalFieldValue,
          })
        }
      });
      item.additionalFields = finalFields;
      item.defaultCheckIds = item.defaultChecks.map(item => item.checkId).filter(item => this.toRemove.indexOf(item) === -1);
      return item;
    },
    itemTransform(item) {
      var finalFields = [];
      _.forEach(item.additionalFields, (additionalField) => {
        let fieldValue = item.additionalFieldValues.filter(fv => fv.additionalFieldId === additionalField.id)[0];
        if (fieldValue) {
          finalFields.push({
            ident: uuidv4(),
            additionalField: additionalField.name,
            additionalFieldValue: fieldValue.fieldValue,
          })
        }
      })
      item.fields = finalFields;
      console.log("FINAL ITEM IS ", item);
      return item;
    },
    onItemUpdated(item) {
      this.currentItem = item;
    },
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'organisation', 'optionalFields']),
    formType() {
      if (this.$router.currentRoute.name === "AssetTypeDelete") return 'delete';
      if (this.$router.currentRoute.name === "AssetTypeView") return 'view';
      return this.assetTypeId != null ? "edit" : "add";
    },
    formTitle() {
      if (this.formType === "delete") {
        return "Delete Asset";
      } else if (this.formType === "view") {
        return "View Asset";
      } else if (this.formType === "add") {
        return "Create Asset";
      } else if (this.formType === "edit") {
        return "Edit Asset";
      }
    }
  }
}
</script>

<style>
.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
