<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th >
          Date
        </th>
        <th >
          Note
        </th>

        <th>
          User
        </th>
        <th>
          Images
        </th>

      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td  class="py-1">
          {{ $options.filters.formatDateTime(item.createdAt) }}
        </td>
        <td  class="py-1">
          {{ item.notes }}
        </td>
        <td>
          {{ item.user != null ? item.user.fullName : "" }}
        </td>
        <td>
          <div class="my-2" v-if="item.uploads.length > 0">
            <a @click="selectedUpload = upload" class="rounded pa-2 d-inline-block mr-2 mb-2 light"
               v-for="(upload,uploadKey) in item.uploads"
               v-bind:key="upload.id">
              <upload-image-loader :upload="upload"/>
            </a>
            <v-dialog
                width="1200"
                v-model="dialogOpen"
            >
              <div class="white rounded">
                <upload-image-loader :upload="selectedUpload" :size="1" :width="1200" :thumbnail="false"/>
                <div class="mt-2" v-if="item.uploads.length > 0">
                  <a @click="selectedUpload = upload" class="rounded pa-2 d-inline-block mr-2 mb-2 light"
                     v-for="(upload,uploadKey) in item.uploads"
                     v-bind:key="upload.id">
                    <upload-image-loader :upload="upload"/>
                  </a>
                </div>
              </div>
            </v-dialog>
          </div>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import AssetTypeDropdown from "../asset-types/AssetTypeDropdown";
import SiteDropdown from "../sites/SiteDropdown";
import CheckStatus from "@/components/checks/CheckStatus";
import UploadImageLoader from "@/components/UploadImageLoader.vue";

export default {
  name: 'AssetMetaList',
  components: {
    UploadImageLoader,
    CheckStatus, SiteDropdown, AssetTypeDropdown, HoseConfigurationView, ActionDropdown},
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      selectedUpload: null
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),
    dialogOpen: {
      get() {
        return this.selectedUpload != null
      },
      set(value) {
        if (!value) {
          this.selectedUpload = null;
        }
      }
    }
  },
  methods: {
    lastInspection(item) {
      return `<div>` + (item.lastInspectionDate ? "Inspected by " + item.lastInspectionName + " on " + moment(item.lastInspectionDate).format("DD/MM/yyyy") : "Never inspected") + `<div>`
    },
    nextInspection(item) {
      if (item.nextInspectionDate) {
        var divClass = "";
        var divText = "";
        var date = moment(item.nextInspectionDate);
        var currentDate = moment().utc();
        var soonDate = currentDate.subtract(1, "month");
        if (currentDate.isSameOrAfter(date)) {
          divClass = "error white--text";
          divText = "Inspection was due on " + date.format("DD/MM/yyyy");
        } else if (currentDate.isBefore(soonDate) && currentDate.isSameOrAfter(date)) {
          divClass = "warning white--text";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        } else {
          divClass = "light";
          divText = "Inspection due on " + date.format("DD/MM/yyyy");
        }
        return `<span class="` + this.badgeClass + ` ` + divClass + `">` + divText + `</span>`
      }
      return "";
    }
  }
}
</script>
