<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table fixed-header>
    <template>
      <thead>
      <tr>
        <th >
          Date
        </th>
        <th >
          Action
        </th>

        <th>
          User
        </th>

      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td  class="py-1">
          {{ $options.filters.formatDateTime(item.createdAt) }}
        </td>
        <td  class="py-1">
          {{ item.notes }}
        </td>
        <td>
          {{ item.user != null ? item.user.fullName : "" }}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import moment from "moment";
import HoseConfigurationView from "../asset-types/AssetTypeView";
import AssetTypeDropdown from "../asset-types/AssetTypeDropdown";
import SiteDropdown from "../sites/SiteDropdown";
import CheckStatus from "@/components/checks/CheckStatus";
import UploadImageLoader from "@/components/UploadImageLoader.vue";

export default {
  name: 'AssetAuditList',
  components: {},
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      selectedUpload: null
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor'])
  },
  methods: {

  }
}
</script>
