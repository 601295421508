<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

  <v-menu v-if="authenticated">
    <template v-slot:activator="{ on, attrs }">
      <v-list>
        <v-list-item class="ma-0 pl-2" link v-on="on">
          <v-list-item-avatar class="primary onPrimary--text font-weight-bold">
            {{ user.firstName.charAt(0) + user.lastName.charAt(0) }}
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user.firstName + ' ' + user.lastName }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-bold " v-if="organisation != null && organisation.name != null">
              {{ organisation.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="organisation != null">
              {{ organisation.isDistributor ? 'Distributor account' : 'Customer account' }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-bold " v-if="systemAdmin">System Admin</v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-bold " v-else-if="clientAdmin">Client Admin</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-menu-down</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <v-list
        nav
        dense
    >
      <v-list-item-group
          active-class="active"
      >
        <v-list-item
            exact
            v-for="(item, i) in menuItems"
            :key="i"
            @click="menuClick(item)"
        >
          <v-list-item-icon class="mr-2 ">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">

</style>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: "UserSideMenu",
  data: () => ({}),
  computed: {
    ...mapGetters('auth', ['authenticated', 'user', 'multiOrg', 'clientAdmin', 'organisation', 'isUserAdmin', 'isAdmin','systemAdmin']),
    menuItems: function () {
      console.log(this.user);
      let items = [
        {icon: 'mdi-account-cog-outline', text: 'Your Profile', route: 'YourProfile'},
      ];
      if (this.organisation != null && this.isAdmin) {
        items.push({icon: 'mdi-tune', text: this.organisation.name + ' Settings', route: 'OrganisationSettings'})
      }
      if (this.clientAdmin) {
        items.push({icon: 'mdi-database-cog', text:'Client Settings', route: 'ClientSettings'})
      }
      if (this.multiOrg) {
        items.push({icon: 'mdi-arrow-decision', text: 'Switch Organisations', route: 'OrganisationSelect'});
      } else {
        items.push({icon: 'mdi-arrow-decision', text: 'Create new Organisation', route: 'OrganisationCreate'});
      }
      items.push({icon: 'mdi-logout', text: 'Logout', action: 'logout'});

      return items;
    },
  },
  watch: {
    authenticated(newVal, oldVal) {
      if (!newVal) {
        this.$router.replace({name: 'Home'}).catch(() => {
        });
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout']),
    menuClick: function (item) {
      if (item.route) {
        this.$router.push({name: item.route}).catch(() => {
        });
      } else if (item.action) {
        this[item.action]();
      }
    }
  }
}
</script>

<style scoped>

</style>
