<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-tag-multiple" :actions="actions" :breadcrumbs="breadcrumbs" :title="title">

    <v-alert type="error" v-if="item.deletedAt != null">
      This asset has been archived
    </v-alert>

    <v-row v-if="item.id">
      <v-col cols="4">
        <template v-if="item.organisationZoneId">
          <div class="mb-3">
            <h2 class="card-header mb-1">
              Asset Location
            </h2>
            <v-card class="pa-3">
              <site-view :id="item.organisationZoneId"></site-view>
            </v-card>
          </div>
        </template>
        <h2 class="card-header mb-1">
          Asset Details
        </h2>
        <v-card class="pa-3">
          <asset-type-view :refresh-token="refreshToken" :asset-id="assetId"></asset-type-view>
        </v-card>

        <h2 class="card-header mb-1 mt-4">
          Asset Documents
        </h2>
        <v-dialog
            persistent
            max-width="500"
            v-model="deleteDocumentDialog">
          <v-card :loading="deleteLoading" v-if="selectedDocument">
            <v-card-title class="text-h5">
              Are you sure?
            </v-card-title>
            <v-card-text>
              <div>This action is permanent.</div>
              <global-error-inline :errors="deleteError"></global-error-inline>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  :loading="deleteLoading"
                  @click="deleteDocumentDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="error"
                  text
                  :loading="deleteLoading"
                  @click="confirmDeleteUpload"
              >
                Delete {{ selectedDocument.filename }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-card class="pa-3">
          <v-alert color="light" v-if="!item.uploads || item.uploads.length === 0">
            No documents uploaded to this asset
          </v-alert>
          <div class="light-border pa-3 mb-2 rounded align-center justify-space-between d-flex"
               v-for="upload in item.uploads">
            <v-icon color="info flex-wrap">{{ $options.filters.fileTypeToIcon(upload.mimetype) }}</v-icon>
            <div class="flex-fill ml-2">
              <div><strong>{{ upload.filename }}</strong></div>
              <div><small>{{ "Uploaded on " + $options.filters.formatDate(upload.createdAt) }}</small></div>
            </div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap mr-3 error" @click="deleteUpload(upload)" icon>
                  <v-icon color="white">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete document</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" class="flex-wrap primary" v-if="upload.signedLink"
                       :href="upload.signedLink" target="_blank" icon>
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download document</span>
            </v-tooltip>


          </div>
          <v-file-input
              v-model="uploadFile"
              :loading="uploadLoading"
              accept="image/png,image/jpg,image/jpeg,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel"
              label="Select a document to upload"
          ></v-file-input>
          <div class="font-weight-bold">
            <v-btn depressed small :loading="uploadLoading" color="success" :disabled="!uploadFile"
                   @click="processUpload">Upload file
            </v-btn>
            <small class="ml-2">Images, PDFs, Word and Excel files are supported</small>
          </div>
        </v-card>

      </v-col>
      <v-col cols="8">
        <h2 class="card-header mb-1">
          Asset Tasks
        </h2>
        <item-list-loader
            class="mb-4"
            :limit="1000"
            :custom-filters="{assetId : assetId}"
            namespace="checksDue">
          <template v-slot:main="props">
            <v-card>
              <check-detail-list :full="true" :items="props.items"/>
            </v-card>
          </template>
        </item-list-loader>
        <h2 class="card-header mb-1">
          Asset History
        </h2>

        <paginated-result-loader
            :disable-filters="true"
            :hide-card="false"
            :listeners="['assetUpdated']"
            :custom-filters="{assetId:assetId}"
            namespace="assetMeta"
        >
          <template #default="props">
            <v-card>
              <asset-meta-list :items="props.items"/>
            </v-card>
          </template>
        </paginated-result-loader>
      </v-col>
    </v-row>



  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import AssetConfigurationView from "../../../components/asset-types/AssetTypeView";
import AssetTimelineItem from "./AssetTimelineItem";
import * as _ from "lodash";
import SiteView from "../../../components/sites/SiteView";
import AssetTypeView from "@/components/asset-types/AssetTypeView";
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import CheckList from "@/components/checks/CheckList";
import CheckDetailList from "@/components/checks/CheckDetailList";
import {FileUploadService} from "v-file-upload";
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import {getFileType, toBase64} from "@/helpers";
import router from "@/router";
import AssetMetaList from "@/components/assets/AssetMetaList.vue";
import ItemListLoader from "@/components/ItemListLoader.vue";
import GlobalErrorInline from "@/components/GlobalErrorInline.vue";
import emitter from "@/utils/emitter";

export default {
  name: 'AssetDetail',
  props: {
    assetId: null
  },
  data() {
    return {
      refreshToken: 0,
      uploadFile: null,
      uploadLoading: false,
      uploadErrors: {},
      deleteDocumentDialog: false,
      selectedDocument: null
    }
  },
  components: {
    GlobalErrorInline,
    ItemListLoader,
    AssetMetaList,
    CheckDetailList,
    CheckList,
    PaginatedResultLoader,
    AssetTypeView,
    SiteView,
    AssetTimelineItem,
    AssetConfigurationView,
    CrudPage
  },
  created() {
    if (this.assetId != null) {
      console.log("GETTING ITEM ", this.assetId);
      this.getItem(this.assetId);
    }
  },
  watch: {
    item(newVal, oldVal) {
      this.refreshToken = Math.random();
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor', 'isUserAdmin']),
    ...mapGetters('assets', ['item', 'formLoading', 'formError']),
    ...mapGetters('uploads', {
      'deleteLoading': 'saveLoading',
      'deleteError': 'saveError'
    }),
    actions() {
      var list = [];
      if (this.item.deletedAt == null && !this.clientAdmin) {
        if (this.isUserAdmin) {
          list.push({
            icon: 'mdi-trash-can',
            title: 'Archive',
            route: {name: 'AssetDelete'},
            variant: 'error'
          });
        }
        list.push({
          icon: 'mdi-pencil',
          title: 'Edit',
          route: {name: 'AssetEdit'},
          variant: 'accent'
        });
      }
      list.push({
        icon: 'mdi-note-plus',
        title: 'Add Note',
        route: {name: 'AssetNote'},
        variant: 'success'
      });
      return list;
    },
    deletedText() {
      return "Archived by " + (this.item.deletedUser?.fullName ?? "Unknown user");
    },
    loadingError() {
      return Object.keys(this.formError).length > 0;
    },
    title() {
      if (this.formLoading) {
        return "Loading";
      } else if (this.loadingError) {
        return "Unable to load";
      } else {
        if (this.item.name) {
          return this.item.name;
        } else if (this.item.organisationZone) {
          return this.item.organisationZone.name;
        } else if (this.item.id) {
          return "#" + this.item.id;
        }
      }
    },
    breadcrumbs() {
      return [
        {
          to: {name: 'Assets'},
          label: "Assets"
        }
      ]
    },
  },
  methods: {
    ...mapActions('assets', ['getItem']),
    ...mapActions('uploads', ['deleteItem']),
    async deleteUpload(upload) {
      this.selectedDocument = upload;
      this.deleteDocumentDialog = true;
    },
    async confirmDeleteUpload() {
      await this.deleteItem(this.selectedDocument.id);
      emitter.emit('assetUpdated', {});
      this.deleteDocumentDialog = false;
      await this.getItem(this.assetId);

    },
    async processUpload() {
      var upload = {
        base64Data: await toBase64(this.uploadFile),
        uploadType: getFileType(this.uploadFile),
        assetId: this.assetId,
        filename: this.uploadFile.name,
        organisationId: this.item.distributorOrganisationId ?? this.item.customerOrganisationId
      };
      this.uploadLoading = true;
      this.uploadErrors = {};

      try {
        let res = await httpRequest(endpoints.uploads.createItem, upload);
        await this.$store.dispatch('alerts/setUserMessage', 'File uploaded');
        await this.getItem(this.assetId);
        this.uploadFile = null;
        console.log("upload success", res);
      } catch (e) {
        console.log("error", e);
        console.log("error data", e.response.data);
        this.uploadErrors = e.response.data;
      } finally {
        this.uploadLoading = false;
      }
    },
  }
}
</script>
