<template>
  <v-simple-table>
    <template>
      <thead>
      <tr>
        <th>
          Details
        </th>
        <th class="text-left">
          Host names
        </th>
        <th class="text-left">
          Actions
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="(item,itemKey) in items"
          :key="'clientRow'+itemKey"
      >

        <td>
          {{ item.name }} <v-chip v-if="currentClient.id === item.id" color="primary" class="ml-2" small>Current client</v-chip>
        </td>

        <td class="py-2">
          {{ item.hostnames.join(', ') }}
        </td>
        <td class="py-2">
          <action-dropdown :items="getDropdown(item)"/>
        </td>

      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import SiteDropdown from "../sites/SiteDropdown";
import AssetTypeDropdown from "@/components/asset-types/AssetTypeDropdown.vue";

export default {
  name: 'ClientList',
  components: {SiteDropdown, AssetTypeDropdown, ActionDropdown},
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor', 'systemAdmin']),
    currentClient() {
      return this.$store.getters['clients/item'];
    }
  },
  methods: {
    getDropdown(item) {
      return [
        {
          label: 'Edit',
          icon: 'mdi-pencil',
          route: {name: 'ClientEdit', params: {clientId: item.id}}
        },
        {
          label: 'Manage Client Admins',
          icon: 'mdi-account-group-outline',
          route: {name: 'ClientUsers', params: {clientId: item.id}}
        },
        {
          label: 'Delete',
          icon: 'mdi-delete-outline',
          route: {name: 'ClientDelete', params: {clientId: item.id}}
        }
      ]
    }
  }
}
</script>
