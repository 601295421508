<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-checkbox-multiple-outline" :actions="actions" title="Task library">
    <paginated-result-loader
        :enable-exports="true"
        :custom-filters="{organisationId: currentOrganisationId}"
        namespace="checks">
      <template v-slot:default="props">
        <task-list :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import AssetTypeList from "@/components/asset-types/AssetTypeList";
import TaskList from "@/components/tasks/TaskList";
import emitter from "@/utils/emitter";

export default {
  name: 'Tasks',
  components: {
    TaskList,
    AssetTypeList,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  created() {
    emitter.on('checksUpdated',this.getItems);
  },
  beforeDestroy() {
    emitter.off('checksUpdated',this.getItems);
  },
  methods: {
    getItems() {
      this.$store.dispatch('checks/getItems', {customFilters : {'organisationId': this.currentOrganisationId}});
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'currentOrganisationId', 'isAdmin']),
    actions() {
      var list = [];
      if (this.isAdmin) {
        list.push({
          icon: 'mdi-plus-circle-outline',
          title: 'Import tasks',
          route: {name: 'TasksImport'},
          variant: 'info'
        });
      }
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Create task',
        route: {name: 'TaskAdd'},
        variant: 'success'
      });

      return list;
    }
  }
}
</script>
