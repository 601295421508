<template>
  <v-chip small v-if="outputExplanation">
    <v-icon size="16" class="mr-1">mdi-clock</v-icon>
    {{ this.outputExplanation }}
  </v-chip>
</template>

<script>
import {periodToText} from "@/helpers/helperFunctions";

export default {
  name: "CheckTimelineChip",
  props: {
    checkTimeline: {
      type: Object,
      default() {
        return {}
      }
    },
    short : {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    outputExplanation() {
      if (this.checkTimeline.periodValue != null && this.checkTimeline.period != null) {
        return (this.short ? "" : "This task will become due") + " every " + (this.checkTimeline.periodValue > 1 ? this.checkTimeline.periodValue + " " + periodToText(this.checkTimeline.period, true) : periodToText(this.checkTimeline.period, false))
      }
    }
  },
}
</script>

<style scoped>

</style>
