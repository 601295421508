import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            defaultTheme: 'amtag',
            amtag: {
                primary: '#F3C300',
                secondary: '#b0bec5',
                info: '#314dba',
                accent: '#314dba',
                error: '#b71c1c',
                warning: '#E8B618',
                success: '#5DB716',
                white: '#ffffff',
                text: '#111111',
                onPrimary: '#111111',
                textSubtle: '#938788',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            pirtek: {
                primary: '#012169',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning: '#E8B618',
                success: '#5DB716',
                white: '#ffffff',
                text: '#333333',
                textSubtle: '#938788',
                onPrimary: '#FFFFFF',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            hydraquip: {
                primary: '#f90404',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning: '#E8B618',
                success: '#5DB716',
                white: '#ffffff',
                text: '#333333',
                textSubtle: '#938788',
                onPrimary: '#FFFFFF',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            flowtech: {
                primary: '#027dc5',
                secondary: '#b0bec5',
                info: '#314dba',
                error: '#b71c1c',
                warning: '#E8B618',
                success: '#5DB716',
                white: '#ffffff',
                text: '#333333',
                textSubtle: '#938788',
                onPrimary: '#FFFFFF',
                light: '#f7f7f7',
                border: '#cccccc',
            },
            hayley:
                {
                    primary: '#0054a3',
                    secondary: '#b0bec5',
                    info: '#314dba',
                    accent: '#314dba',
                    error: '#b71c1c',
                    warning: '#E8B618',
                    success: '#5DB716',
                    white: '#ffffff',
                    text: '#111111',
                    onPrimary: '#FFFFFF',
                    textSubtle: '#938788',
                    light: '#f7f7f7',
                    border: '#cccccc',
                },
        },
    },
});
