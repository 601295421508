<template>
  <centered-layout title="Forgot your password?">
    <div class="pa-5">
      <v-text-field
          autocomplete="false"
          :loading="loading"
          :disabled="loading"
          v-model="email"
          label="Email"
          :persistent-placeholder="true"
          :error="loginError.email != null"
          :error-messages="loginError.email"
      ></v-text-field>

      <vue-recaptcha
          ref="register-captcha"
          :sitekey="recaptchaKey"
          v-on:verify="onRecaptchaVerify"
          v-on:expired="onRecaptchaExpired"
          v-on:render="onRecaptchaRendered"
          v-on:error="onRecaptchaError"
          :loadRecaptchaScript="true"></vue-recaptcha>

      <global-error-inline :errors="loginError"/>


      <v-btn class="mt-4" color='primary' depressed dark :loading="loading" @click="sendReset({email: email, recaptchaResponse : recaptchaResponse})">
        <v-icon small class="mr-1">mdi-account-lock-outline</v-icon>
        Send reset email
      </v-btn>
      <div class="py-3">
        <router-link :to="{name:'Login'}">Back to login</router-link>
      </div>
    </div>
  </centered-layout>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import CenteredLayout from "../layouts/CenteredLayout";
import GlobalErrorInline from "../components/GlobalErrorInline";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'ForgotPassword',
  data: () => ({
    email: null,
    password: null,
    recaptchaResponse : null,
  }),
  computed: {
    ...mapGetters('auth', ['loading', 'user', 'multiOrg', 'clientAdmin', 'loginError']),
    recaptchaKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
    },
  },
  watch: {},
  methods: {
    ...mapActions('auth', ['forgotPassword']),
    ...mapActions('alerts', ['setUserMessage']),
    async sendReset(data) {
      await this.forgotPassword(data);
      this.email = null;
    },
    onRecaptchaVerify(response) {
      console.log("VERIFIED", response);
      this.recaptchaResponse = response;
    },
    onRecaptchaExpired() {
      this.recaptchaResponse = null;
      console.log("RECAPTCHA EXPIRED");
    },
    onRecaptchaRendered(id) {
      console.log("RENDERED", id);
    },
    onRecaptchaError() {
      this.recaptchaResponse = null;
      console.log("ERROR IN RECAPTCHA");
    }
  },
  components: {GlobalErrorInline, CenteredLayout, VueRecaptcha},
}
</script>
