<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :on-save-listeners="['siteUpdated']"
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :id="siteId"
      :initial-item="siteId == null ? {organisationId : organisationId, parentId : parentId} : {}"
      namespace="sites">
    <template #default="{item,errors,onUpdate}">
      <api-select-box
          limit="100"
          class="mb-4"
          v-if="(clientAdmin || distributor) && siteId == null"
          :custom-filters="{isDistributor: false}"
          label="Select customer"
          hint="Select customer to manage sites and zones for"
          item-text="name"
          item-value="id"
          v-model="item.organisationId"
          @input="onUpdate(item)"
          namespace="organisationSelect"
      />

      <v-text-field
          ref="szname"
          label="Site/zone name"
          v-model="item.name"
          @input="onUpdate(item)"
          :error="errors.name != null"
          :error-messages="errors.name"
      />
      <item-list-loader :custom-endpoint="siteEndpoint(item)">
        <template #main="{items}">
          <v-select
              label="Parent site"
              v-model="item.parentId"
              hint="Another site/zone that this site/zone is contained within"
              :items="filteredSites(items)"
              item-text="label"
              item-value="value"
              @input="onUpdate(item)"
              :error="errors.parentId != null"
              :error-messages="errors.parentId"/>
        </template>
      </item-list-loader>

    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import GlobalErrorInline from "../../../components/GlobalErrorInline";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {endpoints} from "@/store/network/amtag.client";

export default {
  name: "SiteAdd",
  components: {ItemListLoader, GlobalErrorInline, ApiSelectBox, FormLoader},
  props: {
    siteId: null,
    organisationId: null,
    parentId: null
  },
  data() {
    return {
      selectedOrganisationId: null,
    }
  },
  created() {
    this.selectedOrganisationId = this.organisationId
  },
  mounted() {
    //FOCUS ON THE NAME FIELD
    this.$nextTick(() => {
      this.$refs.szname.focus();
    });
  },
  methods: {
    siteEndpoint(item) {
      return endpoints.sites.getSitesForOrganisation(item.organisationId ?? this.selectedOrganisationId);
    },
    filteredSites(list) {
      if (this.siteId == null) {
        return list;
      }
      return list.filter(i => i.value !== this.siteId);
    }
  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor']),
    formType() {
      if (this.$router.currentRoute.name === "SiteDelete") return 'delete';
      if (this.$router.currentRoute.name === "SiteView") return 'view';
      return this.siteId != null ? "edit" : "add";
    },
    formTitle() {
      if (this.formType === "delete") {
        return "Delete site/zone";
      } else if (this.formType === "view") {
        return "View site/zone";
      } else if (this.formType === "add") {
        return "Create site/zone";
      } else if (this.formType === "edit") {
        return "Edit site/zone";
      }
    }
  }
}
</script>

<style scoped>

</style>
