<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-list two-line class="pa-0">
        <template v-for="(item, index) in items">
            <v-list-item :key="item.id">
                <template v-slot:default="{ active }">
                    <v-list-item-avatar class="primary white--text font-weight-bold">
                        {{ item.firstName.charAt(0) + item.lastName.charAt(0) }}
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="d-flex align-center">
                            {{ item.fullName }}
                            <v-chip
                                    v-if="item.clientAdmin"
                                    class="ml-2"
                                    color="primary"
                                    x-small
                                    outlined
                                    pill
                            >
                                Client admin
                            </v-chip>


                            <v-chip
                                    v-if="item.id === user.id"
                                    class="ml-1"
                                    color="info"
                                    x-small
                                    outlined
                                    pill
                            >
                                You
                            </v-chip>

                        </v-list-item-title>

                        <v-list-item-subtitle
                                class="text--primary"
                                v-text="item.email"
                        ></v-list-item-subtitle>

                        <v-list-item-subtitle class="mt-1">
                            <template v-if="clientAdmin || distributor">
                                <v-chip
                                        v-for="(org,orgKey) in item.organisations"
                                        v-bind:key="item.id+'-'+orgKey"
                                        class="mr-2"
                                        :color="$options.filters.roleToColor(org.role)"
                                        x-small
                                        outlined
                                        pill
                                >
                                    {{ org.name }} - {{ $options.filters.roleToString(org.role) }}
                                </v-chip>
                            </template>
                            <v-chip
                                    v-else-if="userRole(item) != null"
                                    class="mr-2"
                                    :color="$options.filters.roleToColor(userRole(item))"
                                    x-small
                                    outlined
                                    pill
                            >
                                {{ $options.filters.roleToString(userRole(item)) }}
                            </v-chip>
                        </v-list-item-subtitle>
                    </v-list-item-content>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="error" v-on="on" v-bind="attrs" @click="() => deletePendingItem(item.id)" icon ><v-icon color="white">mdi-delete</v-icon></v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>


                </template>
            </v-list-item>

            <v-divider
                    v-if="index < items.length - 1"
                    :key="index"
            ></v-divider>
        </template>
    </v-list>
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import ActionDropdown from "../ActionDropdown";

    export default {
        name: 'PendingList',
        components: {ActionDropdown},
        props: {
            items: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        methods: {
            userRole(item) {
                var search = item.organisations.filter((obj) => obj.id === this.organisation.id);
                console.log("SEARCH IS ", search);
                if (search.length > 0) {
                    return search[0].role;
                }
                return null;
            },
            ...mapActions('users',['deletePendingItem'])
        },
        computed: {
            ...mapGetters('auth', ['clientAdmin', 'user', 'distributor', 'organisation','customer']),
        }
    }
</script>
