export const periodToText = function(period,plural) {
    switch (period) {
        case 0:
            return "Hour"+ (plural ? "s" : "")
        case 1:
            return "Day"+ (plural ? "s" : "")
        case 2:
            return "Week"+ (plural ? "s" : "")
        case 3:
            return "Month"+ (plural ? "s" : "")
    }
}
