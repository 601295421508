<template>
  <div>
    <h2 class="mb-3 d-flex flex-row" v-if="title">
      <div class="flex-fill">{{ title }}</div>
      <v-btn v-if="formType === 'edit' || formType === 'add' || formType === 'link'" :loading="saveLoading"
             @click="saveAction" color='success' depressed dark>
        <v-icon v-if="btnIcon != null" small class="mr-1">{{ btnIcon }}</v-icon>
        {{ btnText }}
      </v-btn>
    </h2>
    <v-progress-circular :indeterminate="true" color="primary" v-if="formLoading"></v-progress-circular>
    <v-form @submit.prevent="saveAction" v-if="!formLoading" class="pb-4" autocomplete="off" :disabled="readOnly">

      <slot v-bind:item="formData" v-bind:errors="errors" v-bind:onUpdate="onUpdate">

      </slot>
      <div class="mt-4 bottom-buttons">
        <global-error-inline :errors="errors"/>
        <v-btn v-if="formType === 'edit' || formType === 'add' || formType === 'link'" :loading="saveLoading"
               @click="saveAction" color='success' depressed dark>
          <v-icon v-if="btnIcon != null" small class="mr-1">{{ btnIcon }}</v-icon>
          {{ btnText }}
        </v-btn>
        <v-btn v-if="formType === 'delete'" :loading="saveLoading" @click="deleteAction" color='error' depressed dark>
          <v-icon v-if="deleteBtnIcon != null" small class="mr-1">{{ deleteBtnIcon }}</v-icon>
          {{ deleteBtnText }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import GlobalErrorInline from "./GlobalErrorInline";
import * as _ from "lodash";
import emitter from "@/utils/emitter";


export default {
  name: "FormLoader",
  components: {GlobalErrorInline},
  props: {
    id: null,
    namespace: null,
    noResults: "No results for your filter",
    formType: {
      type: String,
      default: "edit"
    },
    title: null,
    btnText: {
      type: String,
      default: 'Save'
    },
    btnIcon: {
      type: String,
      default: 'mdi-content-save-outline'
    },
    deleteBtnText: {
      type: String,
      default: 'Delete'
    },
    deleteBtnIcon: {
      type: String,
      default: 'mdi-delete-outline'
    },
    itemTransform: {
      type: Function,
      default: null
    },
    beforeSave: {
      type: Function,
      default: null
    },
    initialItem: {
      type: Object,
      default() {
        return {}
      }
    },
    onSaveListeners:{
      type: Array,
      default() {
        return [];
      }
    },
  },
  data: () => ({
    formData: {},
    errors: {},
    lastUpdated: null
  }),
  created() {
    this.formData = this.initialItem
    if (this.id != null)
      this.getItem();
  },
  watch: {
    item(newVal, oldVal) {
      if (this.itemTransform != null) {
        this.formData = this.itemTransform(newVal)
      } else {
        this.formData = newVal;
      }
      if (this.formType === 'add') {
        if (newVal.updatedAt !== this.lastUpdated) {
          this.onSaveListeners.forEach(listener => {
            emitter.emit(listener, newVal);
            console.log("EMITTING", listener, newVal);
          });
          this.$router.back()
        }
      } else if (this.formType === 'edit' || this.formType === 'delete') {
        if (this.lastUpdated != null && newVal.updatedAt !== this.lastUpdated) {
          this.$router.back()
          this.onSaveListeners.forEach(listener => {
            emitter.emit(listener, newVal);
            console.log("EMITTING", listener, newVal);
          });
        }
      }
      this.lastUpdated = newVal.updatedAt;
      this.$emit("itemUpdated", newVal);
    },
    saveError(newVal, oldVal) {
      console.log("NEW ERRORS");
      this.errors = newVal;
    },
  },
  computed: {
    readOnly() {
      return this.formType === "delete" || this.formType === "view";
    },
    item() {
      return this.$store.getters[this.namespace + '/item'];
    },
    formLoading() {
      return this.$store.getters[this.namespace + '/formLoading'];
    },
    saveLoading() {
      return this.$store.getters[this.namespace + '/saveLoading'];
    },
    saveError() {
      return this.$store.getters[this.namespace + '/saveError'];
    },
  },
  methods: {
    getItem() {
      return this.$store.dispatch(this.namespace + '/getItem', this.id);
    },
    async saveAction() {
      var dataCopy = _.clone(this.formData);
      if (this.beforeSave != null) {
        dataCopy = this.beforeSave(dataCopy);
      }
      console.log("SAVING", this.formType);
      var data;
      if (this.formType === "add") {
        data = await this.$store.dispatch(this.namespace + '/addItem', dataCopy);
      } else if (this.formType === "edit") {
        data = await this.$store.dispatch(this.namespace + '/updateItem', dataCopy);
      } else if (this.formType === "link") {
        data = await this.$store.dispatch(this.namespace + '/link', dataCopy.accountCode);
      }
      this.$emit('complete', data);
      return data;
    },
    deleteAction() {
      var dataCopy = _.clone(this.formData);
      if (this.beforeSave != null) {
        dataCopy = this.beforeSave(dataCopy);
      }
      console.log("SAVING", this.formType);

      return this.$store.dispatch(this.namespace + '/deleteItem', this.id).then((newData) => {
        this.$emit('complete', newData);
        this.onSaveListeners.forEach(listener => {
          emitter.emit(listener, newData);
          console.log("EMITTING", listener, newData);
        });
      });
    },
    onUpdate(item) {
      console.log("New ITEM", item);
      this.formData = item;
      this.$emit("itemUpdated", item);
    },
  },
}
</script>

<style lang="scss">
.card-header {
  color: var(--v-text-base);
}

.v-list-item__icon {
  margin-right: 10px !important;
}
</style>
