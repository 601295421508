<template>
  <div class="filter-field px-3 py-3">
    <label>{{ filter.label }}</label>
    <template v-if="filter.type === 0 && filter.isVisible">
      <v-text-field
          dense
          persistent-hint
          @input="onChange"
          :clearable="true"
          clear-icon="mdi-close-circle"
          :value="filter.textValue"
          :hint="filter.description">
      </v-text-field>
    </template>

    <template v-else-if="filter.type === 1 && filter.isVisible">
      <v-autocomplete
          dense
          persistent-hint
          @input="onChange"
          :items="filter.options"
          :clearable="true"
          item-value="value"
          item-text="label"
          append-icon="mdi-chevron-down"
          clear-icon="mdi-close-circle"
          :value="selectedValue"
          :hint="filter.description">
      </v-autocomplete>
    </template>

    <template v-else-if="filter.type === 2 && filter.isVisible">
      <v-autocomplete
          dense
          chips
          small-chips
          deletable-chips
          persistent-hint
          @input="onChange"
          :multiple="true"
          :items="filter.options"
          :clearable="true"
          item-value="value"
          item-text="label"
          append-icon="mdi-chevron-down"
          clear-icon="mdi-close-circle"
          :value="selectedValue"
          :hint="filter.description">
      </v-autocomplete>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "FilterField",
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      filter: this.value
    }
  },
  computed : {
    selectedValue : function() {
      if(this.filter.type === 1) {
        let selected = _.find(this.filter.options,(option) => option.isSelected);
        return selected != null ? selected.value : null;
      } else if(this.filter.type === 2) {
        return this.filter.options.filter((option) => option.isSelected).map((option) => option.value);
      }
    }
  },
  watch : {
    value : {
      deep : true,
      handler(newVal, oldVal) {
        console.log("FilterField updated", newVal);
        this.filter = newVal;
      }
    }
  },
  methods : {
    onChange:function(newValue) {
      if(this.filter.type === 0) {
        this.filter.textValue = newValue;
      } else if(this.filter.type === 1) {
        this.filter.options = this.filter.options.map(function(obj) {
          obj.isSelected = false;
          if(obj.value === newValue) {
            obj.isSelected = true;
          }
          return obj;
        });
      } else if(this.filter.type === 2) {
        this.filter.options = this.filter.options.map(function(obj) {
          obj.isSelected = newValue.includes(obj.value);
          return obj;
        });
      }
      console.log("EMITTING NEW VALUE",this.filter);
      this.$emit('input', this.filter);
    }
  }

}
</script>

<style scoped lang="scss">
.filter-field {
  border-bottom: solid 1px var(--v-border-base);
}
.filter-field label {
  color: var(--v-textSubtle-base);
  font-weight: 700;
}
</style>
