import emitter from "@/utils/emitter";

export default function paginationMixin() {
    return {
        props: {
            listeners: {
                type: Array,
                default() {
                    return [];
                }
            },
            customFilters: {
                type: Object,
                default() {
                    return {}
                }
            },
            countReference: null,
            limit: null
        },
        created() {
            var query = this.$router.currentRoute.query;

            if (query.filters) {
                this.filters = [];
            } else if (query.clear) {
                this.filters = [];
            } else {
                this.getItems();
            }
            this.listeners.forEach(listenerKey => {
                emitter.on(listenerKey, () => {
                    this.getItems();
                });
            })
        },
        beforeDestroy() {
            this.listeners.forEach(listenerKey => {
                emitter.off(listenerKey);
            });
        },
        computed: {
            items() {
                return this.$store.getters[this.namespace + '/items'];
            },
            totalPages() {
                return this.$store.getters[this.namespace + '/totalPages']
            },
            totalItems() {
                return this.$store.getters[this.namespace + '/totalItems'];
            },
            perPage() {
                return this.$store.getters[this.namespace + '/perPage']
            },
            loading() {
                return this.$store.getters[this.namespace + '/loading']
            },
            loadingError() {
                return this.$store.getters[this.namespace + '/loadingError']
            },
            currentPage: {
                get() {
                    return this.$store.getters[this.namespace + '/currentPage']
                },
                set(value) {
                    this.$store.dispatch(this.namespace + '/updatePage', value)
                }
            },
            filters: {
                get() {
                    var currentFilters = this.$store.getters[this.namespace + '/filters'];
                    console.log("CURRENT FILTERS " + this.namespace, currentFilters);
                    return currentFilters;
                },
                set(value) {
                    this.$store.dispatch(this.namespace + '/updateFilters', value).then(() => {
                        this.getItems();
                    })
                }
            },
        },
        methods: {
            getItems() {
                var query = this.$router.currentRoute.query;
                var customFilters = _.clone(this.customFilters);
                console.log("NEW QUERY IS", query);
                if (query.filters) {
                    var decoded = decodeURI(query.filters);
                    console.log("DECODED QUERY IS", decoded);
                    try {
                        let parsed = JSON.parse(decoded);
                        if (parsed != null) {
                            customFilters = {...customFilters, ...parsed};
                        }
                    } catch (e) {
                        console.log("ERROR PARSING QUERY", e);
                    }

                    this.$router.replace({name: this.$router.currentRoute.name});
                }
                if (query.clear) {
                    this.$router.replace({name: this.$router.currentRoute.name});
                }
                console.log("CUSTOM FILTERS ARE", customFilters);
                let queryParams = {};
                if (this.limit) {
                    queryParams.limit = this.limit;
                }
                return this.$store.dispatch(this.namespace + '/getItems', {customFilters,queryParams})
            }
        },
        watch: {
            currentPage(newVal, oldVal) {
                if (newVal !== oldVal)
                    this.getItems();
            },
            customFilters: {
                deep: true,
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        console.log("CUSTOM FILTERS CHANGED");
                        this.getItems();
                    }
                }
            }
        }
    }
}
