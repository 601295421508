<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-tag-multiple" title="Tagged Assets">
    <paginated-result-loader
        :enable-exports="true"
        namespace="assets">
      <template v-slot:default="props">
        <asset-list :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import HoseList from "../../../components/assets/AssetList";
import AssetList from "@/components/assets/AssetList";

export default {
  name: 'Assets',
  components: {
    AssetList,
    PaginatedResultLoader,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin']),
  }
}
</script>
