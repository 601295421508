<template>
<span v-if="count > 0" class=" px-2 rounded light py-1 mr-1">
            <v-avatar
                class="mr-1 flex-wrap"
                :color="color"
                size="8"
            />
            <strong class="flex-wrap">{{ count + " " + word }}</strong>
          </span>
</template>
<script>
export default {
  name: 'CountIndicator',
  props: {
    count: 0,
    word: {
      type: String,
      default() {
        return "complete"
      }
    },
    color: {
      type: String,
      default() {
        return "success"
      }
    }
  }
}
</script>
