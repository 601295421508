<template>
  <v-simple-table>
    <template>
      <thead>
      <tr>
        <th v-if="clientAdmin" class="text-left">
          Organisation
        </th>
        <th class="text-left">
          Name
        </th>

        <th class="text-left">
          Sector
        </th>

        <th>

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id">
        <td v-if="clientAdmin">
          {{ item.organisation.name }}
        </td>
        <td>
          <asset-type-dropdown :id="item.id"/>
          {{ item.name }}
        </td>
        <td>
          {{ item.sector.name }}
        </td>
        <td class="text-right">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import AssetTypeDropdown from "@/components/asset-types/AssetTypeDropdown";

export default {
  name: 'AssetTypeList',
  components: {AssetTypeDropdown, ActionDropdown},
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin'])
  },
  methods: {
    getDropdown(item) {
      return [
        {
          label: 'View full details',
          icon: 'mdi-arrow-expand-all',
          route: {name: 'AssetTypeView', params: {assetTypeId: item.id}}
        },
        {
          label: 'Edit',
          icon: 'mdi-pencil',
          route: {name: 'AssetTypeEdit', params: {assetTypeId: item.id}}
        },
        {
          label: 'Delete',
          icon: 'mdi-delete-outline',
          route: {name: 'AssetTypeDelete', params: {assetTypeId: item.id}}
        }
      ]
    }
  }
}
</script>
