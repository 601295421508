<template>
  <v-list
      nav
      dense
  >
    <v-list-item-group
        active-class="active"
    >
      <v-list-item
          v-for="(item, i) in topMenu"
          :exact-path="item.route === 'Portal'"
          :key="i"
          :to="{name : item.route, query: {clear : item.clear}}"
      >
        <v-list-item-icon class="mr-2">
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold" v-text="item.text"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>


  </v-list>
</template>

<style scoped lang="scss">
.active {
  //background: var(--v-primary-base);
  //color: var(--v-text-base);
}
</style>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DashboardSideMenu",
  data: () => ({}),
  computed: {
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'isUserAdmin', 'isAdmin','systemAdmin']),
    topMenu() {
      let tagManagement = {icon: 'mdi-tag-multiple-outline', text: 'Tag Management', route: 'TagManagement', clear: true};
      let sectors = {icon: 'mdi-chart-pie', text: 'Sectors', route: 'Sectors', clear: true};
      let organisations = {icon: 'mdi-office-building-outline', text: this.clientAdmin ? 'Organisations' : 'Customers', route: 'Organisations', clear: true};
      let users = {icon: 'mdi-account-group-outline', text: 'Users', route: 'Users', clear: true};
      let tasks = {icon: 'mdi-checkbox-multiple-outline', text: 'Task Library', route: 'Tasks', clear: true};
      let assetTypes = {icon: 'mdi-toolbox', text: 'Asset Library', route: 'AssetTypes', clear: true};
      let assets = {icon: 'mdi-tag-multiple', text: 'Tagged Assets', route: 'Assets', clear: true};
      let taskPlanner = {icon: 'mdi-calendar-month', text: 'Task Planner', route: 'TaskPlanner', clear: true};
      let completedTasks = {icon: 'mdi-clipboard-check-outline', text: 'Completed Tasks', route: 'CompletedTasks', clear: true};
      let sitesAndZones = {icon: 'mdi-map-legend', text: 'Sites & Zones', route: 'SitesAndZones', clear: true};
      let data = [
        {icon: 'mdi-view-dashboard-outline', text: 'Dashboard', route: 'Portal'},
      ];
      if(this.systemAdmin) {
        data.push({icon: 'mdi-database-cog', text: 'Manage Clients', route: 'Clients'});
        data.push(tagManagement);
      }
      if (this.clientAdmin) {
        data.push(organisations);
        data.push(users);
        data.push(tasks);
        data.push(assetTypes);
        data.push(assets);
        data.push(taskPlanner);
        data.push(completedTasks);
      } else if (this.distributor) {
        data.push(organisations);
        if (this.isUserAdmin) {
          data.push(users);
          data.push(sectors);
        }
        data.push(tasks);
        data.push(assetTypes);
        data.push(assets);
        data.push(taskPlanner);
        data.push(completedTasks);
      } else if (this.customer) {
        data.push(sitesAndZones);
        if (this.isUserAdmin) {
          data.push(users);
          data.push(sectors);
        }
        data.push(tasks);
        data.push(assetTypes);
        data.push(assets);
        data.push(taskPlanner);
        data.push(completedTasks);
      }

      return data;
    },
  }
}
</script>

<style scoped>

</style>
