import {httpRequest, endpoints} from "../network/amtag.client";
import * as _ from "lodash";

export const baseState = () => {
    return {
        item: {},
        items: [],
        filters: [],
        currentPage: 1,
        totalPages: 1,
        perPage: 15,
        totalItems: 0,
        loading: false,
        formLoading: false,
        saveLoading: false,
        loadingError: {},
        formError: {},
        saveError: {}
    }
};
export const baseGetters = () => {
    return {
        items: state => state.items,
        item: state => state.item,
        filters: state => state.filters,
        totalPages: state => state.totalPages,
        currentPage: state => state.currentPage,
        totalItems: state => state.totalItems,
        perPage: state => state.perPage,
        loadingError: state => state.loadingError,
        formError: state => state.formError,
        saveError: state => state.saveError,
        loading: state => state.loading,
        formLoading: state => state.formLoading,
        saveLoading: state => state.saveLoading,
    }
};
export const baseMutations = () => {
    return {
        loading(state, data) {
            state.loading = data;
        },
        loadingError(state, data) {
            state.loadingError = data;
        },
        formLoading(state, data) {
            state.formLoading = data;
        },
        saveLoading(state, data) {
            state.saveLoading = data;
        },
        updateResults(state, data) {

            state.items = data.items;
            state.currentPage = data.currentPage;
            state.totalItems = data.totalItems;
            state.totalPages = data.totalPages;
            state.perPage = data.perPage;
            state.filters = data.filters;
        },
        updateFilters(state, filters) {
            state.currentPage = 1;
            state.filters = filters;

        }
        ,
        updatePage(state, page) {
            state.currentPage = page;
        },
        formError(state, data) {
            state.formError = data;
        },
        saveError(state, data) {
            state.saveError = data;
        },
        updateItem(state, data) {
            state.item = data;
        }
    }
};
export const baseActions = (endpoint) => {
    return {
        async getItems({commit, state}, {customFilters = null, queryParams = null}) {
            commit('loadingError', {identifier: null, loadingError: {}});
            commit('loading',true);
            try {
                const data = {
                    page: state.currentPage
                };
                let filtersFinal = {};
                _.forEach(state.filters, function (filter) {
                    if (filter.type === 0) {
                        if (filter.textValue != null && !_.isEmpty(filter.textValue))
                            filtersFinal[filter.id] = filter.textValue;
                    } else if (filter.type === 1) {
                        var selected = _.find(filter.options, (option) => option.isSelected);
                        if (selected != null)
                            filtersFinal[filter.id] = selected.value;
                    } else if(filter.type === 2) {
                        var selected = _.filter(filter.options, (option) => option.isSelected).map((option) => option.value);
                        console.log("Multi select", selected);
                        filtersFinal[filter.id] = selected;
                    }

                });

                try {
                    if (customFilters !== null) {
                       filtersFinal = {...filtersFinal, ...customFilters};
                    }
                } catch (e) {
                    console.log("Error adding custom filters", e);
                }

                data['filters'] = filtersFinal;
                if(queryParams != null) {
                    //loop through the query param object and add them to the data object
                    for (var key in queryParams) {
                        if (queryParams.hasOwnProperty(key)) {
                            data[key] = queryParams[key];
                        }
                    }
                }
                let res = await httpRequest(endpoints[endpoint].getItems, data);
                commit('updateResults', res.data);
            } catch (e) {
                console.log("error", e);
                console.log("error data", e.response.data);
                commit('loadingError', e.response.data);
            } finally {
                commit('loading', false);
            }
        },
        updateFilters({commit, state}, filters) {
            commit('updateFilters', filters);
        },
        updatePage({commit, state}, page) {
            commit('updatePage', page);
        },
        async link({commit, state, dispatch}, accountCode) {
            commit('saveError', {});
            commit('saveLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].link(accountCode), null);
                commit('updateItem', res.data);
                return res.data;
            } catch (e) {
                console.log("error", e.response.data);
                commit('saveError', e.response.data);
            } finally {
                commit('saveLoading', false);
            }
            return null;
        },
        async getItem({commit, state}, id) {
            commit('formError', {});
            commit('formLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].getItem(id), null);
                commit('updateItem', res.data);
            } catch (e) {
                console.log("error", e.response.data);
                commit('formError', e.response.data);
            } finally {
                commit('formLoading', false);
            }
        },
        async deleteItem({commit, state, dispatch}, id) {
            commit('saveError', {});
            commit('saveLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].deleteItem(id), null);
                commit('updateItem', res.data);
            } catch (e) {
                console.log("error", e.response.data);
                commit('saveError', e.response.data);
            } finally {
                commit('saveLoading', false);
            }
        },
        async patchItem({commit, state, dispatch}, id) {
            commit('saveError', {});
            commit('saveLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].patchItem(id), null);
                commit('updateItem', res.data);
            } catch (e) {
                console.log("error", e.response.data);
                commit('saveError', e.response.data);
            } finally {
                commit('saveLoading', false);
            }
        },
        async updateItem({commit, state, dispatch}, data) {
            commit('saveError', {});
            commit('saveLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].updateItem, data);
                commit('updateItem', res.data);
                dispatch('onAddOrEdit');
            } catch (e) {
                console.log("error", e.response.data);
                commit('saveError', e.response.data);
            } finally {
                commit('saveLoading', false);
            }
        },
        async addItem({commit, state, dispatch}, data) {
            commit('saveError', {});
            commit('saveLoading', true);
            try {
                let res = await httpRequest(endpoints[endpoint].addItem, data);
                commit('updateItem', res.data);
                dispatch('onAddOrEdit');
            } catch (e) {
                console.log("error", e.response.data);
                commit('saveError', e.response.data);
            } finally {
                commit('saveLoading', false);
            }
        },
        async onAddOrEdit({commit, state, dispatch}) {

        }
    }
};


