<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      class="px-4"
      :form-type="formType"
      :title="formTitle"
      :before-save="beforeSave"
      :item-transform="itemTransform"
      :btn-text="formTitle"
      :id="batchId"
      deleteBtnText="Delete"
      btn-icon="mdi-pencil"
      namespace="batches">
    <template #default="{item,errors,onUpdate}">
      <template v-if="formType === 'delete'">
        <v-alert type="error">
          Deleting of a batch is irreversible, are you sure you want to do this?
        </v-alert>
      </template>
      <template v-else>
        <v-text-field
            label="Batch name"
            v-model="item.name"
            @input="onUpdate(item)"
            :error="errors.name != null"
            :error-messages="errors.name"
        />
        <v-text-field
            label="Max number of checks for these tags"
            v-model="item.maxChecks"
            type="number"
            step="1"
            :error="errors.maxChecks != null"
            :error-messages="errors.maxChecks"
        />
      </template>
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "BatchAdd",
  components: { FormLoader},
  data() {
    return {}
  },
  props: {
    batchId: null
  },
  methods: {
    beforeSave(item) {
      return item;
    },
    itemTransform(item) {
      return item;
    },
    ...mapActions('batches', ['getItem'])

  },
  computed: {
    ...mapGetters('users', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation']),
    formType() {
      if (this.$router.currentRoute.name === "BatchEdit") return 'edit';
      if (this.$router.currentRoute.name === "BatchAdd") return 'add';
      return "delete";
    },
    formTitle() {
      if (this.$router.currentRoute.name === "BatchEdit") return 'Edit batch';
      if (this.$router.currentRoute.name === "BatchAdd") return 'Add batch';
      return "Delete batch";
    }
  }
}
</script>

<style scoped>

</style>
