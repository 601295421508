<template>
    <div v-if="value" class="asset-type-row d-flex flex-row justify-space-between align-center py-1">
        <label class="mr-2">{{ label }}</label>
        <div class="val">{{ value }}</div>
    </div>
</template>
<script>
    export default {
        name: 'LabelValueHorizontal',
        props: {
            label : null,
            value : null
        }
    }
</script>
<style scoped lang="scss">

</style>
