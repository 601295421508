<template>
  <div class="border_all light rounded pa-3 pt-2">
    <div class="font-weight-bold"><small>Task Frequency</small></div>
    <div class="d-flex flex-row">
        <v-text-field
            class="flex-fill mr-2"
            min="0"
            v-model="checkTimeline.periodValue"
            hint="Frequency"
            persistent-hint
            single-line
            type="number"
        />
        <v-select
            :items="options"
            item-text="label"
            item-value="value"
            class="flex-fill"
            v-model="checkTimeline.period"
            hint="Frequency unit"
            persistent-hint
        />
    </div>
    <check-timeline-chip :check-timeline="checkTimeline"></check-timeline-chip>
  </div>
</template>

<script>
import {periodToText} from "@/helpers/helperFunctions";
import CheckTimelineChip from "@/components/CheckTimelineChip";

export default {
  name: "CheckTimelineInput",
  components: {CheckTimelineChip},
  props: {
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      checkTimeline: this.value,
    }
  },
  computed : {
    options() {
      return [
        {value: 0, label: periodToText(0,this.checkTimeline.periodValue > 1)},
        {value: 1, label: periodToText(1,this.checkTimeline.periodValue > 1)},
        {value: 2, label: periodToText(2,this.checkTimeline.periodValue > 1)},
        {value: 3, label: periodToText(3,this.checkTimeline.periodValue > 1)},
      ];
    },
  },
  watch: {
    checkTimeline(newVal) {
      this.$emit('input', this.checkTimeline);
    }
  }
}
</script>

<style scoped>

</style>
