<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-list-item-action class="ma-0">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="white"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon>{{ icon }}</v-icon>
                    <template v-if="label != null">
                        {{ label }}
                    </template>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                        v-for="(item,itemKey) in items"
                        v-bind:key="Math.random()"
                        @click="() => { if(item.click !== undefined) item.click(); }"
                        :to="item.route">
                    <v-list-item-icon>
                        <v-icon small>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-list-item-action>
</template>
<script>
    export default {
        name: 'ActionDropdown',
        props: {
            items: {
                type : Array,
                default : [],
            },
            label : {
                type: String,
                default() {
                    return null;
                }
            },
            icon : {
                type: String,
                default() {
                    return 'mdi-dots-horizontal';
                }
            }
        },
        computed : {
            random() {
                return Math.random();
            }
        },
    }
</script>
