<template>
  <v-simple-table>
    <template>
      <thead>
      <tr>
        <th class="text-left">
          Name
        </th>
        <th class="text-left">

        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id">
        <td>

          {{ item.name }}
        </td>

        <td class="text-right">
          <action-dropdown :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";

export default {
  name: 'ManufacturerList',
  components: {ActionDropdown},
  props: {
    items: {
      type: Array
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin'])
  },
  methods: {
    getDropdown(item) {
      return [
        {
          label: 'Edit',
          icon: 'mdi-pencil',
          route: {name: 'ManufacturerEdit', params: {manufacturerId: item.id}}
        },
        {
          label: 'Delete',
          icon: 'mdi-delete-outline',
          route: {name: 'ManufacturerDelete', params: {manufacturerId: item.id}}
        }
      ]
    }
  }
}
</script>
