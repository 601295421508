<template>
    <div>
        <h1 v-if="!hideTitle" class="primary--text">amTAG WEBSITE AND APP – LICENCE AND TERMS OF USE</h1>

        <p>PLEASE READ THESE LICENCE TERMS CAREFULLY. THIS IS A LEGALLY BINDING AGREEMENT.<br/>
            THE amTAG WEBSITE AND APP ARE INTENDED FOR USE FOR BUSINESS PURPOSES ONLY.
        </p>

        <p>BY CHECKING "ACCEPT" ON ACCOUNT CREATION YOU AGREE TO THESE TERMS WHICH WILL BIND YOU.<br/>
            IF YOU DO NOT AGREE TO THESE TERMS PLEASE DO NOT CHECK "ACCEPT".
        </p>

        <h2 class="primary--text">WHO WE ARE AND WHAT THIS AGREEMENT DOES</h2>
        <p>We amTAG LTD of Unit 3 & 4 Royson Way, Hurn Road, Dereham, Norfolk, United Kingdom, NR19 1WD license you
            to use,
            as permitted in these terms on a non-exclusive basis:
        </p>
        <ul>
            <li>The amTAG website, (Website) and any updates or supplements to it;</li>
            <li>The amTAG mobile application software, (App) and any updates or supplements to it;</li>
            <li>Any related online or electronic documentation (Documentation)</li>
            <li>The service you connect to via the Website and App and the content we or your supplier of amTAG
                tagged
                products (Supplier) provide to you through it including your use of and access to your amTAG
                Account (as
                defined below) (Service)
            </li>
        </ul>
        <h2 class="primary--text">YOUR PRIVACY AND DATA</h2>
        <p>We only use any personal data we collect through your use of the Website, App and Services in the ways
            set out in
            our
            <router-link target="_blank" :to="{name : 'InfoPage',params: {type: 'privacy-policy'}}">privacy policy.
            </router-link>
        </p>

        <p>Please note where you have purchased a amTAG tagged product through a third party (Supplier) they may
            have
            already set up an account for you on the Website (“your amTAG Account”). Where this is the case you
            will have
            received an email inviting you to confirm your amTAG Account and download the App which will allow you
            access
            to that account. If this is the case your Supplier may access and manage your amTAG Account and the
            personal
            data collected through the Website and App. Whilst we are a data controller, in this case your Supplier
            will
            also be a data controller and their privacy policy will apply to their use of any personal data
            collected
            through the Website or App or otherwise stored within your amTAG Account. Your Supplier should have
            provided
            you with a copy of their Privacy Policy at the time they agreed to set you up with your amTAG Account.
            If they
            did not do so you should request one immediately. If you do not wish your Supplier to have access to the
            personal data collected through your amTAG Account, then you should notify them and us immediately so
            that
            appropriate steps can be taken.</p>

        <p>Please be aware that internet transmissions are never completely private or secure and that any message
            or
            information you send using the Website, App or any Service may be read or intercepted by others, even if
            there
            is a special notice that a particular transmission is encrypted.</p>

        <p>In consideration for our granting of the licence to use the Website and App as set out in this Licence,
            you grant
            to us a non-exclusive, royalty-free, irrevocable and perpetual licence to use any data submitted by you
            or
            collected by us in connection with your use of the Website or App or otherwise stored in your amTAG
            Account
            (Data):</p>
        <ul>
            <li>for the purpose of providing your amTAG Account, the App and the Website;</li>
            <li>in the course of our business (on an anonymised and/or aggregated basis) for the purposes of
                maintaining its
                infrastructure, capacity planning, providing services to other customers, and developing new
                software and
                services;
            </li>
            <li>for the purposes of analysing and commenting on industry trends and developments and to develop our
                business
                with existing and new customers (where such Data is anonymised and/or aggregated).
            </li>
        </ul>

        <h2 class="primary--text">OPERATING SYSTEM REQUIREMENTS</h2>
        <p>The App requires an iOS 13+ or Android 5.0+ device with a minimum of 2GB of memory. We recommend a
            minimum of
            500mb free storage space for the App and data required. Database access can be via any device connected
            to the
            internet.</p>

        <h2 class="primary--text">SUPPORT FOR THE WEBSITE AND APP AND HOW TO TELL US ABOUT PROBLEMS</h2>
        <p>Contacting us. If you want to learn more about the Website, App or the Service or have any problems using
            them,
            including if you think the App is faulty or misdescribed please contact us at info@amtag.uk or 0330 030
            9888 or
            alternatively you can speak to your Supplier who will get in touch with us on your behalf.</p>
        <p>How we will communicate with you. If we have to contact you, we will do so by email, by SMS or by
            pre-paid post,
            using the contact details you have provided to us or where appropriate we may ask your Supplier to
            contact you
            on our behalf.</p>

        <h2 class="primary--text">HOW YOU MAY USE THE APP, INCLUDING HOW MANY DEVICES YOU MAY USE IT ON</h2>
        <p>In return for your agreeing to comply with these terms you may:</p>
        <ul>
            <li>download, stream, use and display the App onto as many devices as you require for your business
                needs.
            </li>
            <li>use any Documentation to support your use of the App.</li>
            <li>use the Website to access and use your amTAG Account.</li>
            <li>provided you comply with the Licence Restrictions, make a copy of the App for back-up purposes;
                and
            </li>
            <li>receive and use any free supplementary software code or update of the App incorporating "patches"
                and
                corrections of errors as we may provide to you.
            </li>
        </ul>

        <p><strong>YOU MUST BE 18 OR OVER TO ACCEPT THESE TERMS AND INSTALL THE APP</strong></p>

        <h2 class="primary--text">YOU MUST USE THIS APP ONLY FOR BUSINESS PURPOSES</h2>
        <p>You hereby agree to use the Website, App, Documentation and Services wholly for purposes relating to your
            trade,
            business, craft or profession and not for any personal purposes.</p>

        <h2 class="primary--text">CHANGES TO THESE TERMS</h2>
        <p>We may need to change these terms to reflect changes in law or best practice or to deal with additional
            features
            which we introduce. We will give you at least 30 days-notice of any change by sending you an email with
            details
            of the change or notifying you of a change when you next start the App or log into your amTAG Account
            via
            the
            Website. If you do not accept the notified changes you should stop using the Website, App, Documentation
            and
            Services.</p>

        <h2 class="primary--text">UPDATE TO THE WEBSITE AND APP AND CHANGES TO THE SERVICE</h2>
        <p>From time to time we may automatically update the Website and App and change the Service to improve
            performance,
            enhance functionality, reflect changes to the operating system or address security issues.
            Alternatively, we
            may
            ask you to update the App for these reasons. If you choose not to install such updates or if you opt out
            of
            automatic updates you may not be able to continue using the App and the Services although you may still
            be
            able
            to access your amTAG Account through the Website.</p>

        <h2 class="primary--text">WE MAY COLLECT TECHNICAL DATA ABOUT YOUR DEVICE</h2>
        <p>By using the Website, App or any of the Services, you agree to us collecting and using technical
            information
            about the devices you use the Website and App on and related software, hardware and peripherals to
            improve
            our
            products and to provide any Services to you.</p>

        <h2 class="primary--text">WE ARE NOT RESPONSIBLE FOR OTHER WEBSITES YOU LINK TO</h2>
        <p>The Website, App or any Service may contain links to other independent websites which are not provided by
            us.
            These sites are not under our control, and we are not responsible for and have not checked and approved
            their
            content or their privacy policies (if any). You will need to make your own independent judgement about
            whether
            to use any such independent sites, including whether to buy any products or services offered by
            them.</p>

        <h2 class="primary--text">LICENCE RESTRICTIONS</h2>
        <p>You agree that you will:</p>
        <ul>
            <li>not rent, lease, sub-license, loan, provide, or otherwise make available, the Website, App,
                Documentation or
                Services to any person without our permission;
            </li>
            <li>not copy the Website, App, Documentation or Services, except in respect of the App where this is
                necessary for
                the purpose of back-up or operational security;
            </li>
            <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the Website, App,
                Documentation
                or Services nor permit the Website, App or the Services or any part of them to be combined with, or
                become
                incorporated in, any other programs, except as necessary to use the Website, App and the Services on
                devices as
                permitted in these terms;
            </li>
            <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any
                part
                of the
                Website, App or the Services nor attempt to do any such things, except to the extent that (by virtue
                of
                sections
                50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited
                because
                they are
                necessary to decompile the App to obtain the information necessary to create an independent program
                that
                can be
                operated with the App or with another program (Permitted Objective), and provided that the
                information
                obtained
                by you during such activities:
            </li>
            <li>is not disclosed or communicated without our prior written consent to any third party to whom it is
                not
                necessary to disclose or communicate it in order to achieve the Permitted Objective; and
            </li>
            <li>is not used to create any software that is substantially similar in its expression to the Website or
                App;
            </li>
            <li>is kept secure; and</li>
            <li>is used only for the Permitted Objective;</li>
            <li>comply with all applicable technology control or export laws and regulations that apply to the
                technology used
                or supported by the App or any Service.
            </li>
        </ul>

        <h2 class="primary--text">ACCEPTABLE USE RESTRICTIONS</h2>
        <p>You must:</p>
        <ul>
            <li>not use the App or any Service in any unlawful manner, for any unlawful purpose, or in any manner
                inconsistent
                with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting
                malicious code,
                such as viruses, or harmful data, into the Website, App, any Service or any operating system;
            </li>
            <li>not infringe our intellectual property rights or those of any third party in relation to your use of
                the
                Website, App or any Service;
            </li>
            <li>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to
                your
                use of
                the Website, App or any Service;
            </li>
            <li>not use the Website, App or any Service in a way that could damage, disable, overburden, impair or
                compromise
                our systems or security or interfere with other users; and
            </li>
            <li>not collect or harvest any information or data from any Service or our systems or attempt to
                decipher
                any
                transmissions to or from the servers running any Service.
            </li>
        </ul>

        <h2 class="primary--text">INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>All intellectual property rights in the Website, App and the Services throughout the world belong to us
            and
            the
            rights in the Website, App and the Services are licensed (not sold) to you. You have no intellectual
            property
            rights in, or to, the Website, App or Services other than the right to use them in accordance with these
            terms.</p>

        <h2 class="primary--text">OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h2>
        <p>No warranties. To the extent permitted under law, we provide no warranties (whether implied or otherwise)
            to
            you
            in relation to the Website, App, Documents or Services, and to the fullest extent permitted by law all
            such
            warranties are excluded. Please refer to your Supplier for details of any warranties which they may
            provide
            in
            respect of the products which you have purchased from them.</p>
        <p>The Website, App, Documents and Services are provided for internal use by your business and you agree not
            to
            use
            the Website, App or Documents for any re-sale purposes.</p>
        <p>What we are not liable for. We shall not, in any circumstances, be liable to you, for any of the
            following
            flowing from your use of the Website, App, Documentation and/or Services:</p>
        <ul>
            <li>loss of profits, sales, business, or revenue;</li>
            <li>business interruption</li>
            <li>loss of anticipated savings;</li>
            <li>loss or corruption of data or information;</li>
            <li>loss of business opportunity, goodwill or reputation; or</li>
            <li>any indirect or consequential loss or damage.</li>
        </ul>
        <p>To the extent it is not excluded above our total, aggregate, liability to you in respect of your use of
            the
            Website, App, Documentation and/or Services shall be limited to £500.</p>
        <p>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This
            includes
            liability for death or personal injury caused by our negligence or the negligence of our employees,
            agents
            or
            subcontractors or for fraud or fraudulent misrepresentation.</p>
        <p>Check that the Website App and the Services are suitable for you. The Website, App and the Services have
            not
            been
            developed to meet your individual requirements. Please check that the facilities and functions of the
            Website,
            App and Services meet your requirements.</p>
        <p>We are not responsible for events outside our control. If our provision of the Services or support for
            the
            Website, App or the Services is delayed by an event outside our control then we will contact you as soon
            as
            possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this
            we
            will
            not be liable for delays caused by the event.</p>

        <h2 class="primary--text">WE MAY END YOUR RIGHTS TO USE THE WEBSITE, APP AND THE SERVICES</h2>
        <p>We may end your rights to use the Website, App, Documentation and Services (including your amTAG
            Account) at
            any
            time by contacting you if you have broken these terms in a serious way. If what you have done can be
            corrected
            we will give you a reasonable opportunity to do so.</p>
        <p>We may suspend or end, in our sole discretion, your rights to use the Website, App, Documentation and
            Services
            (including your amTAG Account) at any time, without cause, upon giving you 30 days written notice of
            termination.</p>

        <h2 class="primary--text">NO RIGHTS FOR THIRD PARTIES</h2>
        <p>This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to
            enforce
            any term of this agreement.</p>

        <h2 class="primary--text">IF A COURT FINDS PART OF THIS CONTRACT ILLEGAL, THE REST WILL CONTINUE IN FORCE</h2>
        <p>Each of the paragraphs of these terms operates separately. If any court or relevant authority decides
            that
            any of
            them are unlawful, the remaining paragraphs will remain in full force and effect.</p>

        <h2 class="primary--text">WHICH LAWS APPLY TO THIS CONTRACT AND WHERE YOU MAY BRING LEGAL PROCEEDINGS</h2>
        <p>These terms are governed by English law and the resolution of any dispute in relation to this licence,
            the
            App,
            Documentation or Services is the exclusive jurisdiction of the Courts of England and Wales.</p>

    </div>
</template>

<script>
    export default {
        name: "TermsOfUse",
        props: {
            hideTitle: false
        }
    }
</script>

<style scoped lang="scss">
    h2 {
        margin-top: 10px;
    }
</style>
