<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-simple-table v-if="!loading" fixed-header>
    <template>
      <thead>
      <tr>
        <th>
          Upload Date
        </th>
        <th>
          Batch Name
        </th>
        <th>
          Max Checks
        </th>
        <th>
          Asset Count
        </th>
        <th>
          Uploaded by
        </th>
        <th>
          Actions
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="item in items"
          :key="item.id"
      >
        <td class="py-1">
          {{ $options.filters.formatDateTime(item.createdAt) }}
        </td>
        <td class="py-1">
          {{ item.name }}
        </td>
        <td class="py-1">
          {{ item.maxChecks }}
        </td>
        <td>
          <v-chip :color="item.inUseAssetCount > 0 ? 'error' : 'info'">
            {{ item.inUseAssetCount }}/{{ item.assetCount }} in use
          </v-chip>
        </td>
        <td>
          {{ item.user.fullName }}
        </td>
        <td>
          <action-dropdown icon="mdi-dots-vertical" :items="getDropdown(item)"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
  <v-alert class="mt-4 mx-4 font-weight-bold text-white" color="info"  v-else>Exporting batch, please wait</v-alert>
</template>
<script>
import {mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import {endpoints, httpRequest} from "@/store/network/amtag.client";

export default {
  name: 'TagBatchList',
  components: {
    ActionDropdown
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data() {
    return {
      selectedBatch: null,
      loading:false,
    }
  },
  methods: {
    getDropdown(item) {
      let data = [
        {
          label: 'Edit batch',
          icon: 'mdi-pencil',
          route: {name: 'BatchEdit', params: {batchId: item.id}}
        },
        {
          label: 'Export batch',
          icon: 'mdi-export',
          click: async () => {
            await this.exportData(item)
          }
        },
      ];
      if (item.inUseAssetCount === 0) {
        data.push(
            {
              label: 'Delete batch',
              icon: 'mdi-pencil',
              route: {name: 'BatchDelete', params: {batchId: item.id}}
            })
      }
      return data;
    },
    async exportData(item) {
      this.errors = {}
      this.loading = true;
      try {
        console.log("ENDPOINT IS",endpoints.tags.exportBatch(item.id));
        let res = await httpRequest(endpoints.tags.exportBatch(item.id), {});
        console.log("RES IS ",res);
        window.open(res.data.url, '_blank');
      } catch (e) {
        console.log("error", e);
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin', 'distributor']),
    dialogOpen: {
      get() {
        return this.selectedBatch != null
      },
      set(value) {
        if (!value) {
          this.selectedBatch = null;
        }
      }
    }
  },
}
</script>
