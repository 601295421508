<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <item-loader v-on:itemUpdated="assetUpdated" namespace="assets" :id="assetId">
    <template #default="assetSlot">
      <crud-page v-if="asset" icon="mdi-tag-multiple" :title="title">
        <template v-if="formType === 'delete'">
          <div class="light px-3 mb-3">
            <asset-type-view :asset-id="assetId" ></asset-type-view>
          </div>
          <v-alert type="error">
            Archiving of a tagged asset is irreversible, are you sure you want to do this?
          </v-alert>

          <div class="mt-3 d-flex align-center justify-end">
            <v-btn color="error" :loading="saveLoading" @click="deleteAsset">Archive</v-btn>
          </div>

        </template>
        <template v-else>
          <v-text-field
              label="Tagged Asset Reference"
              v-model="asset.name"
              :error="errors.name != null"
              :error-messages="errors.name"
          />
          <item-list-loader v-if="asset.organisationZoneId !== null || hadLocation"
                            :custom-endpoint="siteEndpoint(asset)">
            <template #main="{items}">
              <v-select
                  label="Asset Location"
                  v-model="asset.organisationZoneId"
                  :items="items"
                  item-text="label"
                  item-value="value"
                  :error="errors.organisationZoneId != null"
                  :error-messages="errors.organisationZoneId"/>
            </template>
          </item-list-loader>

          <v-text-field
              v-if="asset.organisationZoneId"
              label="Installation Date (dd/mm/yyyy)"
              v-model="asset.installationDate"
              v-mask="'##/##/####'"
              :error="errors.installationDate != null"
              :error-messages="errors.installationDate"
          />
          <v-text-field
              v-if="asset.organisationZoneId"
              label="Build Date (dd/mm/yyyy)"
              v-model="asset.buildDate"
              v-mask="'##/##/####'"
              :error="errors.buildDate != null"
              :error-messages="errors.buildDate"
          />

          <v-row v-if="asset.assetType">
            <v-col class="d-flex flex-column" cols="6">
              <h3>Assigned Tasks:</h3>
              <v-card class="flex-fill" style="overflow: scroll;" max-height="700">

                <v-alert class="ma-3" v-if="asset.checks.length - toRemove.length >= asset.maxChecks" type="success">{{
                    asset.checks.length - toRemove.length
                  }}/{{ asset.maxChecks }} tasks assigned
                </v-alert>
                <v-alert class="ma-3" v-else-if="asset.checks.length === 0" type="info">No tasks assigned</v-alert>
                <v-alert class="ma-3" v-else type="info">
                  {{ asset.checks.length - toRemove.length }}/{{ asset.maxChecks }} tasks assigned
                </v-alert>
                <template v-for="(item,itemKey) in asset.checks">
                  <v-list-item class="py-3" :key="item.id">
                    <v-list-item-title :style="toRemove.indexOf(item.checkId) !== -1 ? 'opacity: 0.3' : ''">
                      <div>
                        <div class="mb-2">{{ item.checkName }}<v-chip x-small color="info" class="ml-2" v-if="recommendedCheckIds.indexOf(item.checkId) !== -1">Recommended</v-chip></div>
                        <check-timeline-input v-model="item.checkTimeline" :check-id="item.id"></check-timeline-input>
                      </div>
                    </v-list-item-title>

                    <v-list-item-action v-if="asset.organisationZoneId == null || toAdd.indexOf(item.id) !== -1"
                                        class="pr-2">
                      <v-btn color="error" small depressed
                             @click="removeTask(items,item)">
                        {{ toRemove.indexOf(item.id) !== -1 ? 'Cancel' : 'Remove' }}
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="itemKey !== asset.checks.length-1"/>
                </template>
              </v-card>
            </v-col>
            <v-col class="d-flex flex-column" cols="6">
              <h3>Add New Tasks:</h3>
              <v-card class="flex-fill pa-0" style="overflow: scroll;" max-height="700">
                <item-list-loader
                    namespace="checks"
                    :limit="1000"
                    :custom-filters="{organisationId : currentOrganisationId, excludeAssetId : asset.id, sectorId : [asset.assetType.sectorId]}">
                  <template #noResults>
                    <v-alert class="ma-3" type="info">
                      No tasks available
                    </v-alert>
                  </template>
                  <template #main="{items}">
                    <v-text-field
                        hide-details
                        placeholder="Search Tasks"
                        clearable
                        prepend-inner-icon="mdi-magnify"
                        solo
                        outlined
                        flat
                        class="ml-3 mr-3 mt-3"
                        v-model="taskSearch"></v-text-field>
                    <v-list class="rounded">
                      <v-alert class="ma-3" type="warning"
                               v-if="taskSearch != null && items.length > 0 && items.filter(item => (taskSearch == null || item.name.toLowerCase().includes(taskSearch.toLowerCase())) &&  !asset.checks.some(check => check.id === item.id)).length === 0">
                        No tasks found
                      </v-alert>
                      <template
                          v-for="(item,itemKey) in items.filter(item => (taskSearch == null || item.name.toLowerCase().includes(taskSearch.toLowerCase())) &&  !asset.checks.some(check => check.id === item.id)).sort((a,b) => recommendedCheckIds.indexOf(b.id) - recommendedCheckIds.indexOf(a.id))">

                        <v-list-item class="py-3" :key="item.id">
                          <v-list-item-title>
                            <div>
                              <div class="mb-2">{{ item.name }} <v-chip x-small color="info" class="ml-2" v-if="recommendedCheckIds.indexOf(item.id) !== -1">Recommended</v-chip></div>
                              <check-timeline-chip :check-timeline="item.checkTimeline"></check-timeline-chip>
                            </div>
                          </v-list-item-title>

                          <v-list-item-action class="pr-2">
                            <v-btn color="success" v-if="asset.checks.length - toRemove.length < asset.maxChecks" small
                                   depressed
                                   @click="addTask(item)">Add
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="itemKey !== items.length-1"/>
                      </template>
                    </v-list>
                  </template>
                </item-list-loader>
              </v-card>
            </v-col>
          </v-row>
          <global-error-inline class="mt-3" :errors="errors"/>
          <div class="mt-3 d-flex align-center justify-end">
            <v-alert v-if="asset.checks.length - toRemove.length > asset.maxChecks" type="error">
              This change would result in more tasks being assigned than the maximum allowed for this asset
            </v-alert>

            <v-btn color="success" :loading="loading" @click="save">Save</v-btn>
          </div>

        </template>
      </crud-page>
    </template>
  </item-loader>
</template>

<script>
import FormLoader from "../../../components/FormLoader";
import {mapActions, mapGetters} from "vuex";
import ApiSelectBox from "../../../components/ApiSelectBox";
import HoseConfigurationView from "../../../components/asset-types/AssetTypeView";
import AssetTypeView from "@/components/asset-types/AssetTypeView";
import TaskSelectionInput from "@/components/tasks/TaskSelectionInput";
import {v4 as uuidv4} from "uuid";
import ItemListLoader from "@/components/ItemListLoader.vue";
import {endpoints, httpRequest} from "@/store/network/amtag.client";
import CrudPage from "@/components/CrudPage.vue";
import ItemLoader from "@/components/ItemLoader.vue";
import CheckList from "@/components/checks/CheckList.vue";
import CheckTimelineChip from "@/components/CheckTimelineChip.vue";
import CheckTimelineInput from "@/components/CheckTimelineInput.vue";
import emitter from "@/utils/emitter";
import _ from "lodash";
import GlobalErrorInline from "@/components/GlobalErrorInline.vue";

export default {
  name: "AssetEdit",
  components: {
    GlobalErrorInline,
    CheckTimelineInput,
    CheckTimelineChip,
    CheckList,
    ItemLoader,
    CrudPage,
    ItemListLoader, TaskSelectionInput, AssetTypeView, HoseConfigurationView, ApiSelectBox, FormLoader
  },
  data() {
    return {
      asset: {},
      errors: {},
      loading : false,
      hadLocation: false,
      initialCheck: false,
      toRemove: [],
      toAdd: [],
      recommendedCheckIds: [],
      installationDate: null,
      buildDate: null,
      taskSearch: null
    }
  },
  props: {
    assetId: null
  },
  methods: {
    ...mapActions('assets', ['deleteItem']),
    async deleteAsset() {
      await this.deleteItem(this.assetId);
      await this.$router.push({name: 'AssetDetail', params: {assetId: this.asset.id}});
    },
    async save() {
      this.loading = true;
      this.errors = {};
      try {
        let res = await httpRequest(endpoints.assets.updateItem, {
          ...this.asset,
          installationDate: this.$options.filters.databaseDate(this.asset.installationDate),
          buildDate: this.$options.filters.databaseDate(this.asset.buildDate),
          checkIds: this.asset.checks.map(item => {
            return {
              id: item.checkId,
              checkTimeline: item.checkTimeline
            }
          }).filter(item => this.toRemove.indexOf(item.id) === -1)
        });
        this.loading = false;
        await this.$router.push({name: 'AssetDetail', params: {assetId: this.asset.id}});
      } catch (e) {
        this.errors = e.response.data;
        this.loading = false;
      }
    },
    removeTask(items, item) {
      if (this.toAdd.indexOf(item.id) !== -1) {
        this.toAdd = this.toAdd.filter(i => i !== item.id);
        this.asset.checks = this.asset.checks.filter(i => i.id !== item.id);
      } else {
        if (this.toRemove.indexOf(item.checkId) !== -1) {
          this.toRemove = this.toRemove.filter(i => i !== item.checkId);
        } else {
          this.toRemove.push(item.checkId);
        }
      }
    },
    addTask(item) {
      this.asset.checks.push({id: item.id, checkId: item.id, checkTimeline: item.checkTimeline, checkName: item.name})
      this.toAdd.push(item.id);
    },
    assetUpdated(item) {
      let newItem = _.clone(item);
      this.asset = {
        ...newItem,
        buildDate: this.$options.filters.formatDate(newItem.buildDate),
        installationDate: this.$options.filters.formatDate(newItem.installationDate)
      };
      this.recommendedCheckIds = this.asset.assetType?.defaultChecks?.map(item => item.checkId) ?? [];
      if (!this.initialCheck && item !== null) {
        this.hadLocation = item.organisationZoneId !== null && item.organisationZoneId !== undefined;
        this.initialCheck = true;
      }
    },
    siteEndpoint(item) {
      return endpoints.sites.getSitesForOrganisation(item.customerOrganisationId);
    },
    ...mapActions('assets', ['getItem'])

  },
  computed: {
    title() {
      if (this.asset.name) {
        return this.asset.name;
      } else if (this.asset.organisationZone) {
        return this.asset.organisationZone.name;
      } else if (this.asset.id) {
        return "#" + this.asset.id;
      } else {
        return "Loading";
      }
    },
    formType() {
      if (this.$router.currentRoute.name === "AssetEdit") return 'edit';
      return "delete";
    },
    formTitle() {
      return this.$router.currentRoute.name === "AssetEdit" ? "Edit tagged asset" : "Archive tagged asset";
    },
    ...mapGetters('auth', ['user', 'clientAdmin', 'distributor', 'customer', 'organisation', 'currentOrganisationId']),
    ...mapGetters('assets',['saveError','saveLoading'])
  }
}
</script>

<style scoped>

</style>
