<template>
    <v-alert
            v-if="errors != null && errors.global != null"
            text
            prominent
            type="error"
            icon="mdi-cloud-alert"
    >
        <div v-for="(error,key) in errors.global" v-bind:key="random + '-error-' + key">
            {{ error }}
        </div>
    </v-alert>
</template>
<script>
    export default {
        name: 'GlobalErrorInline',
        props: {
            errors: {},
        },
        computed : {
            random() {
                return Math.random();
            }
        }
    }
</script>
