<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <form-loader
      :form-type="formType"
      class="px-4"
      :on-save-listeners="['clientUsersUpdated']"
      :title="userId ? 'Edit client admin' : 'Add client admin'"
      :item-transform="itemTransform"
      :before-save="beforeSave"
      :id="userId"
      v-on:itemUpdated="itemUpdated"
      namespace="clientUsers">

    <template #default="{item,errors,onUpdate}">

      <v-text-field
          label="First name"
          v-model="item.firstName"
          @input="onUpdate(item)"
          :error="errors.firstName != null"
          :error-messages="errors.firstName"
      />

      <v-text-field label="Last name"
                    v-model="item.lastName"
                    @input="onUpdate(item)"
                    :error="errors.lastName != null"
                    :error-messages="errors.lastName"/>

      <v-text-field
          label="Email"
          v-model="item.email"
          @input="onUpdate(item)"
          :error="errors.email != null"
          :error-messages="errors.email"/>
      {{ clientId }}
    </template>
  </form-loader>
</template>

<script>
import FormLoader from "../../../../components/FormLoader";
import ApiSelectBox from "../../../../components/ApiSelectBox";
import {mapActions, mapGetters} from "vuex";
import UserRoles from "../../../../components/users/UserRoles";
import UserRolesDropdown from "../../../../components/users/UserRolesDropdown";


export default {
  name: "ClientUserAdd",
  props: {
    userId: null,
    clientId: null
  },
  computed: {
    ...mapGetters('clientUsers', ['item']),
    ...mapGetters('auth', ['user', 'clientAdmin', 'organisation', 'distributor', 'customer']),
    formType() {
      return this.userId != null ? "edit" : "add";
    },
  },
  data() {
    return {

    }
  },
  components: {UserRolesDropdown, UserRoles, ApiSelectBox, FormLoader},
  methods: {
    ...mapActions('auth', ['me']),
    itemTransform(item) {

    },
    beforeSave(item) {
      item.clientId = this.clientId;
      return item;
    },
    itemUpdated(item) {

    }
  }
}
</script>

<style scoped>

</style>
