<template>
  <div>
    <v-dialog
        persistent
        max-width="500"
        v-model="deleteDialog">
      <v-card :loading="saveLoading">
        <v-card-title class="text-h5">
          Are you sure?
        </v-card-title>
        <v-card-text>
          <div>Archiving this organisation will remove access for all users using it.</div>
          <global-error-inline :errors="saveError"></global-error-inline>
        </v-card-text>
        <v-card-actions v-if="saveLoading">
          Archiving {{ selected.name }}
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="confirmArchive"
          >
            Archive {{ selected.name }}
          </v-btn>
          <v-btn
              color="green"
              text
              @click="deleteDialog = false"
          >
            Don't archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-simple-table>
      <template>
        <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Account Code
          </th>
          <th v-if="full" class="text-left">
            Tagged Assets
          </th>
          <th v-if="clientAdmin && full" class="text-left">
            Organisation type
          </th>
          <th>

          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="item in items"
            :key="item.id"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.accountCode }}</td>
          <td v-if="full">{{ item.hoseCount }}</td>
          <td v-if="clientAdmin && full">{{ item.isDistributor ? "Distributor" : "Customer" }}</td>
          <td class="text-right">
            <action-dropdown :items="getDropdown(item)"/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import ActionDropdown from "../ActionDropdown";
import GlobalErrorInline from "@/components/GlobalErrorInline";
import emitter from "@/utils/emitter";

export default {
  name: 'OrganisationList',
  components: {GlobalErrorInline, ActionDropdown},
  data() {
    return {
      deleteDialog : false,
      selected : {}
    }
  },
  props: {
    items: {
      type: Array
    },
    full: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin']),
    ...mapGetters('organisations', ['saveError','saveLoading']),
  },
  methods: {
    ...mapActions('organisations',['deleteItem','patchItem']),
    async confirmArchive() {
      await this.deleteItem(this.selected.id);
      this.deleteDialog = false;
      emitter.emit('organisationsUpdated');
    },
    filtersString(data) {
      return encodeURI(JSON.stringify(data));
    },
    getDropdown(item) {
      var data = [
        {
          label: 'View full details',
          icon: 'mdi-arrow-expand-all',
          route: {name: 'OrganisationView', params: {organisationId: item.id}}
        },
      ];
      if (this.clientAdmin) {
        data.push({
          label: 'View users',
          icon: 'mdi-account-group-outline',
          route: {
            name: 'Users',
            query: {filters: this.filtersString({organisationId: [item.id]})}
          }
        });
      }
      var assetQuery = item.isDistributor ? { distributorOrganisationId : [item.id] } : { customerOrganisationId : [item.id]};
      var tasksQuery = this.clientAdmin ? { organisationId : [item.id]} : (item.isDistributor ? { distributorOrganisationId : [item.id] } : { customerOrganisationId : [item.id]});
      data.push({
        label: 'View assets',
        icon: 'mdi-gesture',
        route: {
          name: 'Assets',
          query: {
            filters: this.filtersString(assetQuery)
          }
        }
      });
      data.push({
        label: 'View completed tasks',
        icon: 'mdi-clipboard-check-outline',
        route: {
          name: 'CompletedTasks',
          query: {
            filters: this.filtersString(tasksQuery)
          }
        }
      });
      if (this.clientAdmin) {
        data.push({
          label: 'Edit organisation',
          icon: 'mdi-pencil',
          route: {name: 'OrganisationEdit', params: {organisationId: item.id}}
        });
        if (item.deletedAt == null) {
          data.push({
            label: 'Archive organisation',
            icon: 'mdi-trash-can',
            click: () => {
              this.selected = item;
              this.deleteDialog = true;
            }
          });
        } else {
          data.push({
            label: 'Unarchive organisation',
            icon: 'mdi-refresh',
            click: () => {
              this.patchItem(item.id);
            }
          });
        }
      }
      return data;
    }
  }
}
</script>
