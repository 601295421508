<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page title="Organisation settings">
    <v-row align="stretch">
      <v-col cols="12" class="d-flex flex-column">
        <h2 class="card-header mb-2">Business information</h2>
        <v-card class="pa-3 py-5 d-flex flex-column justify-end">
          <v-row>
            <v-col cols="12" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Organisation name"
                            v-model="item.name"

                            :error="errors.name != null"
                            :error-messages="errors.name"
              />
            </v-col>
            <v-col cols="6" :lg="4" :md="6" :sm="12">
              <v-text-field dense label="Contact name"
                            v-model="item.contactName"

                            :error="errors.contactName != null"
                            :error-messages="errors.contactName"/>
            </v-col>
            <v-col cols="6" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Contact email"
                            v-model="item.contactEmail"

                            :error="errors.contactEmail != null"
                            :error-messages="errors.contactEmail"/>
            </v-col>
            <v-col cols="6" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Address line 1"
                            v-model="item.addressLine1"
                            :error="errors.addressLine1 != null"
                            :error-messages="errors.addressLine1"/>
            </v-col>
            <v-col cols="6" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Address line 2"
                            v-model="item.addressLine2"
                            :error="errors.addressLine2 != null"
                            :error-messages="errors.addressLine2"/>
            </v-col>
            <v-col cols="6" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Town/City"
                            v-model="item.town"
                            :error="errors.town != null"
                            :error-messages="errors.town"/>
            </v-col>
            <v-col cols="6````" :lg="4" :md="6" :sm="12">
              <v-text-field dense
                            label="Post code"
                            v-model="item.postCode"
                            :error="errors.postCode != null"
                            :error-messages="errors.postCode"/>
            </v-col>
          </v-row>
          <div class="text-right mt-3">
            <global-error-inline :errors="errors"></global-error-inline>
            <v-btn small :loading="loading" @click="saveAction" color='success' depressed>
              <v-icon small class="mr-1">mdi-content-save-outline</v-icon>
              Save business information
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

  </crud-page>
</template>

<script>

import CrudPage from "../../components/CrudPage";
import {mapActions, mapGetters} from "vuex";
import {endpoints, httpRequest} from "../../store/network/amtag.client";
import GlobalErrorInline from "../../components/GlobalErrorInline";
import PaginatedResultLoader from "@/components/PaginatedResultLoader";
import emitter from "@/utils/emitter";

export default {
  name: 'OrganisationSettings',
  components: {
    PaginatedResultLoader,
    GlobalErrorInline,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['organisation', 'customer']),
  },
  methods: {
    async saveAction() {
      this.loading = true;
      this.errors = {};
      try {
        var data = this.item;
        data.updateConfig = true;
        data.manufacturers = this.selectedManufacturers;
        data.hoseTypes = this.selectedHoseTypes;
        data.fields = this.selectedFields;
        console.log("UPLOAD DATA IS", data.fields);
        let res = await httpRequest(endpoints.organisations.updateItem, data);
        await this.$store.dispatch("auth/me", res.data);
      } catch (e) {
        this.errors = e.response.data;
      } finally {
        this.loading = false;
      }
    }
  },
  data() {
    return {
      selectedHoseTypes: [],
      selectedManufacturers: [],
      selectedFields: {},
      loading: false,
      item: {},
      errors: {},
    }
  },
  watch: {
    organisation: {
      immediate: true,
      handler(newVal) {
        console.log("ORGANISATION IS ", newVal);
        this.item = newVal;
        this.selectedFields = newVal.fields ?? {};
        this.selectedManufacturers = newVal.manufacturers ?? [];
        this.selectedHoseTypes = newVal.hoseTypes ?? [];
      }
    }
  }
}
</script>
