<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <crud-page icon="mdi-toolbox" :actions="actions" title="Asset library">
    <paginated-result-loader
        :enable-exports="true"
        namespace="assetTypes">
      <template v-slot:default="props">
        <asset-type-list :items="props.items"/>
      </template>
    </paginated-result-loader>
  </crud-page>
</template>

<script>

import CrudPage from "../../../components/CrudPage";
import {mapGetters} from "vuex";
import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
import OrganisationList from "../../../components/organisations/OrganisationList";
import HoseConfigurationList from "../../../components/asset-types/AssetTypeList";
import AssetTypeList from "@/components/asset-types/AssetTypeList";

export default {
  name: 'AssetTypes',
  components: {
    AssetTypeList,
    HoseConfigurationList,
    PaginatedResultLoader,
    CrudPage
  },
  computed: {
    ...mapGetters('auth', ['clientAdmin','isAdmin']),
    actions() {
      var list = [];
      if (this.isAdmin) {
        list.push({
          icon: 'mdi-plus-circle-outline',
          title: 'Import assets',
          route: {name: 'AssetTypesImport'},
          variant: 'info'
        });
      }
      list.push({
        icon: 'mdi-plus-circle-outline',
        title: 'Create asset',
        route: {name: 'AssetTypeAdd'},
        variant: 'success'
      });
      return list;
    }
  }
}
</script>
