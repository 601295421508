<template>
  <v-card
      :to="to"
      :loading="loading"
      :elevation="3">
    <div :class="genClasses">
      <div class="display-4 font-weight-medium ma-0">{{ totalItems }}</div>
      <div class="mb-2" v-if="text != null">
        {{ text }}
      </div>
    </div>
  </v-card>
</template>
<script>
import {endpoints, httpRequest} from "../../store/network/amtag.client";

export default {
  name: 'ApiCountCard',
  data() {
    return {
      loading: false,
      totalItems: 0,
    }
  },
  props: {
    to: null,
    uuid: null,
    namespace: null,
    text: null,
    classes: "",
    customEndpoint : {
      type: Object,
      default() {
        return null
      }
    },
    customFilters: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    genClasses() {
      return this.classes + " px-3 py-2";
    }
  },
  watch: {
    customFilters: {
      handler(newVal, oldVal) {
        this.loadData();
      },
      deep: true
    },
    uuid(newVal, oldVal) {
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.loading = true;
      var data = {};
      data['filters'] = this.customFilters;
      let endpoint = this.customEndpoint != null ? this.customEndpoint : endpoints[this.namespace].getItems;
      let res = await httpRequest(endpoint, data);
      console.log(res);
      this.totalItems = res.data.count ?? res.data.totalItems;
      this.loading = false;
    }
  }
}
</script>
