<template>
  <v-alert
      v-if="errorsComputed != null && errorsComputed.length > 0"
      text
      prominent
      type="error"
      icon="mdi-cloud-alert"
  >
    <div v-for="(error,key) in errorsComputed" v-bind:key="random + '-error-' + key">
      {{ error }}
    </div>
  </v-alert>
</template>
<script>
export default {
  name: 'AllErrorInline',
  props: {
    errors: {},
  },
  computed: {
    random() {
      return Math.random();
    },
    errorsComputed() {
      var finalErrors = [];
      // loop through all keys in the errors object
      for (var key in this.errors) {
          finalErrors.push(...this.errors[key]);
      }
      return finalErrors;
    }
  }
}
</script>
